@import "https://fast.fonts.net/cssapi/eb623335-2389-44f5-8cdd-67e75bdcbd7b.css";
:root {
  --emu-common-spacing-xs: 4px;
  --emu-common-spacing-xxs: 2px;
  --emu-common-spacing-small: 8px;
  --emu-common-spacing-medium: 16px;
  --emu-common-spacing-large: 32px;
  --emu-common-spacing-xl: 64px;
  --emu-common-spacing-none: 0px;
  --emu-common-spacing-unset: unset;
  --emu-common-colors-white: #fff;
  --emu-common-colors-black: #000;
  --emu-common-colors-red-100: #ff7570;
  --emu-common-colors-red-200: #ff615c;
  --emu-common-colors-red-300: #ff4e47;
  --emu-common-colors-red-400: #ff3a33;
  --emu-common-colors-red-500: #ff1d15;
  --emu-common-colors-red-600: #ff120a;
  --emu-common-colors-red-700: #f50800;
  --emu-common-colors-red-800: #e00700;
  --emu-common-colors-red-900: #cc0700;
  --emu-common-colors-grey-100: #f4f5f5;
  --emu-common-colors-grey-200: #d4d7d8;
  --emu-common-colors-grey-300: #b4b8bb;
  --emu-common-colors-grey-400: #949a9e;
  --emu-common-colors-grey-500: #7e868b;
  --emu-common-colors-grey-600: #61676b;
  --emu-common-colors-grey-700: #44484b;
  --emu-common-colors-grey-800: #303436;
  --emu-common-colors-grey-900: #1d1f20;
  --emu-common-colors-transparent: #0000;
  --emu-common-font-families-sans: Arial;
  --emu-common-font-families-serif: Times New Roman;
  --emu-common-font-families-mono: Courier;
  --emu-common-font-weight-bold: 700;
  --emu-common-font-weight-light: 300;
  --emu-common-font-weight-black: 900;
  --emu-common-font-weight-regular: 400;
  --emu-common-font-sizes-narrow-medium: 12px;
  --emu-common-font-sizes-narrow-large: 16.97px;
  --emu-common-font-sizes-narrow-xl: 23.99px;
  --emu-common-font-sizes-narrow-xxl: 33.93px;
  --emu-common-font-sizes-narrow-xxxl: 47.97px;
  --emu-common-font-sizes-wide-medium: 16px;
  --emu-common-font-sizes-wide-large: 22.62px;
  --emu-common-font-sizes-wide-xl: 31.99px;
  --emu-common-font-sizes-wide-xxl: 45.23px;
  --emu-common-font-sizes-wide-xxxl: 63.96px;
  --emu-common-line-heights-narrow-large: 18px;
  --emu-common-line-heights-narrow-medium: 18px;
  --emu-common-line-heights-narrow-xl: 36px;
  --emu-common-line-heights-narrow-xxl: 36px;
  --emu-common-line-heights-narrow-xxxl: 54px;
  --emu-common-line-heights-wide-large: 24px;
  --emu-common-line-heights-wide-medium: 24px;
  --emu-common-line-heights-wide-xl: 48px;
  --emu-common-line-heights-wide-xxl: 48px;
  --emu-common-line-heights-wide-xxxl: 72px;
  --emu-common-border-radius-xxs: 2px;
  --emu-common-border-radius-xs: 4px;
  --emu-common-border-radius-small: 8px;
  --emu-common-border-radius-medium: 16px;
  --emu-common-border-radius-large: 32px;
  --emu-common-border-radius-none: 0px;
  --emu-common-border-width-thin: 1px;
  --emu-common-border-width-medium: 2px;
  --emu-common-border-width-thick: 4px;
  --emu-common-border-width-none: 0px;
  --emu-common-outline-width-thin: thin;
  --emu-common-outline-width-medium: medium;
  --emu-common-outline-width-thick: thick;
  --emu-common-outline-width-unset: unset;
  --emu-common-sizing-none: 0px;
  --emu-common-sizing-xxs: 1px;
  --emu-common-sizing-xs: 8px;
  --emu-common-sizing-small: 16px;
  --emu-common-sizing-medium: 32px;
  --emu-common-sizing-large: 64px;
  --emu-common-sizing-xl: 128px;
  --emu-common-sizing-xxl: 256px;
  --emu-common-sizing-container: 1520px;
  --emu-common-other-time-transition-short: 200ms;
  --emu-common-other-time-transition-base: 400ms;
  --emu-common-other-time-transition-long: 600ms;
  --emu-common-other-time-transition-xl: 1000ms;
  --emu-common-other-time-duration-instant: 400ms;
  --emu-common-other-time-duration-short: 2000ms;
  --emu-common-other-time-duration-base: 4000ms;
  --emu-common-other-time-duration-long: 6000ms;
  --emu-common-other-time-delay-none: 0ms;
  --emu-common-other-time-delay-short: 50ms;
  --emu-common-other-time-delay-base: 100ms;
  --emu-common-other-time-delay-long: 200ms;
  --emu-common-other-z-index-cookie-banner: 700;
  --emu-common-other-z-index-modal: 600;
  --emu-common-other-z-index-header: 500;
  --emu-common-other-z-index-isi: 400;
  --emu-common-other-z-index-overlay: 300;
  --emu-common-other-z-index-layer: 100;
  --emu-common-other-z-index-base: 0;
  --emu-common-other-z-index-behind: -1;
  --emu-semantic-colors-none: var(--emu-common-colors-transparent);
  --emu-semantic-colors-surface-light: var(--emu-common-colors-white);
  --emu-semantic-colors-surface-dark: var(--emu-common-colors-black);
  --emu-semantic-colors-surface-modal-backdrop: #0006;
  --emu-semantic-colors-actions-primary-light: var(--emu-common-colors-red-500);
  --emu-semantic-colors-actions-primary-dark: var(--emu-common-colors-red-900);
  --emu-semantic-colors-actions-on-primary-light: var(--emu-common-colors-black);
  --emu-semantic-colors-actions-on-primary-dark: var(--emu-common-colors-white);
  --emu-semantic-colors-text-light: var(--emu-common-colors-black);
  --emu-semantic-colors-text-dark: var(--emu-common-colors-white);
  --emu-semantic-colors-foreground-light: var(--emu-common-colors-black);
  --emu-semantic-colors-foreground-dark: var(--emu-common-colors-white);
  --emu-semantic-colors-primary-transparent: var(--emu-common-colors-transparent);
  --emu-semantic-colors-primary-teal-100: #f9feff;
  --emu-semantic-colors-primary-teal-200: #dceff0;
  --emu-semantic-colors-primary-teal-300: #00acbb;
  --emu-semantic-colors-primary-teal-400: #00a4b3;
  --emu-semantic-colors-primary-teal-500: #0096a3;
  --emu-semantic-colors-primary-white-solid: var(--emu-common-colors-white);
  --emu-semantic-colors-primary-white-header: #ffffffe6;
  --emu-semantic-colors-primary-black: var(--emu-common-colors-black);
  --emu-semantic-colors-primary-bone: #f6f1ee;
  --emu-semantic-colors-secondary-grey-100: #d8d8d8;
  --emu-semantic-colors-secondary-grey-200: #979797;
  --emu-semantic-colors-secondary-grey-300: #484848;
  --emu-semantic-colors-secondary-grey-400: #eee0;
  --emu-semantic-font-weight-bold: var(--emu-common-font-weight-bold);
  --emu-semantic-font-weight-light: var(--emu-common-font-weight-light);
  --emu-semantic-font-weight-black: var(--emu-common-font-weight-black);
  --emu-semantic-font-weight-regular: var(--emu-common-font-weight-regular);
  --emu-semantic-font-families-heading: Helvetica Now Text W01 Bold;
  --emu-semantic-font-families-body: Helvetica Now Text W01 Regular;
  --emu-semantic-font-families-light: Helvetica Now Text W01 Light;
  --emu-semantic-font-families-italic: Helvetica Now Text W01 Italic;
  --emu-semantic-font-families-medium: Helvetica Now Text W01 Medium;
  --emu-semantic-font-families-juana-light: Juana W05 Light;
  --emu-semantic-font-families-juana-regular: Juana W05 Regular;
  --emu-semantic-spacing-none: var(--emu-common-spacing-none);
  --emu-semantic-spacing-horizontal: var(--emu-common-spacing-small);
  --emu-semantic-spacing-vertical: var(--emu-common-spacing-small);
  --emu-semantic-spacing-one-line-height-wide: var(--emu-semantic-line-heights-wide-medium);
  --emu-semantic-spacing-one-line-height-narrow: var(--emu-semantic-line-heights-narrow-medium);
  --emu-semantic-font-sizes-narrow-xxs: 12px;
  --emu-semantic-font-sizes-narrow-xs: 18px;
  --emu-semantic-font-sizes-narrow-small: 20px;
  --emu-semantic-font-sizes-narrow-medium: 22px;
  --emu-semantic-font-sizes-narrow-large: 25px;
  --emu-semantic-font-sizes-narrow-xl: 28px;
  --emu-semantic-font-sizes-narrow-xxl: 30px;
  --emu-semantic-font-sizes-narrow-xxxl: 35px;
  --emu-semantic-font-sizes-wide-xs: 14px;
  --emu-semantic-font-sizes-wide-small: 16px;
  --emu-semantic-font-sizes-wide-nav: 20px;
  --emu-semantic-font-sizes-wide-medium: 40px;
  --emu-semantic-font-sizes-wide-large: 50px;
  --emu-semantic-font-sizes-wide-xl: 55px;
  --emu-semantic-font-sizes-wide-xxl: 70px;
  --emu-semantic-font-sizes-wide-xxxl: var(--emu-common-font-sizes-wide-xxxl);
  --emu-semantic-line-heights-narrow-xxs: 14px;
  --emu-semantic-line-heights-narrow-xs: 17px;
  --emu-semantic-line-heights-narrow-small: 25px;
  --emu-semantic-line-heights-narrow-medium: 30px;
  --emu-semantic-line-heights-narrow-large: 35px;
  --emu-semantic-line-heights-narrow-xl: 38px;
  --emu-semantic-line-heights-narrow-xxl: 40px;
  --emu-semantic-line-heights-narrow-xxxl: 45px;
  --emu-semantic-line-heights-wide-nav: 15px;
  --emu-semantic-line-heights-wide-top-navigation: 17px;
  --emu-semantic-line-heights-wide-xs: var(--emu-common-line-heights-narrow-medium);
  --emu-semantic-line-heights-wide-small: 20px;
  --emu-semantic-line-heights-wide-medium: 44px;
  --emu-semantic-line-heights-wide-large: 50px;
  --emu-semantic-line-heights-wide-xl: 55px;
  --emu-semantic-line-heights-wide-xxl: 65px;
  --emu-semantic-line-heights-wide-xxxl: 66px;
  --emu-semantic-border-radius-xs: 20px;
  --emu-semantic-border-radius-small: 21px;
  --emu-semantic-border-radius-medium: 22.5px;
  --emu-semantic-border-radius-large: 26px;
  --emu-semantic-border-radius-xl: var(--emu-common-border-radius-large);
  --emu-semantic-border-radius-round: 100px;
  --emu-semantic-border-radius-none: 0px;
  --emu-semantic-border-width-thin: var(--emu-common-border-width-thin);
  --emu-semantic-border-width-medium: var(--emu-common-border-width-medium);
  --emu-semantic-border-width-thick: var(--emu-common-border-width-thick);
  --emu-semantic-border-width-none: 0px;
  --emu-semantic-outline-width-thin: var(--emu-common-outline-width-thin);
  --emu-semantic-outline-width-medium: var(--emu-common-outline-width-medium);
  --emu-semantic-outline-width-thick: var(--emu-common-outline-width-thick);
  --emu-semantic-outline-width-unset: unset;
  --emu-semantic-sizing-none: var(--emu-common-sizing-none);
  --emu-semantic-sizing-xxs: var(--emu-common-sizing-xxs);
  --emu-semantic-sizing-xs: var(--emu-common-sizing-xs);
  --emu-semantic-sizing-small: var(--emu-common-sizing-small);
  --emu-semantic-sizing-medium: var(--emu-common-sizing-medium);
  --emu-semantic-sizing-large: var(--emu-common-sizing-large);
  --emu-semantic-sizing-xl: var(--emu-common-sizing-xl);
  --emu-semantic-sizing-xxl: var(--emu-common-sizing-xxl);
  --emu-semantic-sizing-breakpoints-small: 320px;
  --emu-semantic-sizing-breakpoints-medium: 768px;
  --emu-semantic-sizing-breakpoints-large: 1024px;
  --emu-semantic-sizing-breakpoints-x-large: 1201px;
  --emu-semantic-sizing-breakpoints-xx-large: 1441px;
  --emu-semantic-sizing-one-line-height-wide: var(--emu-semantic-line-heights-wide-medium);
  --emu-semantic-sizing-one-line-height-narrow: var(--emu-semantic-line-heights-narrow-medium);
  --emu-semantic-typography-narrow-headings-headline-h1: var(--emu-semantic-font-weight-light) var(--emu-semantic-font-sizes-narrow-xxxl) / var(--emu-semantic-line-heights-narrow-xxxl) var(--emu-semantic-font-families-juana-light);
  --emu-semantic-typography-narrow-headings-headline-h2: var(--emu-semantic-font-weight-light) var(--emu-semantic-font-sizes-narrow-xl) / var(--emu-semantic-line-heights-narrow-xl) var(--emu-semantic-font-families-juana-light);
  --emu-semantic-typography-narrow-headings-headline-h3: var(--emu-semantic-font-weight-light) var(--emu-semantic-font-sizes-narrow-xxl) / var(--emu-semantic-line-heights-narrow-xxl) var(--emu-semantic-font-families-juana-light);
  --emu-semantic-typography-narrow-headings-headline-h4: var(--emu-semantic-font-weight-bold) var(--emu-semantic-font-sizes-narrow-large) / var(--emu-semantic-line-heights-narrow-medium) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-headline-h4-teal: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-large) / var(--emu-semantic-line-heights-narrow-medium) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-narrow-headings-q-a-headline: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-small) / var(--emu-semantic-line-heights-narrow-small) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-narrow-headings-eyebrow: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-large) / var(--emu-semantic-line-heights-narrow-large) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-narrow-headings-xxxl: var(--emu-semantic-font-weight-regular) var(--emu-common-font-sizes-narrow-xxxl) / 51px var(--emu-common-font-families-sans);
  --emu-semantic-typography-narrow-headings-xxl: var(--emu-semantic-font-weight-regular) var(--emu-common-font-sizes-narrow-xxl) / 34px var(--emu-common-font-families-sans);
  --emu-semantic-typography-narrow-headings-xl: var(--emu-semantic-font-weight-regular) var(--emu-common-font-sizes-narrow-xl) / 34px var(--emu-common-font-families-sans);
  --emu-semantic-typography-narrow-headings-large: var(--emu-semantic-font-weight-regular) var(--emu-common-font-sizes-narrow-large) / 17px var(--emu-common-font-families-sans);
  --emu-semantic-typography-narrow-headings-medium: var(--emu-semantic-font-weight-bold) var(--emu-common-font-sizes-narrow-medium) / 17px var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-body-intro-large: var(--emu-semantic-font-weight-light) var(--emu-semantic-font-sizes-narrow-medium) / var(--emu-semantic-line-heights-narrow-medium) var(--emu-semantic-font-families-light);
  --emu-semantic-typography-narrow-body-intro-small: var(--emu-semantic-font-weight-light) var(--emu-semantic-font-sizes-narrow-small) / var(--emu-semantic-line-heights-narrow-medium) var(--emu-semantic-font-families-light);
  --emu-semantic-typography-narrow-body-regular: var(--emu-semantic-font-weight-light) var(--emu-semantic-font-sizes-narrow-xs) / var(--emu-semantic-line-heights-narrow-small) var(--emu-semantic-font-families-light);
  --emu-semantic-typography-narrow-body-small: var(--emu-semantic-font-weight-light) var(--emu-semantic-font-sizes-wide-xs) / var(--emu-semantic-line-heights-narrow-xs) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-narrow-body-footnotes: var(--emu-semantic-font-weight-light) var(--emu-semantic-font-sizes-narrow-xxs) / var(--emu-semantic-line-heights-narrow-xxs) var(--emu-semantic-font-families-light);
  --emu-semantic-typography-narrow-body-footer: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-xs) / var(--emu-semantic-line-heights-narrow-small) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-wide-headings-headline-h1: var(--emu-semantic-font-weight-light) var(--emu-semantic-font-sizes-wide-xl) / var(--emu-semantic-line-heights-wide-xxl) var(--emu-semantic-font-families-juana-light);
  --emu-semantic-typography-wide-headings-headline-h2: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-medium) / var(--emu-semantic-line-heights-wide-xl) var(--emu-semantic-font-families-juana-regular);
  --emu-semantic-typography-wide-headings-headline-h3: var(--emu-semantic-font-weight-light) var(--emu-semantic-font-sizes-narrow-xxxl) / var(--emu-semantic-line-heights-wide-large) var(--emu-semantic-font-families-juana-light);
  --emu-semantic-typography-wide-headings-headline-h4: var(--emu-semantic-font-weight-bold) var(--emu-semantic-font-sizes-narrow-small) / var(--emu-semantic-line-heights-narrow-medium) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-headline-h4-teal: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-large) / var(--emu-semantic-line-heights-narrow-medium) var(--emu-semantic-font-families-medium);
  --emu-semantic-typography-wide-headings-q-a-headline: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-large) / var(--emu-semantic-line-heights-narrow-medium) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-wide-headings-eyebrow: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-xxl) / var(--emu-semantic-line-heights-wide-medium) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-wide-headings-main-nav: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-nav) / var(--emu-semantic-line-heights-wide-nav) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-wide-headings-xxxl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-xxxl) / var(--emu-semantic-line-heights-wide-xxxl) var(--emu-common-font-families-sans);
  --emu-semantic-typography-wide-headings-xxl: var(--emu-semantic-font-weight-regular) var(--emu-common-font-sizes-wide-xxl) / 44px var(--emu-common-font-families-sans);
  --emu-semantic-typography-wide-headings-xl: var(--emu-semantic-font-weight-regular) var(--emu-common-font-sizes-wide-xl) / 44px var(--emu-common-font-families-sans);
  --emu-semantic-typography-wide-headings-large: var(--emu-semantic-font-weight-regular) var(--emu-common-font-sizes-wide-large) / 22px var(--emu-common-font-families-sans);
  --emu-semantic-typography-wide-headings-medium: var(--emu-semantic-font-weight-bold) var(--emu-common-font-sizes-wide-medium) / 22px var(--emu-common-font-families-sans);
  --emu-semantic-typography-wide-body-intro-large: var(--emu-semantic-font-weight-light) var(--emu-semantic-font-sizes-narrow-xxxl) / var(--emu-semantic-line-heights-narrow-xxxl) var(--emu-semantic-font-families-light);
  --emu-semantic-typography-wide-body-intro-small: var(--emu-semantic-font-weight-light) var(--emu-semantic-font-sizes-narrow-large) / var(--emu-semantic-line-heights-narrow-large) var(--emu-semantic-font-families-light);
  --emu-semantic-typography-wide-body-regular: var(--emu-semantic-font-weight-light) var(--emu-semantic-font-sizes-narrow-small) / var(--emu-semantic-line-heights-narrow-medium) var(--emu-semantic-font-families-light);
  --emu-semantic-typography-wide-body-small: var(--emu-semantic-font-weight-light) var(--emu-semantic-font-sizes-wide-small) / var(--emu-semantic-line-heights-wide-xs) var(--emu-semantic-font-families-light);
  --emu-semantic-typography-wide-body-footnotes: var(--emu-semantic-font-weight-light) var(--emu-semantic-font-sizes-wide-xs) / var(--emu-semantic-line-heights-wide-small) var(--emu-semantic-font-families-light);
  --emu-semantic-typography-wide-body-top-navigation: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-xxs) / var(--emu-semantic-line-heights-wide-top-navigation) var(--emu-semantic-font-families-body);
  --emu-component-cards-card-margin-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-padding-top-narrow: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-top-wide: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-right-narrow: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-right-wide: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-bottom-narrow: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-bottom-wide: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-left-narrow: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-left-wide: var(--emu-common-spacing-medium);
  --emu-component-cards-card-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-cards-card-header-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-cards-card-header-button-text-color-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-hover-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-hover-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-active-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-active-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-size-height: 30px;
  --emu-component-cards-card-header-button-icon-size-width: 30px;
  --emu-component-cards-card-header-button-icon-spacing: var(--emu-common-spacing-xs);
  --emu-component-cards-card-header-button-icon-color-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-hover-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-hover-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-active-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-active-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-thickness: var(--emu-semantic-border-width-medium);
  --emu-component-cards-card-header-button-color-background-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-hover-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-active-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-hover-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-active-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-body-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-cards-card-body-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-cards-teaser-padding-top-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-top-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-right-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-right-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-bottom-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-bottom-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-left-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-left-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-text-color-light: var(--emu-common-colors-white);
  --emu-component-cards-teaser-text-color-dark: var(--emu-common-colors-black);
  --emu-component-cards-teaser-title-max-width: 66.6667%;
  --emu-component-cards-teaser-title-margin-top-narrow: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-title-margin-top-wide: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-title-margin-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-title-margin-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-description-margin-top-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-description-margin-top-wide: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-description-margin-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-description-margin-bottom-wide: var(--emu-common-spacing-small);
  --emu-component-lists-accordion-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-lists-accordion-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-lists-accordion-color-text-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-accordion-color-text-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-accordion-border-radius-top-left: var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-border-radius-top-right: var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-border-radius-bottom-right: var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-border-radius-bottom-left: var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-margin-top-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-top-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-right-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-right-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-bottom-narrow: var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-margin-bottom-wide: var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-margin-left-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-left-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-item-color-border-bottom-closed-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-lists-accordion-item-color-border-bottom-closed-light: var(--emu-semantic-colors-secondary-grey-200);
  --emu-component-lists-accordion-item-color-border-bottom-open-dark: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-color-border-bottom-open-light: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-body-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-lists-accordion-item-body-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-body-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-lists-accordion-item-body-padding-top-narrow: var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-item-body-padding-top-wide: var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-item-body-padding-right-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-right-wide: 76px;
  --emu-component-lists-accordion-item-body-padding-bottom-narrow: var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-item-body-padding-bottom-wide: var(--emu-semantic-sizing-none);
  --emu-component-lists-accordion-item-body-padding-left-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-left-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-lists-accordion-item-header-text-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-lists-accordion-item-header-padding-top-narrow: 25px;
  --emu-component-lists-accordion-item-header-padding-top-wide: 25px;
  --emu-component-lists-accordion-item-header-padding-right-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-right-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-bottom-narrow: 25px;
  --emu-component-lists-accordion-item-header-padding-bottom-wide: 25px;
  --emu-component-lists-accordion-item-header-padding-left-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-left-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-icon-spacing: var(--emu-common-spacing-medium);
  --emu-component-lists-accordion-item-header-icon-size-height: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-item-header-icon-size-width: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-item-header-icon-thickness: var(--emu-semantic-border-width-medium);
  --emu-component-lists-accordion-item-header-icon-border-radius-top-left: var(--emu-semantic-border-radius-small);
  --emu-component-lists-accordion-item-header-icon-border-radius-top-right: var(--emu-semantic-border-radius-small);
  --emu-component-lists-accordion-item-header-icon-border-radius-bottom-right: var(--emu-semantic-border-radius-small);
  --emu-component-lists-accordion-item-header-icon-border-radius-bottom-left: var(--emu-semantic-border-radius-small);
  --emu-component-lists-accordion-item-header-icon-transform-open: rotate(0);
  --emu-component-lists-accordion-item-header-icon-transform-closed: rotate(180deg);
  --emu-component-lists-accordion-item-header-icon-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-lists-accordion-item-header-icon-color-fill-open-light: var(--emu-semantic-colors-secondary-grey-200);
  --emu-component-lists-accordion-item-header-icon-color-fill-open-dark: var(--emu-component-ingredients-icon-color-fill-dark);
  --emu-component-lists-accordion-item-header-icon-color-fill-closed-light: var(--emu-semantic-colors-secondary-grey-200);
  --emu-component-lists-accordion-item-header-icon-color-fill-closed-dark: var(--emu-component-ingredients-icon-color-fill-dark);
  --emu-component-lists-accordion-item-header-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-header-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-border-width-bottom-open: var(--emu-semantic-border-width-thin);
  --emu-component-lists-navigation-banner-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-lists-navigation-banner-color-background-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-background-default-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-banner-item-color-background-default-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-background-hover-light: var(--emu-common-colors-transparent);
  --emu-component-lists-navigation-banner-item-color-background-hover-dark: var(--emu-common-colors-grey-800);
  --emu-component-lists-navigation-banner-item-color-border-default-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-banner-item-color-border-default-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-border-hover-light: var(--emu-common-colors-grey-800);
  --emu-component-lists-navigation-banner-item-color-border-hover-dark: var(--emu-common-colors-grey-800);
  --emu-component-lists-navigation-banner-item-text-color-default-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-banner-item-text-color-default-dark: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-banner-item-text-color-hover-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-banner-item-text-color-hover-dark: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-banner-item-border-width: var(--emu-common-border-width-none);
  --emu-component-lists-navigation-banner-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-dropdown-color-background-light: var(--emu-common-colors-white);
  --emu-component-lists-navigation-dropdown-color-background-dark: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-color-border-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-color-border-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-dropdown-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-text-color-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-text-color-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-border-width: var(--emu-common-border-width-thin);
  --emu-component-lists-navigation-dropdown-item-color-background-default-light: var(--emu-common-colors-white);
  --emu-component-lists-navigation-dropdown-item-color-background-default-dark: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-item-color-background-hover-light: var(--emu-common-colors-grey-100);
  --emu-component-lists-navigation-dropdown-item-color-background-hover-dark: var(--emu-common-colors-grey-100);
  --emu-component-lists-navigation-dropdown-item-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-item-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-item-text-color-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-item-text-color-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-z-index: 500;
  --emu-component-lists-navigation-text-padding-top-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-lists-navigation-text-padding-top-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-lists-navigation-text-padding-right-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-lists-navigation-text-padding-right-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-lists-navigation-text-padding-bottom-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-lists-navigation-text-padding-bottom-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-lists-navigation-text-padding-left-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-lists-navigation-text-padding-left-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-feeds-instagram-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-bottom-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-feeds-instagram-padding-bottom-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-feeds-instagram-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-post-gap-narrow: 40px;
  --emu-component-feeds-instagram-post-gap-wide: 40px;
  --emu-component-ingredients-icon-color-fill-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-icon-color-fill-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-icon-size-height: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-icon-size-width: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-separator-color-background-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-separator-color-background-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-separator-size-height: var(--emu-semantic-sizing-xxs);
  --emu-component-ingredients-title-h1-typography-narrow: var(--emu-semantic-typography-narrow-headings-headline-h1);
  --emu-component-ingredients-title-h1-typography-wide: var(--emu-semantic-typography-wide-headings-headline-h1);
  --emu-component-ingredients-title-h2-typography-narrow: var(--emu-semantic-typography-narrow-headings-headline-h2);
  --emu-component-ingredients-title-h2-typography-wide: var(--emu-semantic-typography-wide-headings-headline-h2);
  --emu-component-ingredients-title-h3-typography-narrow: var(--emu-semantic-typography-narrow-headings-headline-h3);
  --emu-component-ingredients-title-h3-typography-wide: var(--emu-semantic-typography-wide-headings-headline-h3);
  --emu-component-ingredients-title-h4-typography-narrow: var(--emu-semantic-typography-narrow-headings-headline-h4);
  --emu-component-ingredients-title-h4-typography-wide: var(--emu-semantic-typography-wide-headings-headline-h4);
  --emu-component-ingredients-title-h5-typography-narrow: var(--emu-semantic-typography-narrow-headings-q-a-headline);
  --emu-component-ingredients-title-h5-typography-wide: var(--emu-semantic-typography-wide-headings-q-a-headline);
  --emu-component-ingredients-title-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-title-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-text-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-text-typography-narrow: var(--emu-semantic-typography-narrow-body-regular);
  --emu-component-ingredients-text-text-typography-wide: var(--emu-semantic-typography-wide-body-regular);
  --emu-component-ingredients-loader-color-fill-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-loader-color-fill-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-image-map-buttons-size-height: 27px;
  --emu-component-ingredients-image-map-buttons-size-width: 27px;
  --emu-component-ingredients-image-map-buttons-color-background-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-image-map-buttons-color-background-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-image-map-buttons-color-icon-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-ingredients-image-map-buttons-color-icon-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-ingredients-image-map-buttons-border-radius-top-left: 50%;
  --emu-component-ingredients-image-map-buttons-border-radius-top-right: 50%;
  --emu-component-ingredients-image-map-buttons-border-radius-bottom-right: 50%;
  --emu-component-ingredients-image-map-buttons-border-radius-bottom-left: 50%;
  --emu-component-ingredients-image-map-buttons-shadow: 0 0 2 5 #0009;
  --emu-component-ingredients-google-map-color-background-light: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-component-ingredients-google-map-color-background-dark: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-component-ingredients-google-map-margin-top-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-ingredients-google-map-margin-top-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-ingredients-google-map-margin-right-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-ingredients-google-map-margin-right-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-ingredients-google-map-margin-bottom-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-ingredients-google-map-margin-bottom-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-ingredients-google-map-margin-left-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-ingredients-google-map-margin-left-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-text-typography-narrow: var(--emu-semantic-font-weight-regular) 18px / normal var(--emu-semantic-font-families-heading);
  --emu-component-actions-button-text-typography-wide: var(--emu-semantic-font-weight-regular) 25px / normal var(--emu-semantic-font-families-heading);
  --emu-component-actions-button-text-color-link-light: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-link-dark: var(--emu-common-colors-black);
  --emu-component-actions-button-text-color-link-hover-light: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-link-hover-dark: var(--emu-common-colors-black);
  --emu-component-actions-button-text-color-primary-filled-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-filled-dark: var(--emu-semantic-colors-primary-white-solid);
  --emu-component-actions-button-text-color-primary-filled-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-filled-focus-dark: var(--emu-semantic-colors-primary-teal-300);
  --emu-component-actions-button-text-color-primary-filled-hover-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-hover-dark: var(--emu-semantic-colors-primary-teal-300);
  --emu-component-actions-button-text-color-primary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-active-dark: var(--emu-semantic-colors-primary-white-solid);
  --emu-component-actions-button-text-color-primary-outline-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-dark: var(--emu-semantic-colors-primary-teal-400);
  --emu-component-actions-button-text-color-primary-outline-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-focus-dark: var(--emu-semantic-colors-primary-white-solid);
  --emu-component-actions-button-text-color-primary-outline-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-outline-hover-dark: var(--emu-semantic-colors-primary-white-solid);
  --emu-component-actions-button-text-color-primary-outline-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-outline-active-dark: var(--emu-semantic-colors-primary-teal-400);
  --emu-component-actions-button-text-color-secondary-filled-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-secondary-filled-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-filled-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-secondary-filled-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-filled-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-filled-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-secondary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-filled-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-secondary-outline-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-outline-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-secondary-outline-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-outline-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-secondary-outline-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-outline-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-secondary-outline-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-outline-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-padding-top-narrow: 6px;
  --emu-component-actions-button-padding-top-wide: 7px;
  --emu-component-actions-button-padding-right-narrow: 30px;
  --emu-component-actions-button-padding-right-wide: 30px;
  --emu-component-actions-button-padding-bottom-narrow: 6px;
  --emu-component-actions-button-padding-bottom-wide: 7px;
  --emu-component-actions-button-padding-left-narrow: 30px;
  --emu-component-actions-button-padding-left-wide: 30px;
  --emu-component-actions-button-border-width-primary-filled: var(--emu-common-border-width-thin);
  --emu-component-actions-button-border-width-primary-outline: var(--emu-common-border-width-thin);
  --emu-component-actions-button-border-width-secondary-filled: var(--emu-common-border-width-thin);
  --emu-component-actions-button-border-width-secondary-outline: var(--emu-common-border-width-thin);
  --emu-component-actions-button-outline-width-primary-filled: var(--emu-semantic-outline-width-unset);
  --emu-component-actions-button-outline-width-primary-outline: var(--emu-semantic-outline-width-unset);
  --emu-component-actions-button-outline-width-secondary-filled: var(--emu-semantic-outline-width-unset);
  --emu-component-actions-button-outline-width-secondary-outline: var(--emu-semantic-outline-width-unset);
  --emu-component-actions-button-border-radius-primary-filled-top-left: var(--emu-semantic-border-radius-xl);
  --emu-component-actions-button-border-radius-primary-filled-top-right: var(--emu-semantic-border-radius-xl);
  --emu-component-actions-button-border-radius-primary-filled-bottom-right: var(--emu-semantic-border-radius-xl);
  --emu-component-actions-button-border-radius-primary-filled-bottom-left: var(--emu-semantic-border-radius-xl);
  --emu-component-actions-button-border-radius-primary-outline-top-left: var(--emu-semantic-border-radius-xl);
  --emu-component-actions-button-border-radius-primary-outline-top-right: var(--emu-semantic-border-radius-xl);
  --emu-component-actions-button-border-radius-primary-outline-bottom-right: var(--emu-semantic-border-radius-xl);
  --emu-component-actions-button-border-radius-primary-outline-bottom-left: var(--emu-semantic-border-radius-xl);
  --emu-component-actions-button-border-radius-secondary-filled-top-left: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-filled-top-right: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-filled-bottom-right: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-filled-bottom-left: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-outline-top-left: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-outline-top-right: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-outline-bottom-right: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-outline-bottom-left: var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-color-border-primary-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-primary-filled-dark: var(--emu-semantic-colors-primary-teal-300);
  --emu-component-actions-button-color-border-primary-filled-focus-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-primary-filled-focus-dark: var(--emu-semantic-colors-primary-teal-300);
  --emu-component-actions-button-color-border-primary-filled-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-primary-filled-hover-dark: var(--emu-semantic-colors-primary-teal-300);
  --emu-component-actions-button-color-border-primary-filled-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-primary-filled-active-dark: var(--emu-semantic-colors-primary-teal-300);
  --emu-component-actions-button-color-border-primary-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-primary-outline-dark: var(--emu-semantic-colors-primary-teal-400);
  --emu-component-actions-button-color-border-primary-outline-focus-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-primary-outline-focus-dark: var(--emu-semantic-colors-primary-teal-400);
  --emu-component-actions-button-color-border-primary-outline-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-primary-outline-hover-dark: var(--emu-semantic-colors-primary-teal-400);
  --emu-component-actions-button-color-border-primary-outline-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-primary-outline-active-dark: var(--emu-semantic-colors-primary-teal-400);
  --emu-component-actions-button-color-border-secondary-filled-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-secondary-filled-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-filled-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-secondary-filled-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-filled-hover-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-filled-hover-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-secondary-filled-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-filled-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-secondary-outline-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-secondary-outline-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-outline-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-secondary-outline-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-outline-hover-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-outline-hover-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-border-secondary-outline-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-border-secondary-outline-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-primary-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-primary-filled-dark: var(--emu-semantic-colors-primary-teal-300);
  --emu-component-actions-button-color-background-primary-filled-focus-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-primary-filled-focus-dark: var(--emu-semantic-colors-primary-white-solid);
  --emu-component-actions-button-color-background-primary-filled-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-primary-filled-hover-dark: var(--emu-semantic-colors-primary-white-solid);
  --emu-component-actions-button-color-background-primary-filled-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-primary-filled-active-dark: var(--emu-semantic-colors-primary-teal-300);
  --emu-component-actions-button-color-background-primary-outline-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-focus-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-focus-dark: var(--emu-semantic-colors-primary-teal-400);
  --emu-component-actions-button-color-background-primary-outline-hover-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-primary-outline-hover-dark: var(--emu-semantic-colors-primary-teal-400);
  --emu-component-actions-button-color-background-primary-outline-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-primary-outline-active-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-filled-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-secondary-filled-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-secondary-filled-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-secondary-filled-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-secondary-filled-hover-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-secondary-filled-hover-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-secondary-filled-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-secondary-filled-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-secondary-outline-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-focus-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-focus-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-hover-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-secondary-outline-hover-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-background-secondary-outline-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-button-color-background-secondary-outline-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-button-color-outline-color-primary-filled-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-active-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-active-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-active-light: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-primary-outline-active-dark: #43272880;
  --emu-component-actions-button-color-outline-color-secondary-filled-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-active-light: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-secondary-filled-active-dark: #43272880;
  --emu-component-actions-button-color-outline-color-secondary-outline-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-active-light: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-secondary-outline-active-dark: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-group-horizontal-gap: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-group-horizontal-padding-left: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-group-horizontal-padding-right: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-group-horizontal-padding-top: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-button-group-horizontal-padding-bottom: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-button-group-vertical-gap: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-group-vertical-padding-left: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-group-vertical-padding-right: var(--emu-semantic-spacing-horizontal);
  --emu-component-actions-button-group-vertical-padding-top: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-button-group-vertical-padding-bottom: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-button-icon-size-height: 12px;
  --emu-component-actions-button-icon-size-width: 12px;
  --emu-component-actions-button-icon-spacing: var(--emu-common-spacing-xs);
  --emu-component-actions-button-icon-thickness: 3px;
  --emu-component-actions-button-icon-border-radius-top-left: var(--emu-semantic-border-radius-small);
  --emu-component-actions-button-icon-border-radius-top-right: var(--emu-semantic-border-radius-small);
  --emu-component-actions-button-icon-border-radius-bottom-right: var(--emu-semantic-border-radius-small);
  --emu-component-actions-button-icon-border-radius-bottom-left: var(--emu-semantic-border-radius-small);
  --emu-component-actions-button-icon-transform-open: rotate(0);
  --emu-component-actions-button-icon-transform-closed: rotate(180deg);
  --emu-component-actions-button-icon-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-actions-button-icon-color-primary-filled-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-hover-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-hover-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-active-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-filled-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-filled-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-filled-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-filled-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-outline-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-outline-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-outline-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-secondary-outline-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-back-to-top-z-index: 600;
  --emu-component-actions-back-to-top-icon-size-height: 55px;
  --emu-component-actions-back-to-top-icon-size-width: 56px;
  --emu-component-actions-back-to-top-position-bottom-narrow: 111px;
  --emu-component-actions-back-to-top-position-bottom-wide: 166px;
  --emu-component-actions-back-to-top-position-left-narrow: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-left-wide: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-right-narrow: 22px;
  --emu-component-actions-back-to-top-position-right-wide: 86px;
  --emu-component-actions-back-to-top-position-top-narrow: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-top-wide: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-margin-bottom-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-back-to-top-margin-bottom-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-back-to-top-margin-left-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-back-to-top-margin-left-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-back-to-top-margin-right-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-back-to-top-margin-right-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-back-to-top-margin-top-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-actions-back-to-top-margin-top-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-containers-scroll-reveal-header-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-containers-scroll-reveal-header-text-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-containers-scroll-reveal-header-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-header-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-padding-top-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-scroll-reveal-padding-top-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-scroll-reveal-padding-bottom-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-scroll-reveal-padding-bottom-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-scroll-reveal-progress-bar-color-progress-indicator-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-progress-bar-color-progress-indicator-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-progress-bar-color-bar-light: var(--emu-common-colors-grey-300);
  --emu-component-containers-scroll-reveal-progress-bar-color-bar-dark: var(--emu-common-colors-grey-300);
  --emu-component-containers-scroll-reveal-static-section-margin-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-right-wide: var(--emu-common-sizing-medium);
  --emu-component-containers-scroll-reveal-static-section-margin-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-scroll-reveal-cards-header-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-medium);
  --emu-component-containers-scroll-reveal-cards-header-text-typography-wide: var(--emu-semantic-typography-wide-headings-medium);
  --emu-component-containers-scroll-reveal-cards-header-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-cards-header-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-cards-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-containers-scroll-reveal-cards-text-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-containers-scroll-reveal-cards-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-cards-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-comparison-slider-divider-color-light: var(--emu-common-colors-white);
  --emu-component-containers-comparison-slider-divider-color-dark: var(--emu-common-border-width-medium);
  --emu-component-containers-comparison-slider-divider-size-width-narrow: 2px;
  --emu-component-containers-comparison-slider-divider-size-width-wide: 2px;
  --emu-component-containers-comparison-slider-handle-size-width-narrow: var(--emu-semantic-sizing-medium);
  --emu-component-containers-comparison-slider-handle-size-width-wide: var(--emu-semantic-sizing-large);
  --emu-component-containers-comparison-slider-custom-handle-padding-top-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-top-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-right-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-right-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-bottom-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-bottom-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-left-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-left-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-arrow-size-width-narrow: var(--emu-common-sizing-small);
  --emu-component-containers-comparison-slider-custom-handle-arrow-size-width-wide: var(--emu-common-sizing-small);
  --emu-component-containers-comparison-slider-custom-handle-arrow-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-comparison-slider-custom-handle-arrow-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-comparison-slider-custom-handle-arrow-thickness-narrow: var(--emu-semantic-border-width-thin);
  --emu-component-containers-comparison-slider-custom-handle-arrow-thickness-wide: var(--emu-semantic-border-width-thin);
  --emu-component-containers-comparison-slider-custom-handle-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-containers-comparison-slider-custom-handle-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-comparison-slider-custom-handle-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-comparison-slider-custom-handle-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-comparison-slider-custom-handle-text-font-size-narrow: var(--emu-semantic-font-sizes-narrow-medium);
  --emu-component-containers-comparison-slider-custom-handle-text-font-size-wide: var(--emu-semantic-font-sizes-wide-medium);
  --emu-component-containers-comparison-slider-custom-handle-text-line-height-narrow: var(--emu-semantic-line-heights-narrow-medium);
  --emu-component-containers-comparison-slider-custom-handle-text-line-height-wide: var(--emu-semantic-line-heights-wide-medium);
  --emu-component-containers-tabs-gap-horizontal-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-tabs-gap-horizontal-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-tabs-gap-vertical-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-tabs-gap-vertical-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-tabs-navigation-item-padding-top-narrow: var(--emu-component-containers-container-padding-top-narrow);
  --emu-component-containers-tabs-navigation-item-padding-top-wide: var(--emu-component-containers-container-padding-top-wide);
  --emu-component-containers-tabs-navigation-item-padding-right-narrow: var(--emu-component-containers-container-padding-right-narrow);
  --emu-component-containers-tabs-navigation-item-padding-right-wide: var(--emu-component-containers-container-padding-right-wide);
  --emu-component-containers-tabs-navigation-item-padding-bottom-narrow: var(--emu-component-containers-container-padding-bottom-narrow);
  --emu-component-containers-tabs-navigation-item-padding-bottom-wide: var(--emu-component-containers-container-padding-bottom-wide);
  --emu-component-containers-tabs-navigation-item-padding-left-narrow: var(--emu-component-containers-container-padding-left-narrow);
  --emu-component-containers-tabs-navigation-item-padding-left-wide: var(--emu-component-containers-container-padding-left-wide);
  --emu-component-containers-tabs-navigation-item-gap-horizontal-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-tabs-navigation-item-gap-horizontal-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-tabs-navigation-item-gap-vertical-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-tabs-navigation-item-gap-vertical-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-tabs-navigation-item-border-width-top-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-top-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-right-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-right-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-bottom-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-bottom-active: var(--emu-semantic-border-width-thin);
  --emu-component-containers-tabs-navigation-item-border-width-left-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-left-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-color-border-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-tabs-navigation-item-color-border-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-border-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-tabs-navigation-item-color-border-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-background-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-tabs-navigation-item-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-background-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-tabs-navigation-item-color-background-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-tabs-navigation-item-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-tabs-navigation-item-text-color-active-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-tabs-navigation-item-text-color-active-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-tabs-content-padding-top-narrow: var(--emu-component-containers-container-padding-top-narrow);
  --emu-component-containers-tabs-content-padding-top-wide: var(--emu-component-containers-container-padding-top-wide);
  --emu-component-containers-tabs-content-padding-right-narrow: var(--emu-component-containers-container-padding-right-narrow);
  --emu-component-containers-tabs-content-padding-right-wide: var(--emu-component-containers-container-padding-right-wide);
  --emu-component-containers-tabs-content-padding-bottom-narrow: var(--emu-component-containers-container-padding-bottom-narrow);
  --emu-component-containers-tabs-content-padding-bottom-wide: var(--emu-component-containers-container-padding-bottom-wide);
  --emu-component-containers-tabs-content-padding-left-narrow: var(--emu-component-containers-container-padding-left-narrow);
  --emu-component-containers-tabs-content-padding-left-wide: var(--emu-component-containers-container-padding-left-wide);
  --emu-component-containers-tabs-content-gap-horizontal-narrow: var(--emu-component-containers-container-gap-horizontal-narrow);
  --emu-component-containers-tabs-content-gap-horizontal-wide: var(--emu-component-containers-container-gap-horizontal-wide);
  --emu-component-containers-tabs-content-gap-vertical-narrow: var(--emu-component-containers-container-gap-vertical-narrow);
  --emu-component-containers-tabs-content-gap-vertical-wide: var(--emu-component-containers-container-gap-vertical-wide);
  --emu-component-containers-tabs-content-color-background-light: var(--emu-component-containers-container-color-background-light);
  --emu-component-containers-tabs-content-color-background-dark: var(--emu-component-containers-container-color-background-dark);
  --emu-component-containers-isi-header-padding-top-narrow: 0px;
  --emu-component-containers-isi-header-padding-top-wide: 0px;
  --emu-component-containers-isi-header-padding-right-narrow: 20px;
  --emu-component-containers-isi-header-padding-right-wide: 65px;
  --emu-component-containers-isi-header-padding-bottom-narrow: 0px;
  --emu-component-containers-isi-header-padding-bottom-wide: 0px;
  --emu-component-containers-isi-header-padding-left-narrow: 20px;
  --emu-component-containers-isi-header-padding-left-wide: 70px;
  --emu-component-containers-isi-header-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-header-color-background-light: var(--emu-semantic-colors-primary-teal-100);
  --emu-component-containers-isi-header-color-border-top-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-header-color-border-top-light: var(--emu-semantic-colors-primary-teal-400);
  --emu-component-containers-isi-header-border-width-top: var(--emu-semantic-border-width-thick);
  --emu-component-containers-isi-body-padding-top-narrow: 26px;
  --emu-component-containers-isi-body-padding-top-wide: 28px;
  --emu-component-containers-isi-body-padding-right-narrow: 25px;
  --emu-component-containers-isi-body-padding-right-wide: 70px;
  --emu-component-containers-isi-body-padding-bottom-narrow: 51px;
  --emu-component-containers-isi-body-padding-bottom-wide: 51px;
  --emu-component-containers-isi-body-padding-left-narrow: 25px;
  --emu-component-containers-isi-body-padding-left-wide: 70px;
  --emu-component-containers-isi-body-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-body-color-background-light: var(--emu-semantic-colors-primary-teal-100);
  --emu-component-containers-isi-body-color-border-top-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-body-color-border-top-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-isi-body-border-width-top: var(--emu-semantic-border-width-thin);
  --emu-component-containers-isi-open-content-size: 30vh;
  --emu-component-containers-isi-z-index: 400;
  --emu-component-containers-container-max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
  --emu-component-containers-container-padding-top-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-containers-container-padding-top-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-containers-container-padding-right-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-container-padding-right-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-container-padding-bottom-narrow: var(--emu-semantic-spacing-vertical);
  --emu-component-containers-container-padding-bottom-wide: var(--emu-semantic-spacing-vertical);
  --emu-component-containers-container-padding-left-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-container-padding-left-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-container-gap-horizontal-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-container-gap-horizontal-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-container-gap-vertical-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-container-gap-vertical-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-container-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-container-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-carousel-gap-horizontal-narrow: 5px;
  --emu-component-containers-carousel-gap-horizontal-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-gap-vertical-narrow: 0px;
  --emu-component-containers-carousel-gap-vertical-wide: 0px;
  --emu-component-containers-carousel-indicators-margin-top-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-indicators-margin-top-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-indicators-margin-bottom-narrow: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-indicators-margin-bottom-wide: var(--emu-semantic-spacing-horizontal);
  --emu-component-containers-carousel-indicators-gap-horizontal-narrow: 5px;
  --emu-component-containers-carousel-indicators-gap-horizontal-wide: 20px;
  --emu-component-containers-carousel-indicators-dots-color-background-default-light: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-indicators-dots-color-background-default-dark: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-indicators-dots-color-background-active-light: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-indicators-dots-color-background-active-dark: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-indicators-dots-color-border-default-light: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-indicators-dots-color-border-default-dark: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-indicators-dots-color-border-active-light: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-indicators-dots-color-border-active-dark: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-indicators-dots-border-width-all: var(--emu-common-border-width-none);
  --emu-component-containers-carousel-indicators-dots-size-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-dots-size-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-image-container-size-narrow: 290px;
  --emu-component-containers-carousel-indicators-image-container-size-wide: 1110px;
  --emu-component-containers-carousel-indicators-image-color-border-default-light: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-indicators-image-color-border-default-dark: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-indicators-image-color-border-active-light: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-indicators-image-color-border-active-dark: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-indicators-image-border-width-all: var(--emu-common-border-width-none);
  --emu-component-containers-carousel-indicators-image-size-narrow: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-indicators-image-size-wide: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-controls-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-controls-color-background-dark: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-controls-color-border-light: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-controls-color-border-dark: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-controls-border-width-all: var(--emu-common-border-width-none);
  --emu-component-containers-carousel-controls-z-index: 100;
  --emu-component-containers-carousel-controls-icon-color-fill-light: var(--emu-semantic-colors-primary-teal-300);
  --emu-component-containers-carousel-controls-icon-color-fill-dark: var(--emu-semantic-colors-primary-teal-300);
  --emu-component-containers-carousel-controls-icon-size-narrow: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-controls-icon-size-wide: var(--emu-common-sizing-large);
  --emu-component-containers-embed-gap-horizontal-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-horizontal-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-vertical-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-vertical-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-embed-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-embed-player-vimeo-global-color-light: #00b3ff;
  --emu-component-containers-embed-player-vimeo-global-color-dark: #00b3ff;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-base: 15px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small: 13px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-large: 18px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-xlarge: 21px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-weight-regular: 400;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-weight-bold: 600;
  --emu-component-containers-embed-player-vimeo-global-text-typography-line-height: 1.7px;
  --emu-component-containers-embed-player-vimeo-color-background-light: #000;
  --emu-component-containers-embed-player-vimeo-color-background-dark: #000;
  --emu-component-containers-embed-player-vimeo-outline-color-focus-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-outline-color-focus-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-badge-border-radius-all: 2px;
  --emu-component-containers-embed-player-vimeo-badge-color-background-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-badge-color-background-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-badge-text-typography-font-size: 9px;
  --emu-component-containers-embed-player-vimeo-badge-text-color-light: #fff;
  --emu-component-containers-embed-player-vimeo-badge-text-color-dark: #fff;
  --emu-component-containers-embed-player-vimeo-menu-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-menu-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-menu-color-border-light: #dcdfe5;
  --emu-component-containers-embed-player-vimeo-menu-color-border-dark: #dcdfe5;
  --emu-component-containers-embed-player-vimeo-menu-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-size: 4px;
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-color-light: #728197;
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-color-dark: #728197;
  --emu-component-containers-embed-player-vimeo-menu-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-embed-player-vimeo-menu-text-color-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-menu-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-menu-border-radius-all: 4px;
  --emu-component-containers-embed-player-vimeo-menu-arrow-size: 6px;
  --emu-component-containers-embed-player-vimeo-controls-global-spacing: 10px;
  --emu-component-containers-embed-player-vimeo-controls-global-icon-sizing: 18px;
  --emu-component-containers-embed-player-vimeo-controls-global-padding: 7px;
  --emu-component-containers-embed-player-vimeo-controls-global-toggle-color-background-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-global-toggle-color-background-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-global-border-radius-all: 3px;
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-tooltip-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-tooltip-text-color-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-vimeo-tooltip-border-radius-all: 3px;
  --emu-component-containers-embed-player-vimeo-tooltip-padding: 5px;
  --emu-component-containers-embed-player-vimeo-tooltip-arrow-size: 4px;
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-active-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-active-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-loading-light: #23282f99;
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-loading-dark: #23282f99;
  --emu-component-containers-embed-player-vimeo-scrubber-sizing-height: 5px;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-sizing-height: 13px;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-color-background-light: #fff;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-color-background-dark: #fff;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-one: 0 1 1 0 #d71a1226;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-two: 0 0 0 1 #d71a1233;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-width-active: 3px;
  --emu-component-containers-embed-player-vimeo-scrubber-loading-size: 25px;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-progress-color-background-buffered-light: #c1c8d199;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-progress-color-background-buffered-dark: #c1c8d199;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-thumb-shadow-active-light: #d71a121a;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-thumb-shadow-active-dark: #d71a121a;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-progress-color-background-buffered-light: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-progress-color-background-buffered-dark: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-thumb-shadow-active-light: #ffffff80;
  --emu-component-containers-embed-player-vimeo-scrubber-video-thumb-shadow-active-dark: #ffffff80;
  --emu-component-containers-embed-player-vimeo-scrubber-video-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-vimeo-captions-text-color-light: #fff;
  --emu-component-containers-embed-player-vimeo-captions-text-color-dark: #fff;
  --emu-component-containers-embed-player-vimeo-captions-color-background-light: #000c;
  --emu-component-containers-embed-player-vimeo-captions-color-background-dark: #000c;
  --emu-component-containers-embed-player-vimeo-time-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-embed-player-youtube-global-color-light: #00b3ff;
  --emu-component-containers-embed-player-youtube-global-color-dark: #00b3ff;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-base: 15px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-small: 13px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-large: 18px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-xlarge: 21px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-weight-regular: 400;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-weight-bold: 600;
  --emu-component-containers-embed-player-youtube-global-text-typography-line-height: 1.7px;
  --emu-component-containers-embed-player-youtube-color-background-light: #000;
  --emu-component-containers-embed-player-youtube-color-background-dark: #000;
  --emu-component-containers-embed-player-youtube-outline-color-focus-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-outline-color-focus-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-badge-border-radius-all: 2px;
  --emu-component-containers-embed-player-youtube-badge-color-background-light: #4a5464;
  --emu-component-containers-embed-player-youtube-badge-color-background-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-badge-text-typography-font-size: 9px;
  --emu-component-containers-embed-player-youtube-badge-text-color-light: #fff;
  --emu-component-containers-embed-player-youtube-badge-text-color-dark: #fff;
  --emu-component-containers-embed-player-youtube-menu-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-youtube-menu-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-youtube-menu-color-border-light: #dcdfe5;
  --emu-component-containers-embed-player-youtube-menu-color-border-dark: #dcdfe5;
  --emu-component-containers-embed-player-youtube-menu-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-youtube-menu-item-arrow-size: 4px;
  --emu-component-containers-embed-player-youtube-menu-item-arrow-color-light: #728197;
  --emu-component-containers-embed-player-youtube-menu-item-arrow-color-dark: #728197;
  --emu-component-containers-embed-player-youtube-menu-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-embed-player-youtube-menu-text-color-light: #4a5464;
  --emu-component-containers-embed-player-youtube-menu-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-menu-border-radius-all: 4px;
  --emu-component-containers-embed-player-youtube-menu-arrow-size: 6px;
  --emu-component-containers-embed-player-youtube-controls-global-spacing: 10px;
  --emu-component-containers-embed-player-youtube-controls-global-icon-sizing: 18px;
  --emu-component-containers-embed-player-youtube-controls-global-padding: 7px;
  --emu-component-containers-embed-player-youtube-controls-global-toggle-color-background-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-controls-global-toggle-color-background-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-controls-global-border-radius-all: 3px;
  --emu-component-containers-embed-player-youtube-controls-video-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-youtube-controls-video-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-youtube-controls-video-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-controls-video-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-light: #fff;
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-dark: #fff;
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-light: #4a5464;
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-tooltip-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-youtube-tooltip-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-youtube-tooltip-text-color-light: #4a5464;
  --emu-component-containers-embed-player-youtube-tooltip-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-tooltip-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-youtube-tooltip-border-radius-all: 3px;
  --emu-component-containers-embed-player-youtube-tooltip-padding: 5px;
  --emu-component-containers-embed-player-youtube-tooltip-arrow-size: 4px;
  --emu-component-containers-embed-player-youtube-scrubber-color-background-active-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-scrubber-color-background-active-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-scrubber-color-background-loading-light: #23282f99;
  --emu-component-containers-embed-player-youtube-scrubber-color-background-loading-dark: #23282f99;
  --emu-component-containers-embed-player-youtube-scrubber-sizing-height: 5px;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-sizing-height: 13px;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-color-background-light: #fff;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-color-background-dark: #fff;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-shadow-one: 0 1 1 0 #d71a1226;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-shadow-two: 0 0 0 1 #d71a1233;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-shadow-width-active: 3px;
  --emu-component-containers-embed-player-youtube-scrubber-loading-size: 25px;
  --emu-component-containers-embed-player-youtube-scrubber-audio-progress-color-background-buffered-light: #c1c8d199;
  --emu-component-containers-embed-player-youtube-scrubber-audio-progress-color-background-buffered-dark: #c1c8d199;
  --emu-component-containers-embed-player-youtube-scrubber-audio-thumb-shadow-active-light: #d71a121a;
  --emu-component-containers-embed-player-youtube-scrubber-audio-thumb-shadow-active-dark: #d71a121a;
  --emu-component-containers-embed-player-youtube-scrubber-audio-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-audio-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-progress-color-background-buffered-light: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-progress-color-background-buffered-dark: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-thumb-shadow-active-light: #ffffff80;
  --emu-component-containers-embed-player-youtube-scrubber-video-thumb-shadow-active-dark: #ffffff80;
  --emu-component-containers-embed-player-youtube-scrubber-video-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-youtube-captions-text-color-light: #fff;
  --emu-component-containers-embed-player-youtube-captions-text-color-dark: #fff;
  --emu-component-containers-embed-player-youtube-captions-color-background-light: #000c;
  --emu-component-containers-embed-player-youtube-captions-color-background-dark: #000c;
  --emu-component-containers-embed-player-youtube-time-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-gallery-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-border-width: var(--emu-semantic-border-width-thin);
  --emu-component-containers-gallery-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-separator-content-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-separator-content-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-separator-content-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-separator-content-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-sizing-width-narrow: 100%;
  --emu-component-containers-gallery-item-sizing-width-wide: calc(33.33% - 8px);
  --emu-component-containers-gallery-item-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-border-width: var(--emu-semantic-border-width-thin);
  --emu-component-containers-gallery-item-text-color-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-text-color-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-active-text-color-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-active-text-color-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-active-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-active-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-active-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-active-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-hover-text-color-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-hover-text-color-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-hover-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-hover-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-hover-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-hover-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-stage-container-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-gap-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-preview-container-gap-wide: var(--emu-common-sizing-xs);
  --emu-component-layers-header: 500;
  --emu-component-layers-modal-z-index: 600;
  --emu-component-layers-modal-scrim-color-background: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-component-layers-modal-scrim-border-radius-top-left: var(--emu-common-border-radius-small);
  --emu-component-layers-modal-scrim-border-radius-top-right: var(--emu-common-border-radius-small);
  --emu-component-layers-modal-scrim-border-radius-bottom-right: var(--emu-common-border-radius-small);
  --emu-component-layers-modal-scrim-border-radius-bottom-left: var(--emu-common-border-radius-small);
  --emu-component-layers-modal-body-sizing-width: 80%;
  --emu-component-layers-modal-body-sizing-max-width: 600px;
  --emu-component-layers-modal-body-padding-top-narrow: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-top-wide: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-right-narrow: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-right-wide: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-bottom-narrow: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-bottom-wide: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-left-narrow: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-left-wide: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-color-background-light: var(--emu-common-colors-black);
  --emu-component-layers-modal-body-color-background-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-body-color-border-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-layers-modal-body-color-border-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-body-border-width-all: var(--emu-semantic-border-width-thin);
  --emu-component-layers-modal-actions-close-padding-top: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-actions-close-padding-right: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-actions-close-padding-bottom: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-actions-close-padding-left: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-actions-close-text-typography: var(--emu-semantic-typography-narrow-headings-xxl);
  --emu-component-layers-modal-actions-close-text-color-light: var(--emu-common-colors-black);
  --emu-component-layers-modal-actions-close-text-color-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-actions-prompt-button-group-gap-narrow: var(--emu-common-spacing-small);
  --emu-component-layers-modal-actions-prompt-button-group-gap-wide: var(--emu-common-spacing-small);
  --emu-component-layers-modal-actions-prompt-button-group-margin-top: var(--emu-semantic-spacing-horizontal);
  --emu-component-layers-modal-content-padding-wide-top: 42px;
  --emu-component-layers-modal-content-padding-wide-right: 34px;
  --emu-component-layers-modal-content-padding-wide-bottom: 21px;
  --emu-component-layers-modal-content-padding-wide-left: 32px;
  --emu-component-layers-cookie-consent-banner-z-index: 700;
  --emu-component-layers-cookie-consent-banner-max-width: var(--emu-component-containers-container-max-width);
  --emu-component-layers-cookie-consent-banner-text-typography-narrow: var(--emu-semantic-font-weight-regular) 12px / 17px var(--emu-semantic-font-families-heading);
  --emu-component-layers-cookie-consent-banner-text-typography-wide: var(--emu-semantic-font-weight-regular) 12px / 17px var(--emu-semantic-font-families-heading);
  --emu-component-layers-cookie-consent-banner-text-color-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-text-color-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-color-background-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-color-background-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-shadow: 4px 4px 20px 4px #0003;
  --emu-component-layers-cookie-consent-banner-padding-top-narrow: 24px;
  --emu-component-layers-cookie-consent-banner-padding-top-wide: 24px;
  --emu-component-layers-cookie-consent-banner-padding-right-narrow: 32px;
  --emu-component-layers-cookie-consent-banner-padding-right-wide: 32px;
  --emu-component-layers-cookie-consent-banner-padding-bottom-narrow: 24px;
  --emu-component-layers-cookie-consent-banner-padding-bottom-wide: 24px;
  --emu-component-layers-cookie-consent-banner-padding-left-narrow: 32px;
  --emu-component-layers-cookie-consent-banner-padding-left-wide: 32px;
  --emu-component-layers-cookie-consent-banner-settings-button-text-typography-narrow: var(--emu-semantic-font-weight-regular) 12px / 17px var(--emu-semantic-font-families-heading);
  --emu-component-layers-cookie-consent-banner-settings-button-text-typography-wide: var(--emu-semantic-font-weight-regular) 12px / 17px var(--emu-semantic-font-families-heading);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-focus-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-focus-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-hover-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-hover-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-active-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-active-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-padding-top-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-top-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-right-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-right-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-bottom-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-bottom-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-left-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-left-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-top-narrow: 24px;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-top-wide: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-right-narrow: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-right-wide: 0px;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-bottom-narrow: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-bottom-wide: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-left-narrow: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-left-wide: 56px;
  --emu-component-layers-cookie-consent-banner-settings-button-border-width: 1px;
  --emu-component-layers-cookie-consent-banner-settings-button-outline-width: 1px;
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-top-left: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-top-right: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-bottom-right: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-bottom-left: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-focus-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-focus-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-hover-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-hover-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-active-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-active-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-dark: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-focus-light: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-focus-dark: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-hover-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-hover-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-active-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-active-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-light: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-dark: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-focus-light: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-hover-light: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-active-light: var(--emu-common-colors-grey-300);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-active-dark: var(--emu-common-colors-grey-300);
}

html {
  -webkit-text-size-adjust: 100%;
}

html, body {
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  font-family: var(--emu-semantic-font-families-body);
  text-align: left;
  margin: 0;
  padding: 0;
  line-height: 1.4;
}

html .xf-content-height, body .xf-content-height {
  margin: inherit;
}

html .experiencefragment, body .experiencefragment {
  padding: 0;
}

body {
  -webkit-overflow-scrolling: touch;
}

* {
  outline: var(--emu-semantic-colors-surface-dark);
}

*, :before, :after {
  box-sizing: border-box;
}

.cmp-image__image, image {
  max-width: 100%;
  width: auto;
}

button, input, optgroup, select, textarea {
  font: inherit;
}

.emphasis {
  color: var(--emu-semantic-colors-primary-teal-500);
}

#maincontent > .aem-Grid {
  height: 100%;
  flex-direction: column;
  display: flex;
}

#maincontent > .aem-Grid > .container {
  flex: 1 0 auto;
}

#maincontent > .aem-Grid > .experiencefragment {
  flex-shrink: 0;
}

.desktop-view {
  display: none;
}

@media (min-width: 1024px) {
  .desktop-view {
    display: block;
  }
}

.tablet-desktop-view {
  display: none;
}

@media (min-width: 768px) {
  .tablet-desktop-view {
    display: block;
  }
}

.tablet-mobile-view {
  display: block;
}

@media (min-width: 1024px) {
  .tablet-mobile-view {
    display: none;
  }
}

.mobile-view {
  display: block;
}

@media (min-width: 768px) {
  .mobile-view {
    display: none;
  }
}

.d-none {
  display: none !important;
}

.no-pad {
  padding: 0;
}

.no-x-pad {
  padding-left: 0;
  padding-right: 0;
}

.no-y-pad {
  padding-top: 0;
  padding-bottom: 0;
}

@media (min-width: 1024px) {
  .container .aaaem-container.container--large {
    max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
    margin-left: auto;
    margin-right: auto;
  }
}

body#about-skinvive .teaser {
  width: 100%;
  overflow: hidden;
}

@media (min-width: 1024px) {
  body#about-skinvive .teaser .teaser-container {
    height: 100%;
    align-items: flex-start;
  }
}

body#about-skinvive .teaser .title-lockup h1 {
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-none);
}

@media (min-width: 1024px) {
  body#about-skinvive .teaser .title-lockup {
    margin-left: var(--emu-common-spacing-none);
  }
}

body#about-skinvive .teaser .content-container {
  padding-bottom: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-medium);
}

@media (min-width: 1024px) {
  body#about-skinvive .teaser .content-container {
    margin-left: var(--emu-common-spacing-none);
    justify-content: flex-end;
    align-items: flex-end;
  }

  body#about-skinvive .teaser .content-container .content {
    width: 41%;
    margin-right: var(--emu-common-spacing-none);
    padding-bottom: 135px;
    position: relative;
  }
}

body#about-skinvive .teaser .content-container .aaaem-teaser__subtitle {
  display: none;
}

@media (min-width: 1024px) {
  body#about-skinvive .teaser .content-container .aaaem-teaser__subtitle {
    width: 93%;
    text-align: left;
    font-size: var(--emu-semantic-font-sizes-wide-xs);
    line-height: var(--emu-semantic-line-heights-wide-small);
    text-indent: -7px;
    display: block;
    position: absolute;
    bottom: 0;
  }
}

body#about-skinvive .teaser .content-container .disclaimer {
  visibility: visible;
  margin-bottom: 28px;
}

@media (min-width: 1024px) {
  body#about-skinvive .teaser .content-container .image--disclaimer {
    visibility: hidden;
  }
}

body#about-skinvive .teaser .cmp-teaser__image img {
  -o-object-position: 10%;
  object-position: 10%;
}

body#about-skinvive .teaser .cmp-teaser__description p {
  margin-bottom: var(--emu-common-spacing-none);
}

body#about-skinvive .teaser .cmp-teaser__description p b {
  font-family: var(--emu-semantic-font-families-body);
  font-weight: var(--emu-common-font-weight-light);
}

@media (min-width: 1024px) {
  body#about-skinvive .teaser .cmp-teaser__description p b {
    font-size: var(--emu-semantic-font-sizes-wide-medium);
  }

  body#about-skinvive .teaser .cmp-teaser__description p {
    font-size: var(--emu-semantic-font-sizes-wide-large);
  }
}

@media (min-width: 768px) {
  body#about-skinvive .teaser .cmp-teaser__description {
    width: 55%;
  }
}

@media (min-width: 1024px) {
  body#about-skinvive .teaser .cmp-teaser__description {
    width: 100%;
  }
}

body#about-skinvive .about--results {
  padding-left: 22px;
  padding-right: 22px;
}

@media (min-width: 1024px) {
  body#about-skinvive .about--results {
    display: none;
  }
}

body#about-skinvive .about--results p {
  font-size: var(--emu-common-font-sizes-narrow-medium);
  line-height: var(--emu-semantic-line-heights-narrow-xxs);
  text-align: center;
}

body#about-skinvive .about-clinically-proven-wrapper {
  margin-top: 30px;
}

body#about-skinvive .about-clinically-proven-wrapper .aem-Grid {
  max-width: var(--emu-component-containers-container-max-width);
}

@media (min-width: 1024px) {
  body#about-skinvive .about-clinically-proven-wrapper .aem-Grid {
    align-items: center;
  }
}

@media (min-width: 1201px) {
  body#about-skinvive .about-clinically-proven-wrapper .aem-Grid > div:nth-of-type(1), body#about-skinvive .about-clinically-proven-wrapper .aem-Grid > div:nth-of-type(2) {
    width: 100%;
  }
}

body#about-skinvive .about-clinically-proven-wrapper .aem-Grid .plyr {
  border-radius: 23px;
}

@media (min-width: 1024px) {
  body#about-skinvive .about-clinically-proven-wrapper .aem-Grid .cmp-text {
    margin: auto;
    padding-top: 30px;
  }

  body#about-skinvive .about-clinically-proven-wrapper .aem-Grid .cmp-text p {
    text-align: center;
  }
}

body#about-skinvive .about-clinically-proven-wrapper p {
  text-align: center;
  width: 85%;
  font: var(--emu-semantic-typography-narrow-body-intro-large);
  margin: auto;
  padding-bottom: 40px;
}

body#about-skinvive .about-clinically-proven-wrapper p:nth-of-type(2) {
  font: var(--emu-semantic-typography-narrow-body-footnotes);
  padding-bottom: 40px;
}

@media (min-width: 1024px) {
  body#about-skinvive .about-clinically-proven-wrapper p {
    text-align: left;
    padding-bottom: var(--emu-common-spacing-none);
    width: 55%;
    font: var(--emu-semantic-typography-wide-body-intro-large);
    margin-left: auto;
    margin-right: auto;
  }

  body#about-skinvive .about-clinically-proven-wrapper p:nth-of-type(2) {
    font: var(--emu-semantic-typography-wide-body-footnotes);
    width: 45%;
    padding-top: 39px;
    padding-bottom: var(--emu-common-spacing-none);
  }
}

body#about-skinvive .about-clinically-proven-wrapper .embed {
  margin-bottom: 53px;
}

@media (min-width: 1024px) {
  body#about-skinvive .about-clinically-proven-wrapper .embed {
    align-self: flex-start;
    margin-bottom: 41px;
  }
}

body#about-skinvive .about-clinically-proven-wrapper .embed .aaaem-embed {
  width: 88%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  body#about-skinvive .about-clinically-proven-wrapper .embed .aaaem-embed {
    width: 820px;
  }
}

body#about-skinvive .about-clinically-proven-wrapper .embed .cmp-embed div {
  border-radius: var(--emu-semantic-border-radius-medium);
}

@media (min-width: 1024px) {
  body#about-skinvive .about-clinically-proven-wrapper {
    margin-top: 93px;
    margin-bottom: 50px;
  }

  body#about-skinvive .about-checker-wrapper > .cmp-container > .aem-Grid > .text {
    width: calc(50% - 11px);
  }
}

body#about-skinvive .about--image--text--container__even .aem-Grid > .teaser .image--disclaimer, body#about-skinvive .about--image--text--container__odd .aem-Grid > .teaser .image--disclaimer {
  visibility: visible;
}

body#about-skinvive .about--image--text--container__even > .aem-Grid > .teaser {
  margin-left: auto;
}

body#about-skinvive .about--image--text--container__even > .aem-Grid > .text p .emphasis {
  color: inherit;
  display: inline-block;
}

body#about-skinvive .about--image--text--container__odd > .aem-Grid > .text {
  margin-left: auto;
}

body#about-skinvive .about-checker-wrapper {
  padding-bottom: 40px;
}

@media (min-width: 1024px) {
  body#about-skinvive .about-checker-wrapper {
    padding-bottom: 22px;
  }
}

body#about-skinvive .about-checker-wrapper .aem-Grid {
  width: 86%;
  max-width: var(--emu-component-containers-container-max-width);
  flex-direction: column-reverse;
  margin: auto;
  display: flex;
}

@media (min-width: 1024px) {
  body#about-skinvive .about-checker-wrapper .aem-Grid {
    flex-direction: row;
  }
}

body#about-skinvive .about-checker-wrapper .cmp-text {
  background-color: var(--emu-semantic-colors-primary-bone);
  border-radius: 23px;
  padding: 36px 20px 36px 25px;
  overflow: hidden;
}

@media (min-width: 1024px) {
  body#about-skinvive .about-checker-wrapper .cmp-text {
    height: 100%;
    padding: 58px 92px 57px 91px;
  }
}

body#about-skinvive .about-checker-wrapper .cmp-text h3 {
  font-family: var(--emu-semantic-font-families-body);
  font-weight: var(--emu-common-font-weight-regular);
  margin-top: var(--emu-common-spacing-none);
  color: var(--emu-semantic-colors-primary-teal-400);
  margin-bottom: 21px;
  font-size: 25px;
  line-height: normal;
}

@media (min-width: 1024px) {
  body#about-skinvive .about-checker-wrapper .cmp-text h3 {
    margin-bottom: 10px;
    font-weight: 500;
  }
}

body#about-skinvive .about-checker-wrapper .cmp-text h3:not(:first-child) {
  margin-top: 44px;
}

@media (min-width: 1024px) {
  body#about-skinvive .about-checker-wrapper .cmp-text h3:not(:first-child) {
    margin-top: 40px;
  }
}

body#about-skinvive .about-checker-wrapper .cmp-text p {
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-none);
  font-size: 18px;
  line-height: 25px;
}

@media (min-width: 1024px) {
  body#about-skinvive .about-checker-wrapper .cmp-text p {
    letter-spacing: -.3px;
    font-size: 20px;
    line-height: 30px;
  }

  body#about-skinvive .about-checker-wrapper .teaser {
    width: calc(50% - 11px);
  }

  body#about-skinvive .about-checker-wrapper .teaser .has-media, body#about-skinvive .about-checker-wrapper .teaser .teaser-container .cmp-teaser__image, body#about-skinvive .about-checker-wrapper .teaser .teaser-container .cmp-teaser__image .cmp-image, body#about-skinvive .about-checker-wrapper .teaser .teaser-container .cmp-teaser__image .cmp-image__link {
    height: 100%;
  }
}

body#about-skinvive .about-checker-wrapper .teaser .teaser-container .cmp-teaser__image img {
  height: auto;
  max-width: 500px;
  border-radius: 23px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  body#about-skinvive .about-checker-wrapper .teaser .teaser-container .cmp-teaser__image img {
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
    max-width: unset;
    -o-object-position: center;
    object-position: center;
    position: static;
  }
}

body#about-skinvive .about-checker-wrapper .teaser .teaser-container .content-container {
  align-items: center;
  margin-bottom: 0;
  position: relative;
  top: -40px;
}

@media (min-width: 1024px) {
  body#about-skinvive .about-checker-wrapper .teaser .teaser-container .content-container {
    position: absolute;
    top: auto;
    bottom: 15px;
  }
}

body#about-skinvive .about-checker-wrapper .teaser .teaser-container .content-container .disclaimer {
  margin-bottom: var(--emu-common-spacing-none);
  padding-right: var(--emu-common-spacing-none);
}

body#about-skinvive .about-checker-wrapper .teaser .teaser-container .content-container .disclaimer p {
  color: var(--emu-common-colors-white);
}

@media (min-width: 1024px) {
  body#about-skinvive .about-checker-wrapper:nth-of-type(2n+1) .cmp-container .aem-Grid {
    flex-direction: row-reverse;
  }

  body#about-skinvive .about-checker-wrapper:nth-of-type(2n) .cmp-container .aem-Grid {
    flex-direction: row;
  }
}

body#about-skinvive .about-expect-from-treatment-wrapper {
  margin-bottom: 102px;
}

@media (min-width: 1024px) {
  body#about-skinvive .about-expect-from-treatment-wrapper {
    margin-bottom: 152px;
  }
}

body#about-skinvive .about-expect-from-treatment-wrapper .aem-Grid {
  width: 86%;
  max-width: var(--emu-component-containers-container-max-width);
  background-color: var(--emu-semantic-colors-primary-bone);
  border-radius: 23px;
  margin: auto;
}

body#about-skinvive .about-expect-from-treatment-wrapper .aem-Grid .embed .cmp-embed div {
  border-radius: var(--emu-semantic-border-radius-medium);
}

body#about-skinvive .about-expect-from-treatment-wrapper .text {
  width: 100%;
}

body#about-skinvive .about-expect-from-treatment-wrapper .text h2 {
  width: 90%;
  text-align: center;
  margin: auto;
  padding-top: 42px;
}

@media (min-width: 1024px) {
  body#about-skinvive .about-expect-from-treatment-wrapper .text h2 {
    font-size: var(--emu-semantic-font-sizes-wide-xl);
    line-height: var(--emu-semantic-line-heights-wide-xxl);
    font-weight: var(--emu-common-font-weight-light);
    width: 100%;
    padding-bottom: 37px;
  }
}

body#about-skinvive .about-expect-from-treatment-wrapper .text ul {
  margin-bottom: var(--emu-common-spacing-none);
  padding-bottom: 26px;
  padding-left: 10px;
  padding-right: 40px;
  position: relative;
}

@media (min-width: 1024px) {
  body#about-skinvive .about-expect-from-treatment-wrapper .text ul {
    margin-top: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    padding-left: var(--emu-common-spacing-none);
  }
}

body#about-skinvive .about-expect-from-treatment-wrapper .text ul li {
  font: var(--emu-semantic-typography-narrow-body-regular);
  padding-bottom: var(--emu-common-spacing-medium);
  list-style-type: none;
  display: -webkit-box;
}

body#about-skinvive .about-expect-from-treatment-wrapper .text ul li:before {
  content: "";
  background-color: var(--emu-semantic-colors-primary-teal-300);
  width: 13px;
  height: 13px;
  border-radius: 50%;
  border-top-right-radius: var(--emu-common-border-radius-none);
  margin-top: var(--emu-common-spacing-small);
  margin-right: 11px;
  display: flex;
  position: relative;
  transform: rotate(-45deg);
}

@media (min-width: 1024px) {
  body#about-skinvive .about-expect-from-treatment-wrapper .text ul li {
    font: var(--emu-semantic-typography-wide-body-regular);
    width: 73%;
    margin-left: auto;
    margin-right: auto;
  }
}

body#real-results .teaser {
  width: 100%;
  overflow: hidden;
}

body#real-results .teaser .content-container {
  width: 100%;
  padding-bottom: var(--emu-semantic-spacing-none);
}

@media (min-width: 1024px) {
  body#real-results .teaser .content-container {
    max-width: var(--emu-component-containers-container-max-width);
    margin: auto;
    padding-left: 96px;
  }
}

body#real-results .teaser .content-container .disclaimer {
  margin-bottom: 28px;
}

@media (min-width: 1024px) {
  body#real-results .teaser .content-container .disclaimer {
    margin-bottom: var(--emu-common-spacing-medium);
  }
}

body#real-results .teaser .cmp-teaser__description > p, body#real-results .teaser .cmp-teaser__title {
  margin-bottom: var(--emu-semantic-spacing-none);
}

@media (min-width: 768px) {
  body#real-results .teaser .cmp-teaser__description {
    justify-content: center;
    display: flex;
  }
}

@media (min-width: 768px) and (min-width: 1024px) {
  body#real-results .teaser .cmp-teaser__description {
    justify-content: flex-start;
  }
}

@media (min-width: 768px) {
  body#real-results .teaser .cmp-teaser__description p {
    width: 54%;
  }
}

@media (min-width: 1024px) {
  body#real-results .teaser .cmp-teaser__description p {
    width: 35%;
  }
}

body#real-results .real-results-wrapper {
  margin-top: 44px;
  margin-bottom: 48px;
}

body#real-results .real-results-wrapper .aem-Grid {
  max-width: var(--emu-component-containers-container-max-width);
  margin: auto;
}

body#real-results .real-results-wrapper .cmp-text {
  text-align: center;
}

body#real-results .real-results-wrapper h2 {
  font: var(--emu-semantic-typography-narrow-body-intro-large);
  width: 90%;
  margin: auto;
  padding-bottom: 21px;
}

@media (min-width: 1024px) {
  body#real-results .real-results-wrapper h2 {
    font: var(--emu-semantic-typography-wide-body-intro-large);
  }
}

body#real-results .real-results-wrapper h2 sup {
  line-height: 0;
  position: relative;
  top: 5px;
}

@media (min-width: 1024px) {
  body#real-results .real-results-wrapper h2 sup {
    top: 8px;
  }
}

body#real-results .real-results-wrapper p {
  width: 85%;
  font: var(--emu-semantic-typography-narrow-body-footnotes);
  margin: auto;
}

@media (min-width: 1024px) {
  body#real-results .real-results-wrapper p {
    font: var(--emu-semantic-typography-wide-body-footnotes);
  }
}

@media (min-width: 768px) {
  body#real-results .real-results-wrapper h2, body#real-results .real-results-wrapper p {
    width: 60%;
  }
}

@media (min-width: 1024px) {
  body#real-results .real-results-wrapper {
    font: var(--emu-semantic-typography-wide-body-intro-large);
    margin-top: 79px;
  }

  body#real-results .real-results-wrapper h2 {
    width: 70%;
  }

  body#real-results .real-results-wrapper p {
    font: var(--emu-semantic-typography-wide-body-footnotes);
    width: 45%;
    padding-top: 27px;
  }
}

@media (min-width: 1441px) {
  body#real-results .real-results-wrapper h2 {
    width: 60%;
  }

  body#real-results .real-results-wrapper p {
    width: 40%;
  }
}

body#real-results .slider-carousel {
  max-width: 915px;
  margin-left: auto;
  margin-right: auto;
}

body#home .home-teaser-container {
  position: relative;
}

body#home .teaser .content-container {
  padding-top: var(--emu-common-spacing-none);
  padding-bottom: var(--emu-common-spacing-none);
  width: 100%;
  justify-content: flex-end;
  padding-left: 22px;
  padding-right: 22px;
}

@media (min-width: 1024px) {
  body#home .teaser .content-container {
    height: 100%;
    max-width: var(--emu-component-containers-container-max-width);
    justify-content: space-between;
    margin: auto;
    padding-top: 90px;
    padding-left: 90px;
  }
}

body#home .teaser .content {
  order: 1;
}

body#home .teaser .title-lockup {
  margin: var(--emu-common-spacing-none);
  min-height: 493px;
  max-width: none;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 15px;
  display: flex;
}

@media (min-width: 1024px) {
  body#home .teaser .title-lockup {
    max-width: var(--emu-component-cards-teaser-title-max-width);
    min-height: -webkit-fit-content;
    min-height: -moz-fit-content;
    min-height: -moz-fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content;
    flex-shrink: 0;
  }

  body#home .teaser .title-lockup p {
    position: relative;
    top: 145px;
  }
}

@media (min-width: 1201px) {
  body#home .teaser .title-lockup p {
    margin: var(--emu-common-spacing-none);
    line-height: 1;
    top: 225px;
  }
}

@media (min-width: 1024px) {
  body#home .teaser .title-lockup h1 {
    line-height: 1;
    position: absolute;
    top: 21%;
    left: auto;
    right: auto;
  }
}

@media (min-width: 1201px) {
  body#home .teaser .title-lockup h1 {
    top: 18%;
  }
}

@media (min-width: 1024px) {
  body#home .teaser .cmp-teaser {
    height: 815px;
  }
}

body#home .teaser .cmp-teaser__image img {
  height: 493px;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 45%;
  object-position: 45%;
}

@media (min-width: 1024px) {
  body#home .teaser .cmp-teaser__image img {
    height: 100%;
  }
}

@media (max-width: 1024px) {
  body#home .teaser .cmp-teaser__title {
    margin-bottom: 35px;
  }
}

body#home .teaser .cmp-teaser__title {
  text-shadow: 0 2px 4px #00000080;
  font-family: var(--emu-semantic-font-families-juana-light);
  font-size: var(--emu-semantic-font-sizes-wide-large);
  color: var(--emu-semantic-colors-primary-white-solid);
  position: relative;
}

@media (min-width: 1024px) {
  body#home .teaser .cmp-teaser__title {
    text-align: left;
    text-shadow: none;
    font-size: var(--emu-semantic-font-sizes-wide-xxl);
  }
}

@media (min-width: 1201px) {
  body#home .teaser .cmp-teaser__title {
    font-size: 130px;
    line-height: 70px;
  }
}

body#home .teaser .cmp-teaser__title br {
  display: none;
}

@media (min-width: 1024px) {
  body#home .teaser .cmp-teaser__title br {
    display: block;
  }
}

body#home .teaser .cmp-teaser__title i {
  font-style: normal;
  font-size: var(--emu-semantic-font-sizes-narrow-xl);
  font-family: var(--emu-semantic-font-families-body);
  position: relative;
  top: -5px;
  left: 4px;
}

@media (min-width: 1024px) {
  body#home .teaser .cmp-teaser__title i {
    font-size: 39px;
    top: -8px;
  }
}

@media (min-width: 1201px) {
  body#home .teaser .cmp-teaser__title i {
    top: -38px;
  }
}

body#home .teaser .cmp-teaser__title:after {
  display: none;
}

body#home .teaser .cmp-teaser__title b {
  font-weight: var(--emu-common-font-weight-light);
  text-decoration: none;
}

@media (min-width: 1024px) {
  body#home .teaser .cmp-teaser__title b {
    display: none !important;
  }
}

body#home .teaser .cmp-teaser__description {
  margin-top: 42px;
}

@media (min-width: 768px) {
  body#home .teaser .cmp-teaser__description {
    width: 50%;
  }
}

@media (min-width: 1024px) {
  body#home .teaser .cmp-teaser__description {
    margin: var(--emu-common-spacing-none);
    width: 40%;
    text-align: left;
    margin-top: 100px;
  }
}

@media (min-width: 1201px) {
  body#home .teaser .cmp-teaser__description {
    max-width: 365px;
    margin-top: 250px;
  }
}

body#home .teaser .cmp-teaser__description p {
  margin-bottom: var(--emu-common-spacing-none);
  font: var(--emu-semantic-typography-narrow-headings-q-a-headline);
  color: var(--emu-semantic-colors-primary-black);
  line-height: 1.5;
}

@media (min-width: 1024px) {
  body#home .teaser .cmp-teaser__description p {
    margin: var(--emu-common-spacing-none);
    font-size: var(--emu-semantic-font-sizes-narrow-xs);
    color: var(--emu-semantic-colors-primary-white-solid);
    line-height: 32px;
  }
}

@media (min-width: 1201px) {
  body#home .teaser .cmp-teaser__description p {
    font-size: var(--emu-semantic-font-sizes-narrow-medium);
  }
}

body#home .teaser .cmp-teaser__description p b {
  line-break: anywhere;
  display: inline-block;
}

body#home .teaser .aaaem-teaser__action-container {
  background-color: var(--emu-semantic-colors-primary-teal-500);
  border-radius: var(--emu-semantic-border-radius-xl);
  width: 70%;
  margin-top: 34px;
  margin-left: auto;
  margin-right: auto;
  transition: all .3s ease-out;
}

@media (min-width: 768px) {
  body#home .teaser .aaaem-teaser__action-container {
    width: auto;
  }
}

@media (min-width: 1024px) {
  body#home .teaser .aaaem-teaser__action-container {
    margin-top: 40px;
    margin-left: var(--emu-common-spacing-none);
    align-self: flex-start;
    margin-bottom: 20px;
  }
}

body#home .teaser .aaaem-teaser__action-container a {
  font-family: var(--emu-semantic-font-families-heading);
  color: var(--emu-semantic-colors-primary-white-solid);
  font-size: var(--emu-semantic-font-sizes-narrow-small);
  justify-content: center;
  padding: 11px;
  text-decoration: none;
  display: flex;
}

@media (min-width: 768px) {
  body#home .teaser .aaaem-teaser__action-container a {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: var(--emu-common-spacing-small);
    padding-bottom: var(--emu-common-spacing-small);
    letter-spacing: .3px;
  }
}

@media (min-width: 1024px) {
  body#home .teaser .aaaem-teaser__action-container a {
    font-size: var(--emu-semantic-font-sizes-narrow-large);
  }
}

body#home .teaser .aaaem-teaser__action-container:hover, body#home .teaser .aaaem-teaser__action-container:focus {
  background-color: var(--emu-semantic-colors-primary-white-solid);
}

body#home .teaser .aaaem-teaser__action-container:hover a, body#home .teaser .aaaem-teaser__action-container:focus a {
  color: var(--emu-semantic-colors-primary-teal-500);
}

body#home .teaser .aaaem-teaser__action-container:active {
  background-color: var(--emu-semantic-colors-primary-teal-500);
}

body#home .teaser .aaaem-teaser__action-container:active a {
  color: var(--emu-semantic-colors-primary-white-solid);
}

body#home .teaser .disclaimer {
  margin: var(--emu-common-spacing-none);
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

@media (min-width: 768px) {
  body#home .teaser .disclaimer {
    margin-top: var(--emu-common-spacing-none);
    margin-bottom: var(--emu-common-spacing-none);
    width: 68%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1024px) {
  body#home .teaser .disclaimer {
    visibility: visible;
    margin-left: var(--emu-common-spacing-none);
    margin-right: var(--emu-common-spacing-none);
    text-align: left;
    color: var(--emu-semantic-colors-primary-white-solid);
    width: 485px;
    padding-top: 25px;
    padding-bottom: 18px;
  }
}

body#home .teaser .disclaimer p {
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-none);
  font-weight: var(--emu-common-font-weight-bold);
  font-family: var(--emu-semantic-font-families-heading);
  letter-spacing: .165px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  body#home .teaser .disclaimer p {
    font-weight: var(--emu-common-font-weight-regular);
  }

  body#home .teaser .disclaimer p:first-child {
    text-indent: -7px;
  }
}

body#home .teaser .disclaimer p:nth-child(2) {
  padding-top: 5px;
}

@media (min-width: 1024px) {
  body#home .teaser .disclaimer p {
    margin-left: var(--emu-common-spacing-none);
    margin-right: var(--emu-common-spacing-none);
    font-size: var(--emu-semantic-font-sizes-wide-xs);
    line-height: 16px;
    font-family: var(--emu-semantic-font-families-body);
  }
}

body#home .droplet-mobile {
  display: block;
  position: absolute;
  top: 360px;
  right: 34px;
}

@media (min-width: 1024px) {
  body#home .droplet-mobile {
    display: none;
  }
}

body#home .droplet-desktop {
  display: none;
}

@media (min-width: 1024px) {
  body#home .droplet-desktop {
    display: block;
    position: absolute;
    top: 130px;
    right: 30px;
  }

  body#home .droplet-desktop img {
    width: 65%;
    margin-left: auto;
  }
}

body#home .ready-to-glow-wrapper {
  background: radial-gradient(88.3% 79.68% at 55.06% 100%, #d9f0f0 0%, #fdf6f2 100% 100%);
}

@media (min-width: 1024px) {
  body#home .ready-to-glow-wrapper {
    background: radial-gradient(184.7% 134.71% at 48.68% 120.94%, #d9f0f0 0%, #fdf6f2 100%);
  }
}

body#home .ready-to-glow-wrapper .ready-to-glow {
  padding-top: 61px;
  padding-bottom: var(--emu-common-spacing-xl);
  text-align: center;
  background-color: #0000;
  justify-content: center;
  padding-left: 26px;
  padding-right: 26px;
  display: flex;
}

@media (min-width: 1024px) {
  body#home .ready-to-glow-wrapper .ready-to-glow {
    text-align: left;
    padding-left: 70px;
    padding-right: 70px;
  }
}

@media (min-width: 1201px) {
  body#home .ready-to-glow-wrapper .ready-to-glow {
    padding: 116px 130px 60px 106px;
  }
}

body#home .ready-to-glow-wrapper .ready-to-glow > div {
  height: 100%;
  max-width: 1200px;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

@media (min-width: 1024px) {
  body#home .ready-to-glow-wrapper .ready-to-glow > div {
    flex-direction: row;
  }

  body#home .ready-to-glow-wrapper .ready-to-glow > div div:nth-child(1) {
    flex-basis: 33%;
  }

  body#home .ready-to-glow-wrapper .ready-to-glow > div div:nth-child(2) {
    flex-basis: 66%;
  }
}

body#home .ready-to-glow-wrapper .ready-to-glow__image img {
  border: var(--emu-common-border-width-thin);
  height: 320px;
  border-radius: 360px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  body#home .ready-to-glow-wrapper .ready-to-glow__image img {
    height: auto;
    margin-left: var(--emu-common-spacing-none);
    margin-right: var(--emu-common-spacing-none);
  }
}

body#home .ready-to-glow-wrapper .ready-to-glow__text {
  padding-top: 24px;
}

@media (min-width: 1024px) {
  body#home .ready-to-glow-wrapper .ready-to-glow__text {
    padding-left: 47px;
    padding-top: var(--emu-common-spacing-none);
  }
}

body#home .ready-to-glow-wrapper .ready-to-glow__text p {
  font-size: var(--emu-semantic-font-sizes-narrow-large);
  line-height: var(--emu-semantic-line-heights-narrow-large);
  margin: var(--emu-common-spacing-none);
  margin-top: 40px;
}

@media (min-width: 1024px) {
  body#home .ready-to-glow-wrapper .ready-to-glow__text p {
    margin-top: var(--emu-common-spacing-none);
    font-size: var(--emu-semantic-font-sizes-narrow-xxl);
    line-height: var(--emu-semantic-line-heights-narrow-xxl);
    margin-bottom: 40px;
  }
}

@media (min-width: 1201px) {
  body#home .ready-to-glow-wrapper .ready-to-glow__text p {
    font-size: var(--emu-semantic-font-sizes-narrow-xxxl);
    line-height: var(--emu-semantic-line-heights-narrow-xxxl);
  }
}

body#home .ready-to-glow-wrapper .ready-to-glow__text p sup {
  line-height: 0;
}

body#home .light-bg {
  text-align: center;
  background-color: var(--emu-semantic-colors-primary-white-solid);
  padding-bottom: 80px;
  padding-top: var(--emu-common-spacing-none);
}

@media (min-width: 1024px) {
  body#home .light-bg {
    margin-bottom: var(--emu-common-spacing-none);
    padding: 81px 70px 95px;
  }
}

body#home .light-bg .aem-Grid {
  height: 100%;
}

@media (min-width: 1024px) {
  body#home .light-bg .aem-Grid {
    display: flex;
  }
}

body#home .light-bg .container {
  margin-top: 70px;
}

body#home .light-bg .container .aaaem-container {
  height: 100%;
  margin: var(--emu-common-spacing-none);
  position: relative;
}

@media (min-width: 1024px) {
  body#home .light-bg .container .aaaem-container .aem-Grid {
    flex-direction: column;
  }
}

body#home .light-bg .container .cmp-image img {
  display: inline;
}

@media (min-width: 1024px) {
  body#home .light-bg .container .cmp-image {
    min-height: 125px;
  }
}

body#home .light-bg .container .cmp-image svg {
  width: 175px;
  height: 117px;
}

body#home .light-bg .container .text .cmp-text {
  width: 87%;
  margin: auto;
}

body#home .light-bg .container .text .cmp-text h2 {
  font: var(--emu-semantic-typography-narrow-headings-headline-h4);
}

body#home .light-bg .container .text .cmp-text p {
  font: var(--emu-semantic-typography-narrow-body-intro-small);
  padding-bottom: var(--emu-common-spacing-small);
}

@media (min-width: 1024px) {
  body#home .light-bg .container .text .cmp-text p {
    padding-bottom: 20px;
  }

  body#home .light-bg .container .text .cmp-text {
    width: 100%;
    margin: var(--emu-common-spacing-none);
  }

  body#home .light-bg .container {
    max-width: 350px;
    margin-bottom: var(--emu-common-spacing-none);
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }
}

body#home .light-bg .button {
  justify-content: center;
  margin-top: auto;
  margin-bottom: 5px;
  display: flex;
}

body#home .light-bg .button a {
  color: var(--emu-semantic-colors-primary-white-solid);
  width: auto;
  min-width: 255px;
  transition: all .3s ease-out;
}

body#home .light-bg .button a:hover, body#home .light-bg .button a:focus {
  color: var(--emu-semantic-colors-primary-teal-300);
}

body#home .light-bg .button a:active {
  color: var(--emu-common-colors-white);
  outline: none;
}

@media (min-width: 1024px) {
  body#home .light-bg .button a {
    min-width: 270px;
  }
}

body#home .fap-section {
  background-color: var(--emu-semantic-colors-primary-bone);
  margin-bottom: 100px;
  padding: 41px 25px 60px;
}

@media (min-width: 1024px) {
  body#home .fap-section {
    margin-bottom: 136px;
    padding-top: 96px;
    padding-bottom: 96px;
  }
}

body#home .fap-section .button {
  justify-content: center;
}

body#home .fap-section__title {
  padding-bottom: 35px;
}

@media (min-width: 1024px) {
  body#home .fap-section__title {
    padding-bottom: 40px;
  }
}

body#home .fap-section__title h1 {
  margin: var(--emu-common-spacing-none);
}

body#home .fap-section__title h1 sup {
  line-height: 0;
  font-size: var(--emu-semantic-font-sizes-narrow-small);
  position: relative;
}

@media (min-width: 1024px) {
  body#home .fap-section__title h1 sup {
    font-size: 32px;
  }
}

body#home .fap-section__text {
  padding-bottom: 10px;
}

body#home .fap-section__text p {
  margin: var(--emu-common-spacing-none);
  font-size: var(--emu-semantic-font-sizes-narrow-small);
  line-height: var(--emu-semantic-line-heights-narrow-medium);
}

@media (min-width: 1024px) {
  body#home .fap-section__text p {
    font-size: var(--emu-semantic-font-sizes-narrow-large);
    line-height: var(--emu-semantic-line-heights-narrow-large);
    margin-bottom: 10px;
  }
}

body#home .fap-section__cta {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

@media (min-width: 1024px) {
  body#home .fap-section__cta {
    min-width: 298px;
    justify-content: center;
  }
}

body#home .fap-section__cta span span {
  font-size: var(--emu-semantic-font-sizes-narrow-xs);
  line-height: var(--emu-semantic-line-heights-narrow-small);
}

@media (min-width: 1024px) {
  body#home .fap-section__cta span span {
    font-size: var(--emu-semantic-font-sizes-narrow-large);
  }
}

#sitemap .sitemap-text {
  text-align: center;
  padding-top: 90px;
}

@media (min-width: 1024px) {
  #sitemap .sitemap-text {
    text-align: left;
    margin-left: 95px;
    padding-top: 150px;
  }
}

#sitemap .sitemap-text h1 {
  font: var(--emu-semantic-typography-narrow-headings-eyebrow);
  margin-bottom: 45px;
  display: inline-block;
}

@media (min-width: 1024px) {
  #sitemap .sitemap-text h1 {
    font: var(--emu-semantic-typography-wide-headings-eyebrow);
  }
}

#sitemap .sitemap-text h1:after {
  content: "";
  width: 100%;
  background: var(--emu-semantic-colors-primary-teal-300);
  height: var(--emu-semantic-border-width-thick);
  border-radius: var(--emu-semantic-border-radius-round);
  display: flex;
  position: relative;
}

#sitemap .sitemap-text ul {
  margin-left: 30px;
}

@media (min-width: 1024px) {
  #sitemap .sitemap-text ul {
    margin-left: var(--emu-common-spacing-none);
    padding-left: 23px;
  }
}

#sitemap .sitemap-text li {
  text-align: left;
}

#sitemap .sitemap-text a, #sitemap .sitemap-text a:hover, #sitemap .sitemap-text a:active, #sitemap .sitemap-text a:focus {
  color: var(--emu-semantic-colors-primary-teal-500);
}

#error-handler .error__wrapper {
  padding-top: 90px;
  padding-left: 26px;
  padding-right: 26px;
}

@media (min-width: 1024px) {
  #error-handler .error__wrapper {
    padding-top: 150px;
    padding-left: 95px;
  }
}

#error-handler .error__wrapper h1 {
  font: var(--emu-semantic-typography-narrow-headings-eyebrow);
  margin-bottom: 45px;
  display: inline-block;
}

@media (min-width: 1024px) {
  #error-handler .error__wrapper h1 {
    font: var(--emu-semantic-typography-wide-headings-eyebrow);
  }
}

#error-handler .error__wrapper h1:after {
  content: "";
  width: 100%;
  background: var(--emu-semantic-colors-primary-teal-300);
  height: var(--emu-semantic-border-width-thick);
  border-radius: var(--emu-semantic-border-radius-round);
  display: flex;
  position: relative;
}

#error-handler .error__wrapper a {
  width: auto;
  display: inline-block;
}

#error-handler .error__text--secondary a, #error-handler .error__text--secondary a:hover, #error-handler .error__text--secondary a:active, #error-handler .error__text--secondary a:focus {
  color: var(--emu-semantic-colors-primary-teal-500);
  text-decoration: none;
}

body#faq .teaser {
  width: 100%;
}

body#faq .teaser .disclaimer {
  font: var(--emu-semantic-typography-wide-body-footnotes);
  margin-bottom: 26px;
}

body#faq .teaser .cmp-teaser__description {
  width: 90%;
}

@media (min-width: 768px) {
  body#faq .teaser .cmp-teaser__description {
    width: 50%;
  }
}

@media (min-width: 1201px) {
  body#faq .teaser .cmp-teaser__description {
    width: 35%;
  }
}

body#faq .teaser .cmp-teaser__description p {
  margin: var(--emu-common-spacing-none);
}

body#faq .teaser .cmp-teaser__description p b {
  font-family: var(--emu-semantic-font-families-light);
  font-weight: var(--emu-common-font-weight-light);
}

body#faq .teaser .cmp-teaser__title {
  margin-bottom: 36px;
}

@media (min-width: 1024px) {
  body#faq .teaser .cmp-teaser__title {
    margin-bottom: var(--emu-common-spacing-medium);
  }
}

body#faq .teaser .cmp-teaser__subtitle {
  display: none;
}

@media (min-width: 1024px) {
  body#faq .teaser .cmp-teaser__subtitle {
    font-size: 14px;
    line-height: var(--emu-semantic-line-heights-wide-small);
    margin: var(--emu-common-spacing-none);
    width: 380px;
    height: 75px;
    text-indent: -7px;
    display: block;
    position: absolute;
    bottom: 75px;
  }
}

body#faq .teaser .cmp-teaser .content-container {
  padding-bottom: var(--emu-common-spacing-none);
}

@media (min-width: 1201px) {
  body#faq .teaser .cmp-teaser .content-container {
    padding-bottom: var(--emu-common-spacing-medium);
    max-width: var(--emu-component-containers-container-max-width);
    margin: auto;
    padding-left: 70px;
  }
}

body#faq .faq-page__sub-text {
  padding-left: 22px;
  padding-right: 22px;
}

body#faq .faq-page__sub-text p {
  font-size: var(--emu-common-font-sizes-narrow-medium);
  line-height: var(--emu-semantic-line-heights-narrow-xxs);
  margin-top: 43px;
  margin-bottom: 30px;
}

@media (min-width: 1024px) {
  body#faq .faq-page__sub-text {
    display: none;
  }
}

#savings .teaser {
  width: 100%;
  overflow: hidden;
}

#savings .teaser .content-container {
  padding-bottom: var(--emu-common-spacing-none);
  width: 100%;
  padding-left: 26px;
  padding-right: 26px;
}

@media (min-width: 1201px) {
  #savings .teaser .content-container {
    max-width: var(--emu-component-containers-container-max-width);
    justify-content: center;
    align-items: center;
    margin: 60px auto auto;
    padding-left: 95px;
  }
}

#savings .teaser .content-container .aaaem-teaser__title {
  margin-bottom: var(--emu-common-spacing-none);
}

#savings .teaser .disclaimer {
  margin-top: var(--emu-common-spacing-small);
  margin-bottom: 45px;
}

@media (min-width: 1201px) {
  #savings .teaser .disclaimer {
    display: none;
  }
}

#savings .teaser .disclaimer p {
  font-size: var(--emu-semantic-font-sizes-narrow-xxs);
  line-height: var(--emu-semantic-line-heights-narrow-xxs);
  font-family: var(--emu-semantic-font-families-light);
  letter-spacing: .165px;
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-none);
}

#savings .teaser .cmp-teaser__title {
  margin-top: var(--emu-common-spacing-none);
}

#savings .teaser .cmp-teaser__description {
  width: 100%;
}

@media (min-width: 768px) {
  #savings .teaser .cmp-teaser__description {
    width: 50%;
  }
}

@media (min-width: 1201px) {
  #savings .teaser .cmp-teaser__description {
    width: 40%;
  }
}

#savings .teaser .cmp-teaser__description p {
  margin-top: 37px;
  margin-bottom: 72px;
}

#savings .teaser .cmp-teaser__description sup {
  position: relative;
  top: 3px;
}

@media (min-width: 1024px) {
  #savings .teaser .cmp-teaser__description sup {
    top: 1px;
  }
}

#savings .teaser .cmp-teaser__description sup:nth-child(2) {
  font-size: 24px;
  top: 3px;
}

@media (min-width: 1024px) {
  #savings .teaser .cmp-teaser__description sup:nth-child(2) {
    top: -4px;
  }
}

#savings .teaser .teaser-image-container {
  background-color: #dcd5cf;
  margin-top: 10px;
}

@media (min-width: 1024px) {
  #savings .disclaimer--text--desktop p {
    margin-bottom: 83px;
  }
}

#savings .body-intro {
  justify-content: center;
  display: flex;
}

@media (min-width: 1024px) {
  #savings .body-intro {
    max-width: var(--emu-component-containers-container-max-width);
    float: unset;
    margin: auto;
    padding-top: 58px;
  }
}

#savings .body-intro__join-alle-container > .button {
  max-width: 143px;
}

@media (min-width: 1024px) {
  #savings .body-intro__join-alle-container > .button {
    margin-bottom: 15px;
  }
}

#savings .body-intro__join-alle-container .aaaem-text p {
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: 20px;
  line-height: 32px;
}

@media (min-width: 1201px) {
  #savings .body-intro__join-alle-container .aaaem-text p {
    margin-bottom: 10px;
    font-size: 25px;
    line-height: 35px;
  }
}

#savings .body-intro__join-alle-container .aaaem-text a {
  font-family: var(--emu-semantic-font-families-heading);
  color: var(--emu-semantic-colors-primary-teal-400);
}

#savings .body-intro__text-container {
  margin: var(--emu-common-spacing-none);
  width: 100%;
  justify-content: center;
  margin-bottom: 80px;
  padding-left: 48px;
  padding-right: 48px;
  display: flex;
}

#savings .body-intro__text-container > .aaaem-container {
  max-width: 1255px;
}

#savings .body-intro__text-container .btn--alle {
  min-width: 143px;
  width: 100%;
  font-family: var(--emu-semantic-font-families-body);
  font-size: var(--emu-semantic-font-sizes-narrow-small);
  justify-content: center;
  margin-bottom: 10px;
  padding-top: 9px;
  padding-bottom: 9px;
  line-height: 1;
}

@media (min-width: 1201px) {
  #savings .body-intro__text-container .btn--alle {
    min-width: 181px;
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 25px;
    font-weight: 500;
  }

  #savings .body-intro__text-container .body-intro-text {
    margin-top: var(--emu-common-spacing-none);
  }
}

#savings .body-intro__text-container .body-intro-text p {
  font-size: var(--emu-semantic-font-sizes-narrow-large);
  line-height: var(--emu-semantic-line-heights-narrow-large);
  margin: var(--emu-common-spacing-none);
  color: var(--emu-semantic-colors-primary-teal-500);
  margin-top: 25px;
  margin-bottom: 58px;
}

@media (min-width: 1024px) {
  #savings .body-intro__text-container .body-intro-text p {
    font-size: 35px;
    line-height: var(--emu-semantic-line-heights-wide-medium);
    margin-bottom: 27px;
    padding-right: 90px;
    font-weight: 100;
  }
}

#savings .body-intro__text-container .body-intro-text ul {
  grid-gap: 52px;
  padding-left: var(--emu-common-spacing-none);
  margin: var(--emu-common-spacing-none);
  flex-direction: column;
  gap: 52px;
  list-style: none;
  display: flex;
}

@media (min-width: 1024px) {
  #savings .body-intro__text-container .body-intro-text ul {
    grid-gap: 28px;
    gap: 28px;
  }
}

#savings .body-intro__text-container .body-intro-text ul li {
  font: var(--emu-semantic-typography-narrow-headings-headline-h4-teal);
  color: var(--emu-semantic-colors-primary-teal-500);
}

@media (min-width: 1024px) {
  #savings .body-intro__text-container .body-intro-text ul li {
    font: var(--emu-semantic-typography-wide-headings-headline-h4-teal);
  }
}

#savings .body-intro__text-container .body-intro-text ul li p {
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-none);
  font: var(--emu-semantic-typography-narrow-body-regular);
  color: var(--emu-common-colors-black);
  letter-spacing: .25px;
  padding-top: 11px;
  padding-right: var(--emu-common-spacing-none);
}

@media (min-width: 1024px) {
  #savings .body-intro__text-container .body-intro-text ul li p {
    font: var(--emu-semantic-typography-wide-body-regular);
  }
}

#savings .body-provider {
  margin-bottom: 95px;
  padding-left: 25px;
  padding-right: 25px;
}

@media (min-width: 1024px) {
  #savings .body-provider {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    margin-bottom: 152px;
  }
}

#savings .body-provider .aem-Grid {
  background: var(--emu-semantic-colors-primary-bone);
  border-radius: 20px;
  padding: 35px 13px 44px;
}

@media (min-width: 1024px) {
  #savings .body-provider .aem-Grid {
    border-radius: var(--emu-semantic-border-radius-none);
    padding-top: 46px;
    padding-bottom: 69px;
  }
}

#savings .body-provider .aem-Grid h3 {
  font-size: var(--emu-semantic-font-sizes-narrow-xxl);
  line-height: var(--emu-semantic-line-heights-narrow-xxl);
  margin: var(--emu-common-spacing-none);
}

@media (min-width: 1024px) {
  #savings .body-provider .aem-Grid h3 {
    font-size: var(--emu-semantic-font-sizes-wide-xl);
    line-height: var(--emu-semantic-line-heights-wide-xxl);
  }
}

#savings .body-provider .aem-Grid p {
  font-size: var(--emu-semantic-font-sizes-narrow-small);
  line-height: var(--emu-semantic-line-heights-narrow-medium);
  max-width: 702px;
  margin: 13px auto 28px;
}

@media (min-width: 1024px) {
  #savings .body-provider .aem-Grid p {
    margin-bottom: 50px;
    font-size: 25px;
    line-height: 35px;
  }
}

#savings .body-provider .aem-Grid p sup {
  line-height: 0;
  position: relative;
  top: 4px;
}

@media (min-width: 1024px) {
  #savings .body-provider .aem-Grid p sup {
    top: 6px;
  }
}

#savings .body-provider .aem-Grid > .button svg {
  margin: var(--emu-common-spacing-none);
  transition: all .3s ease-out;
}

#savings .body-provider .aem-Grid > .button .aaaem-button__primary-filled {
  max-width: 155px;
}

@media (min-width: 1201px) {
  #savings .body-provider .aem-Grid > .button .aaaem-button__primary-filled {
    padding-top: 11px;
    padding-bottom: 11px;
  }
}

#savings .body-provider .aem-Grid > .button .aaaem-button__primary-filled:hover svg, #savings .body-provider .aem-Grid > .button .aaaem-button__primary-filled:focus svg {
  transform: translateX(5%);
}

#savings .body-provider .aem-Grid > .button .aaaem-button__primary-filled:hover svg g, #savings .body-provider .aem-Grid > .button .aaaem-button__primary-filled:focus svg g {
  stroke: var(--emu-semantic-colors-primary-teal-400);
}

body#science .teaser {
  overflow: hidden;
}

body#science .teaser .teaser-container {
  justify-content: flex-end;
}

@media (min-width: 1024px) {
  body#science .teaser .teaser-container {
    justify-content: center;
  }
}

body#science .teaser .teaser-container img {
  width: 100%;
  -o-object-position: 15%;
  object-position: 15%;
}

body#science .teaser .teaser-container .content-container {
  padding: var(--emu-common-spacing-none);
  margin-left: var(--emu-common-spacing-none);
}

@media (min-width: 1024px) {
  body#science .teaser .teaser-container .content-container {
    max-width: var(--emu-component-containers-container-max-width);
    align-content: flex-end;
    display: flex;
  }
}

@media (min-width: 1201px) {
  body#science .teaser .teaser-container .content-container {
    margin-right: 63px;
  }
}

@media (min-width: 1024px) {
  body#science .teaser .teaser-container .content-container .content-middle-right {
    width: 500px;
    padding-bottom: 60px;
  }

  body#science .teaser .teaser-container .content-container .content-middle-right .cmp-teaser__description {
    margin-top: 20px;
  }

  body#science .teaser .teaser-container .content-container .content-middle-right .cmp-teaser__description p {
    font-size: var(--emu-semantic-font-sizes-wide-large);
  }
}

body#science .teaser .teaser-container .content-container .disclaimer {
  font: var(--emu-semantic-typography-narrow-body-footnotes);
  align-self: flex-start;
  padding-left: 22px;
}

@media (min-width: 1024px) {
  body#science .teaser .teaser-container .content-container .disclaimer {
    font: var(--emu-semantic-typography-wide-body-footnotes);
    padding-bottom: var(--emu-common-spacing-medium);
    align-self: flex-end;
  }
}

body#science .teaser .teaser-container .content-container .disclaimer p {
  margin-bottom: var(--emu-common-spacing-none);
}

body#science .teaser .cmp-teaser__title {
  margin-top: var(--emu-common-spacing-none);
}

body#science .teaser .cmp-teaser__description p sup {
  top: 1px;
}

body#science .body-intro {
  justify-content: center;
  display: flex;
}

@media (min-width: 1024px) {
  body#science .body-intro {
    margin-top: var(--emu-common-spacing-large);
    margin-bottom: 40px;
  }
}

body#science .body-intro .text {
  max-width: 936px;
}

@media (min-width: 1024px) {
  body#science .body-intro .text {
    width: 64%;
  }
}

body#science .body-intro .text p {
  font: var(--emu-semantic-typography-narrow-body-intro-large);
  margin-top: 47px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  body#science .body-intro .text p {
    font: var(--emu-semantic-typography-wide-body-intro-large);
  }
}

body#science .body-intro .text p sup {
  line-height: initial;
  line-height: 0;
  position: relative;
  top: 5px;
}

@media (min-width: 1024px) {
  body#science .body-intro .text p sup {
    top: 8px;
  }
}

body#science .body-intro .text p:nth-of-type(1) {
  width: 93%;
}

@media (min-width: 1024px) {
  body#science .body-intro .text p:nth-of-type(1) {
    width: 100%;
  }
}

body#science .body-intro .text p:nth-of-type(2) {
  width: 87%;
  font-size: var(--emu-semantic-font-sizes-narrow-xs);
  line-height: var(--emu-semantic-line-heights-narrow-small);
  margin-top: 36px;
}

@media (min-width: 1024px) {
  body#science .body-intro .text p:nth-of-type(2) {
    width: 100%;
    font-size: var(--emu-semantic-font-sizes-narrow-large);
    line-height: var(--emu-semantic-line-heights-narrow-large);
  }
}

body#science .body-intro .text p:nth-of-type(2) sup {
  line-height: initial;
  line-height: 0;
  position: relative;
  top: 4px;
}

@media (min-width: 1024px) {
  body#science .body-intro .text p:nth-of-type(2) sup {
    top: 6px;
  }
}

body#science .science--glow-report--text {
  width: 85%;
  margin-top: 27px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  body#science .science--glow-report--text {
    width: 60%;
  }
}

@media (min-width: 1201px) {
  body#science .science--glow-report--text {
    width: 45%;
  }
}

body#science .science--glow-report--text p {
  font-size: var(--emu-semantic-font-sizes-narrow-xxs);
  line-height: var(--emu-semantic-line-heights-narrow-xxs);
}

@media (min-width: 1024px) {
  body#science .science--glow-report--text p {
    font-size: var(--emu-semantic-font-sizes-wide-xs);
    line-height: var(--emu-semantic-line-heights-wide-small);
  }
}

body#science .body__content-container {
  margin-top: var(--emu-common-spacing-xl);
  justify-content: center;
  display: flex;
}

@media (min-width: 1024px) {
  body#science .body__content-container {
    margin-top: 90px;
  }

  body#science .body__content-container .teaser {
    width: 44%;
  }
}

body#science .body__content-container .teaser .has-media {
  height: 100%;
}

body#science .body__content-container .science--body--teaser .teaser-container img {
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
}

@media (min-width: 1024px) {
  body#science .body__content-container .science--body--teaser .teaser-container img {
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
  }

  body#science .body__content-container .science--body--teaser .teaser-container .content-container {
    margin-right: var(--emu-common-spacing-none);
  }
}

body#science .body__content-container .science--body--teaser .teaser-container .disclaimer {
  visibility: visible;
  color: var(--emu-common-colors-white);
  margin-bottom: var(--emu-common-spacing-none);
  padding-left: var(--emu-common-spacing-none);
  align-self: center;
  position: absolute;
  bottom: 13px;
}

@media (min-width: 1024px) {
  body#science .body__content-container .science--body--teaser .teaser-container .disclaimer {
    bottom: -5px;
  }
}

body#science .body__content-container .cmp-container {
  max-width: var(--emu-component-containers-container-max-width);
  flex-direction: column;
  display: flex;
}

@media (min-width: 1024px) {
  body#science .body__content-container .cmp-container {
    width: 100%;
    flex-flow: row;
  }
}

@media (min-width: 1441px) {
  body#science .body__content-container .cmp-container {
    justify-content: center;
  }
}

body#science .body__content-container .cmp-container .body__content-container__text-container {
  justify-content: center;
  display: flex;
}

@media (min-width: 1024px) {
  body#science .body__content-container .cmp-container .body__content-container__text-container {
    padding: var(--emu-common-spacing-none);
  }
}

body#science .body__content-container .cmp-container .body__content-container__text-container .aaaem-container {
  grid-gap: 21px;
  width: 89%;
  flex-direction: column;
  gap: 21px;
  margin-top: 53px;
}

@media (min-width: 1024px) {
  body#science .body__content-container .cmp-container .body__content-container__text-container .aaaem-container {
    margin-top: var(--emu-common-spacing-none);
    width: auto;
    grid-gap: 26px;
    gap: 26px;
    padding-left: 26px;
  }
}

body#science .body__content-container .cmp-container .body__content-container__text-container .aaaem-container .cmp-text {
  background-color: var(--emu-semantic-colors-primary-bone);
  padding: 44px 25px;
}

@media (min-width: 1024px) {
  body#science .body__content-container .cmp-container .body__content-container__text-container .aaaem-container .cmp-text {
    max-width: 787px;
    padding: 52px 92px 58px 82px;
  }
}

body#science .body__content-container .cmp-container .body__content-container__text-container .aaaem-container .cmp-text h3 {
  margin-top: var(--emu-common-spacing-none);
  color: var(--emu-semantic-colors-primary-teal-400);
  font: var(--emu-semantic-typography-narrow-headings-headline-h4-teal);
  margin-bottom: 12px;
}

@media (min-width: 1024px) {
  body#science .body__content-container .cmp-container .body__content-container__text-container .aaaem-container .cmp-text h3 {
    font: var(--emu-semantic-typography-wide-headings-headline-h4-teal);
    margin-bottom: 11px;
  }
}

body#science .body__content-container .cmp-container .body__content-container__text-container .aaaem-container .cmp-text p {
  font: var(--emu-semantic-typography-narrow-body-regular);
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-none);
}

@media (min-width: 1024px) {
  body#science .body__content-container .cmp-container .body__content-container__text-container .aaaem-container .cmp-text p {
    font: var(--emu-semantic-typography-wide-body-regular);
    margin-bottom: var(--emu-common-spacing-none);
  }
}

body#science .body-cta-container {
  justify-content: center;
  margin-top: 61px;
  margin-bottom: 101px;
  padding-bottom: 10px;
  display: flex;
}

@media (min-width: 1024px) {
  body#science .body-cta-container {
    margin-top: 132px;
    margin-bottom: 136px;
  }
}

body#science .body-cta-container .cmp-container {
  width: 85%;
}

@media (min-width: 1024px) {
  body#science .body-cta-container .cmp-container {
    width: 55%;
  }
}

body#science .body-cta-container .cmp-container p {
  font: var(--emu-semantic-typography-narrow-headings-headline-h2);
  margin-top: var(--emu-common-spacing-none);
  padding-left: 10px;
  padding-right: 10px;
}

@media (min-width: 1024px) {
  body#science .body-cta-container .cmp-container p {
    font: var(--emu-semantic-typography-wide-headings-headline-h1);
    margin-bottom: 47px;
  }
}

body#science .body-cta-container .button {
  justify-content: center;
}

body#science .body-cta-container .button a {
  width: auto;
  min-width: 130px;
}

body#science .body-cta-container .button a > span {
  display: none;
}

body#science .body-cta-container .button a svg {
  width: 100%;
}

@media (min-width: 1024px) {
  body#science .body-cta-container .button a {
    min-width: 157px;
    padding-top: 11px;
    padding-bottom: 11px;
  }
}

body#science .body-cta-container .button svg {
  margin: var(--emu-common-spacing-none);
  transition: all .3s ease-out;
}

body#science .body-cta-container .button .aaaem-button:hover svg, body#science .body-cta-container .button .aaaem-button:focus svg {
  transform: translateX(5%);
}

body#science .body-cta-container .button .aaaem-button:hover svg g, body#science .body-cta-container .button .aaaem-button:focus svg g {
  stroke: var(--emu-semantic-colors-primary-teal-400);
}

body#science .body-cta-container .button .aaaem-button:active svg g {
  stroke: var(--emu-semantic-colors-primary-teal-100);
}

.disclaimer--text--desktop {
  display: none;
}

@media (min-width: 1024px) {
  .disclaimer--text--desktop {
    padding-top: 10px;
    padding-left: 95px;
    display: block;
  }

  .disclaimer--text--desktop p {
    margin: var(--emu-common-spacing-none);
    font-size: var(--emu-semantic-font-sizes-wide-xs);
    line-height: var(--emu-semantic-line-heights-wide-small);
  }
}

.light-bg {
  background-color: var(--emu-semantic-colors-primary-bone);
  padding-top: 32px;
}

.light-bg .aaaem-container {
  background-color: inherit;
}

.light-bg .aem-Grid {
  max-width: var(--emu-component-containers-container-max-width);
  margin: auto;
}

.aaaem-accordion {
  width: 87%;
  margin: auto;
}

.aaaem-accordion:after {
  content: "";
  border-bottom: var(--emu-common-border-width-thin) solid var(--emu-component-lists-accordion-item-color-border-bottom-closed-light);
  display: block;
}

@media (min-width: 1201px) {
  .aaaem-accordion {
    width: 100%;
    max-width: var(--emu-component-containers-container-max-width);
    padding-top: var(--emu-common-spacing-none);
    padding-right: 96px;
    padding-bottom: var(--emu-common-spacing-none);
    padding-left: 96px;
  }
}

.aaaem-accordion__header {
  border: none;
  border-top: var(--emu-common-border-width-thin) solid var(--emu-component-lists-accordion-item-color-border-bottom-closed-light);
  transition: all .2s ease-in-out;
}

.aaaem-accordion__item:first-child .aaaem-accordion__header {
  border: none;
}

.aaaem-accordion__item .-ba-is-active {
  padding-bottom: 5px;
}

.aaaem-accordion__title {
  font: var(--emu-semantic-typography-narrow-headings-q-a-headline);
  color: var(--emu-semantic-colors-primary-teal-300);
}

@media (min-width: 1201px) {
  .aaaem-accordion__title {
    font: var(--emu-semantic-typography-wide-headings-q-a-headline);
  }
}

.aaaem-accordion dt .text {
  font: var(--emu-semantic-typography-narrow-body-regular);
}

@media (min-width: 1201px) {
  .aaaem-accordion dt .text {
    font: var(--emu-semantic-typography-wide-body-regular);
  }
}

.aaaem-accordion__panel {
  padding-left: var(--emu-common-spacing-large);
  border: none;
}

@media (min-width: 1024px) {
  .aaaem-accordion__panel {
    padding-left: 54px;
  }
}

.aaaem-accordion__panel .aaaem-text p {
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-none);
  padding-bottom: 30px;
}

.aaaem-accordion__icon {
  padding-top: var(--emu-common-spacing-xs);
  align-self: flex-start;
}

.accordion.panelcontainer {
  width: 100%;
  counter-reset: css-counter 0;
  flex-direction: column;
  align-items: center;
  margin-bottom: 96px;
  list-style-type: none;
  display: flex;
}

@media (min-width: 1024px) {
  .accordion.panelcontainer {
    margin-top: 60px;
    margin-bottom: 148px;
  }
}

.accordion.panelcontainer dt {
  counter-increment: css-counter 1;
}

.accordion.panelcontainer dt button:before {
  content: counter(css-counter);
  margin-right: 12px;
  margin-top: var(--emu-common-spacing-small);
  font-family: var(--emu-semantic-font-families-light);
  font-size: 14px;
  line-height: 1;
  display: inline-block;
}

@media (min-width: 1024px) {
  .accordion.panelcontainer dt button:before {
    margin-right: var(--emu-common-spacing-large);
    margin-top: 6px;
    font-size: 20px;
  }
}

.accordion.panelcontainer dt:nth-child(-n+18) button:before {
  content: "0" counter(css-counter);
}

.aaaem-back-to-top-button {
  background-color: var(--emu-common-colors-transparent);
  width: auto;
  border: none;
}

.aaaem-back-to-top-button:focus {
  outline: none;
}

.aaaem-back-to-top-button svg {
  margin: var(--emu-common-spacing-none);
  fill: var(--emu-semantic-colors-primary-teal-300);
}

.aaaem-back-to-top-button span {
  display: none;
}

.aaaem-back-to-top-button.js-absolute {
  position: absolute;
  bottom: 22px;
}

@media (min-width: 1201px) {
  .aaaem-back-to-top-button.js-absolute {
    bottom: 49px;
  }
}

.button:not(.backToTop) {
  display: flex;
}

.button:not(.backToTop) a {
  text-align: center;
  width: 100%;
  text-decoration: none;
}

.button:not(.backToTop) a span {
  margin: auto;
}

.button:not(.backToTop) button {
  display: flex;
}

.slider-carousel {
  position: relative;
  overflow: hidden;
}

.slider-carousel > div {
  margin-bottom: 105px;
}

@media (min-width: 1024px) {
  .slider-carousel > div {
    margin-bottom: 175px;
  }
}

.slider-carousel__main {
  margin: var(--emu-common-spacing-none);
}

.slider-carousel .aaaem-carousel__content {
  margin-left: auto;
  margin-right: auto;
}

.slider-carousel:before, .slider-carousel:after {
  content: "";
  z-index: var(--emu-common-other-z-index-layer);
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.slider-carousel:before {
  background-color: var(--emu-common-colors-white);
  width: 100%;
  height: 100%;
}

.slider-carousel:after {
  width: 60px;
  height: 60px;
  background-image: url("resources/images/loading.gif");
  background-size: cover;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.slider-carousel.loading:after, .slider-carousel.loading:before {
  display: block;
}

.slider-carousel .slider-carousel__comp-slider {
  margin-bottom: var(--emu-common-spacing-small);
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: none;
}

.slider-carousel .slider-carousel__comp-slider img {
  width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.slider-carousel .slider-carousel__comp-slider--zoom-out {
  width: 500px;
}

.slider-carousel .slider-carousel__comp-slider--zoom-out-small-active {
  width: 385px;
  max-height: 249.115px;
  margin-bottom: 15px;
}

.slider-carousel .slider-carousel__comp-slider--zoom-in {
  width: 500px;
}

.slider-carousel .slider-carousel__comp-slider--active {
  display: flex;
}

.slider-carousel__ba[data-component="text"] {
  pointer-events: none;
  display: none;
}

@media (min-width: 1024px) {
  .slider-carousel__ba[data-component="text"].slider-carousel__ba--main {
    width: 88.4%;
    display: block;
    position: absolute;
    top: 48%;
    left: 5.8%;
  }
}

.slider-carousel__ba[data-component="text"].slider-carousel__ba--in-item {
  margin-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;
  display: block;
}

@media (min-width: 1024px) {
  .slider-carousel__ba[data-component="text"].slider-carousel__ba--in-item {
    padding: var(--emu-common-spacing-none);
    margin-bottom: var(--emu-common-spacing-none);
  }
}

.slider-carousel__ba[data-component="text"] ul {
  margin: var(--emu-common-spacing-none);
  padding: var(--emu-common-spacing-none);
  pointer-events: none;
  width: 100%;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  list-style: none;
  display: flex;
}

@media (min-width: 1024px) {
  .slider-carousel__ba[data-component="text"] ul {
    position: absolute;
    top: 45%;
  }
}

.slider-carousel__ba[data-component="text"] ul li {
  margin: var(--emu-semantic-spacing-none);
  font-size: 14px;
  line-height: var(--emu-semantic-line-heights-narrow-medium);
  color: var(--emu-semantic-colors-primary-teal-400);
  font-family: var(--emu-semantic-font-families-medium);
}

@media (min-width: 1024px) {
  .slider-carousel__ba[data-component="text"] ul li {
    color: var(--emu-common-colors-black);
  }
}

.slider-carousel__item-inner {
  position: relative;
}

@media (min-width: 1024px) {
  .slider-carousel__item-inner {
    margin-top: 15px;
  }
}

.slider-carousel__item-desc[data-component="text"] {
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}

@media (min-width: 1024px) {
  .slider-carousel__item-desc[data-component="text"] {
    padding: var(--emu-common-spacing-none);
    max-width: 160px;
    position: absolute;
    top: 15%;
    right: 0;
  }
}

.slider-carousel__item-desc[data-component="text"] h3 {
  margin-top: var(--emu-semantic-spacing-none);
  margin-bottom: var(--emu-common-spacing-none);
  color: var(--emu-semantic-colors-primary-teal-500);
  font-family: var(--emu-semantic-font-families-body);
  font-size: var(--emu-semantic-font-sizes-narrow-small);
  line-height: var(--emu-semantic-line-heights-narrow-medium);
  text-align: center;
  font-weight: var(--emu-common-font-weight-black);
}

@media (min-width: 1024px) {
  .slider-carousel__item-desc[data-component="text"] h3 {
    text-align: end;
  }
}

.slider-carousel__item-desc[data-component="text"] p {
  margin-top: var(--emu-semantic-spacing-none);
  font-size: 14px;
  line-height: var(--emu-common-line-heights-narrow-large);
  text-align: center;
  margin-bottom: 25px;
}

@media (min-width: 1024px) {
  .slider-carousel__item-desc[data-component="text"] p {
    text-align: end;
    font-size: 16px;
  }
}

.slider-carousel__item-desc[data-component="text"] sup {
  line-height: 0;
  font-size: inherit;
  position: relative;
  top: 5px;
}

@media (min-width: 1024px) {
  .slider-carousel__item-desc[data-component="text"] sup {
    top: 6px;
  }
}

.slider-carousel__item-disclaimer[data-component="text"] {
  max-width: 620px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
}

.slider-carousel__item-disclaimer[data-component="text"] p {
  max-width: 90%;
  margin-bottom: 18px;
  margin-top: var(--emu-common-spacing-small);
  font-weight: var(--emu-semantic-font-weight-500);
  line-height: 16px;
  font-size: var(--emu-common-font-sizes-narrow-medium);
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  .slider-carousel__item-disclaimer[data-component="text"] p {
    font-size: var(--emu-semantic-font-sizes-wide-xs);
    margin-bottom: var(--emu-common-spacing-large);
    line-height: var(--emu-semantic-line-heights-wide-small);
    max-width: none;
  }
}

.slider-carousel__item-disclaimer[data-component="text"] sup {
  line-height: 0;
  font-size: inherit;
  position: relative;
  top: 5px;
}

.slider-carousel__item-slider-container[data-component="container"].aaaem-container {
  padding-left: 10px;
  padding-right: 10px;
}

@media (min-width: 1024px) {
  .slider-carousel__item-slider-container[data-component="container"].aaaem-container {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

.slider-carousel__control[data-component="button"] {
  width: 34px;
  height: 35px;
  padding: var(--emu-common-spacing-none);
  -webkit-tap-highlight-color: var(--emu-common-colors-transparent);
}

.slider-carousel__control[data-component="button"], .slider-carousel__control[data-component="button"]:hover, .slider-carousel__control[data-component="button"]:active, .slider-carousel__control[data-component="button"]:focus {
  background-color: var(--emu-common-colors-transparent);
  border: none;
}

.slider-carousel__control .cmp-button__text {
  display: none;
}

.slider-carousel__control > span {
  width: inherit;
  height: inherit;
  background-image: url("resources/images/zoom-minus-green.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
}

.slider-carousel__control--zoom-in > span {
  background-image: url("resources/images/zoom-plus-green.png");
}

.slider-carousel__control--zoom-out-small [disabled] > span {
  background-color: var(--emu-semantic-colors-primary-teal-400);
}

.slider-carousel__control--zoom-out-small > span {
  width: 15px;
  height: 15px;
  background-image: none;
  background-color: var(--emu-semantic-colors-primary-teal-400);
  margin-top: var(--emu-common-spacing-xs);
  border-radius: 50%;
}

@media (min-width: 1024px) {
  .slider-carousel__control--zoom-out-small > span {
    margin-top: 5px;
  }
}

.slider-carousel__control-container {
  grid-gap: 5px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-bottom: 17px;
  margin-left: 5px;
  display: flex;
}

.slider-carousel__control-container > div {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  justify-content: center;
  display: flex;
}

@media (min-width: 1024px) {
  .slider-carousel__control-container {
    margin-bottom: 6px;
    margin-left: var(--emu-common-spacing-none);
  }
}

.slider-carousel__control-container .button:nth-child(2) {
  margin-left: 7px;
  margin-right: 7px;
}

.slider-carousel__thumb.has-no-data {
  padding-top: 10px;
}

.slider-carousel__thumb.has-no-data .aaaem-carousel__actions, .slider-carousel__thumb.has-no-data .aaaem-carousel__content {
  display: none;
}

.slider-carousel__thumb-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  .slider-carousel__thumb-container {
    width: 100%;
    margin-bottom: 58px;
    margin-bottom: var(--emu-common-spacing-none);
  }
}

.slider-carousel__thumb-container img {
  width: 72px;
  border: var(--emu-common-border-width-thin) solid var(--emu-common-colors-transparent);
  max-width: 72px;
  box-sizing: content-box;
  cursor: pointer;
}

.slider-carousel__thumb-container img.active {
  opacity: .4;
}

.slider-carousel__filter[data-component="button"] {
  padding: var(--emu-common-spacing-none);
  -webkit-tap-highlight-color: var(--emu-common-colors-transparent);
  position: relative;
}

.slider-carousel__filter[data-component="button"], .slider-carousel__filter[data-component="button"]:hover, .slider-carousel__filter[data-component="button"]:focus, .slider-carousel__filter[data-component="button"]:active {
  background-color: var(--emu-common-colors-transparent);
  border: none;
}

.slider-carousel__filter:before {
  content: "";
  width: var(--emu-common-sizing-medium);
  height: var(--emu-common-sizing-medium);
  background-color: var(--emu-semantic-colors-primary-red-100);
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}

.slider-carousel__filter:after {
  content: "";
  width: 1.65em;
  height: .2em;
  background-color: var(--emu-semantic-colors-primary-red-100);
  position: absolute;
  top: 14px;
  left: 32px;
}

.slider-carousel__filter .cmp-button__text {
  color: var(--emu-semantic-colors-secondary-dark-gray-800);
  font-family: var(--emu-semantic-font-families-suisse-intl-condensed-light);
  font-size: 14px;
  line-height: 2em;
  font-weight: var(--emu-common-font-weight-bold);
  padding-top: var(--emu-common-spacing-large);
  width: var(--emu-common-sizing-medium);
  display: block;
  position: relative;
}

.slider-carousel__filter .cmp-button__text:after {
  content: "";
  width: 9px;
  height: 18px;
  border: solid var(--emu-common-colors-white);
  border-top-width: var(--emu-common-border-width-none);
  border-right-width: var(--emu-common-border-width-thick);
  border-bottom-width: var(--emu-common-border-width-thick);
  border-left-width: var(--emu-common-border-width-none);
  display: none;
  position: absolute;
  top: 5px;
  left: 12px;
  transform: rotate(45deg);
}

.slider-carousel__filter--active:before {
  background-color: var(--emu-semantic-colors-primary-red-300);
}

.slider-carousel__filter--active .cmp-button__text:after {
  display: block;
}

.slider-carousel__filter-container {
  grid-gap: 5px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-top: 20px;
  display: flex;
}

@media (min-width: 1024px) {
  .slider-carousel__filter-container {
    padding-bottom: 25px;
  }
}

.slider-carousel__filter-container > .button {
  width: var(--emu-common-sizing-medium);
  margin-left: 11px;
  margin-right: 11px;
}

.slider-carousel__filter-container > .button:last-child button:after {
  display: none;
}

.cq-Editable-dom--container .slider-carousel__comp-slider, .cq-Editable-dom--container .slider-carousel__ba, .cq-Editable-dom--container .slider-carousel__item-desc {
  height: auto !important;
  max-height: none !important;
  display: block !important;
  position: static !important;
}

.slider-carousel--hide-items .slider-carousel__filter-container, .slider-carousel--hide-items .slider-carousel__thumb-container, .slider-carousel--hide-items .slider-carousel__control-container {
  display: none;
}

.comparisonslider .emu-comparison-slider__slider {
  margin: inherit;
}

.comparisonslider .emu-comparison-slider img-comparison-slider {
  width: 100%;
  --handle-position-start: 100%;
  margin: var(--emu-common-sizing-none);
}

.comparisonslider .emu-comparison-slider__handle {
  background: var(--emu-semantic-colors-primary-teal-400);
  padding-top: var(--emu-common-spacing-none);
  padding-bottom: var(--emu-common-spacing-none);
  height: 24px;
  padding-left: 5px;
  padding-right: 5px;
  position: relative;
  top: -12px;
  left: 0;
}

.comparisonslider .emu-comparison-slider__text {
  letter-spacing: .2px;
  color: var(--emu-common-colors-white);
  font-size: 14px;
}

.comparisonslider .emu-comparison-slider__arrow {
  border-top: 6px solid var(--emu-common-colors-transparent);
  border-bottom: 6px solid var(--emu-common-colors-transparent);
  font-family: var(--emu-semantic-font-families-light);
  width: var(--emu-common-sizing-none);
  height: var(--emu-common-sizing-none);
  transform: none;
}

.comparisonslider .emu-comparison-slider__arrow--left {
  border-left: none;
  border-right: 11px solid var(--emu-common-colors-white);
  margin-right: 10px;
}

.comparisonslider .emu-comparison-slider__arrow--right {
  border-left: 11px solid var(--emu-common-colors-white);
  border-right: none;
  margin-left: 10px;
}

.divider:after {
  border-left-color: var(--emu-common-colors-white);
  border-left-width: var(--emu-common-border-width-medium);
}

.container {
  overflow: hidden;
}

.container .aaaem-container {
  margin: var(--emu-common-spacing-none);
  padding: var(--emu-common-spacing-none);
  max-width: 100%;
}

.embed .cmp-embed {
  width: 90%;
  height: 100%;
  max-width: 605px;
  margin: auto;
}

.embed iframe {
  width: 100%;
  height: 100%;
  border-radius: var(--emu-semantic-border-radius-large);
}

@media (min-width: 768px) {
  .embed .cmp-embed {
    width: 70%;
  }
}

@media (min-width: 1201px) {
  .embed .cmp-embed {
    width: 100%;
  }
}

footer {
  background-color: var(--emu-semantic-colors-primary-teal-300);
  padding-top: var(--emu-common-spacing-medium);
  position: relative;
}

@media (min-width: 1024px) {
  footer {
    padding-top: 85px;
  }
}

footer .upper_section > div:nth-of-type(2) > div:first-child, footer .lower_section {
  width: 95%;
  max-width: var(--emu-component-containers-container-max-width);
  margin: auto;
}

@media (min-width: 1024px) {
  footer .upper_section > div:nth-of-type(2) > div:first-child, footer .lower_section {
    width: 100%;
    padding-top: var(--emu-common-spacing-none);
    padding-right: 70px;
    padding-bottom: var(--emu-common-spacing-none);
    padding-left: 70px;
    display: flex;
  }

  footer .upper_section > div:nth-of-type(2) > div:first-child > .container:nth-of-type(3) {
    flex: 20%;
  }
}

footer .upper_section p {
  color: var(--emu-common-colors-white);
  margin: var(--emu-common-spacing-none);
  font: var(--emu-semantic-typography-narrow-body-footnotes);
  font-family: var(--emu-semantic-font-families-medium);
  font-weight: var(--emu-common-font-weight-bold);
}

@media (min-width: 1024px) {
  footer .upper_section p {
    font: var(--emu-semantic-typography-narrow-body-small);
  }
}

footer .lower_section p {
  color: var(--emu-common-colors-white);
  font: var(--emu-semantic-typography-narrow-body-footnotes);
  font-family: var(--emu-semantic-font-families-regular);
}

@media (min-width: 1024px) {
  footer .lower_section p {
    font: var(--emu-semantic-typography-narrow-body-small);
  }

  footer .upper_section > div:nth-of-type(2) > div:first-child > .image:first-child {
    flex: 35%;
  }
}

@media (min-width: 1201px) {
  footer .upper_section > div:nth-of-type(2) > div:first-child > .image:first-child {
    flex: 30%;
  }
}

footer .upper_section {
  padding-bottom: var(--emu-common-spacing-medium);
}

@media (min-width: 1024px) {
  footer .upper_section {
    padding-bottom: 68px;
  }
}

footer .upper_section .aaaem-container {
  background-color: var(--emu-common-colors-transparent);
}

footer .upper_section .aem-Grid div p {
  padding-bottom: 14px;
}

@media (min-width: 1024px) {
  footer .upper_section .aem-Grid div p {
    padding-bottom: var(--emu-common-spacing-medium);
  }
}

footer .upper_section .aem-Grid div a {
  color: inherit;
  text-decoration: none;
}

footer .footer-contents > div {
  display: flex;
}

footer .footer-contents > div div:nth-child(1) {
  flex-basis: 285px;
}

@media (min-width: 1024px) {
  footer .footer-contents > div div:nth-child(1) {
    flex-basis: unset;
  }

  footer .footer-contents > .aem-Grid:first-child > .text:first-child {
    flex: 30%;
  }

  footer .footer-right-container .aem-Grid:first-child {
    display: flex;
  }

  footer .footer-right-container .text:first-child {
    flex: 70%;
  }
}

footer .footer-right-bottom p:nth-child(4) a {
  display: flex;
}

footer .footer-right-bottom p:nth-child(4) a img {
  margin-left: 5px;
}

footer .footer-social-media {
  margin-top: 15px;
}

@media (min-width: 1024px) {
  footer .footer-social-media {
    margin-top: var(--emu-common-spacing-none);
  }
}

footer .footer-social-media .aem-Grid .text {
  flex-basis: 285px;
}

@media (min-width: 1024px) {
  footer .footer-social-media .aem-Grid .text {
    flex-basis: unset;
  }
}

footer .footer-social-media > .aem-Grid:first-child {
  display: flex;
}

@media (min-width: 1024px) {
  footer .footer-social-media > .aem-Grid:first-child {
    flex-direction: column;
  }
}

footer .footer-social-media > .aem-Grid:first-child .text {
  width: 100%;
}

footer .footer-social-media > .aem-Grid:first-child .image {
  padding-top: var(--emu-common-spacing-none);
}

footer .footer-social-media__image .aem-Grid:first-child {
  flex-direction: row;
  display: flex;
}

footer .footer-social-media__image > .aem-Grid:first-child > .image:first-child {
  width: 22px;
}

@media (min-width: 1024px) {
  footer .footer-social-media__image > .aem-Grid:first-child > .image:first-child {
    width: 40px;
  }
}

footer .footer-social-media__image img {
  width: 21px;
}

@media (min-width: 1024px) {
  footer .footer-social-media__image img {
    width: 40px;
  }
}

footer .footer-social-media__image .footer-instagram-icon {
  margin-left: 10px;
}

footer .image {
  margin-bottom: 18px;
}

@media (min-width: 1024px) {
  footer .image {
    margin-top: var(--emu-common-spacing-none);
  }
}

footer .image svg {
  width: 136px;
  height: var(--emu-common-sizing-medium);
}

@media (min-width: 1024px) {
  footer .image svg {
    width: 165px;
    height: 39px;
    margin-top: -7px;
  }
}

footer .image:not(:first-child) {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin: var(--emu-common-spacing-none);
  padding-right: 14px;
}

footer .image:not(:first-child) svg {
  width: 21px;
  height: 21px;
}

@media (min-width: 1024px) {
  footer .image:not(:first-child) svg {
    width: 40px;
    height: 40px;
  }

  footer .image:not(:first-child) {
    padding-top: 10px;
    padding-right: 28px;
  }
}

footer .footer__copyright {
  border-top: solid var(--emu-common-border-width-medium) var(--emu-semantic-colors-primary-white-solid);
  padding-top: 23px;
  padding-bottom: 40px;
}

@media (min-width: 1024px) {
  footer .footer__copyright {
    padding-top: 35px;
    padding-bottom: 65px;
  }
}

footer .footer__copyright p {
  margin: var(--emu-common-spacing-none);
}

footer .footer__copyright p:first-child {
  padding-bottom: 19px;
}

@media (min-width: 1024px) {
  footer .footer__copyright p:first-child {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}

footer .footer__copyright img {
  width: 88px;
}

@media (min-width: 1024px) {
  footer .footer__copyright img {
    width: 176px;
  }

  footer .footer__copyright {
    justify-content: space-between;
    align-items: center;
    padding-top: 47px;
    display: flex;
  }

  footer .footer__copyright p:nth-of-type(2) {
    margin-left: 60px;
  }
}

header {
  background-color: var(--emu-common-colors-white);
  z-index: var(--emu-component-layers-header);
  left: var(--emu-common-spacing-none);
  top: var(--emu-common-spacing-none);
  width: 100%;
  position: fixed;
}

@media (min-width: 1024px) {
  header {
    justify-content: flex-start;
    display: flex;
  }
}

@media (min-width: 1201px) {
  header {
    justify-content: center;
  }
}

header .cmp-container-header {
  height: auto;
  padding-top: var(--emu-common-spacing-medium);
  padding-right: 23px;
  padding-bottom: var(--emu-common-spacing-medium);
  max-width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-left: 23px;
  display: flex;
}

@media (min-width: 1024px) {
  header .cmp-container-header {
    max-width: var(--emu-component-containers-container-max-width);
    padding: var(--emu-common-spacing-none);
    grid-column-gap: 20px;
    -moz-column-gap: 20px;
    max-height: 104px;
    flex: 1;
    grid-template-areas: "image top-navigation"
                         "image navigation";
    justify-content: space-between;
    column-gap: 20px;
    display: grid;
  }
}

@media (min-width: 1201px) {
  header .cmp-container-header {
    margin-left: 26px;
    margin-right: 26px;
  }
}

@media (min-width: 1441px) {
  header .cmp-container-header {
    grid-column-gap: 143px;
    -moz-column-gap: 143px;
    margin-left: var(--emu-common-spacing-none);
    margin-right: var(--emu-common-spacing-none);
    column-gap: 143px;
  }
}

@media (min-width: 1024px) {
  header .cmp-container-header .skip-menu-container {
    padding-left: 70px;
  }

  header .cmp-container-header .skip-menu-container a {
    color: var(--emu-semantic-colors-secondary-grey-300);
    text-decoration: none;
  }

  header .cmp-container-header .header__logo {
    grid-area: image;
  }
}

header .cmp-container-header .header__logo .cmp-image {
  height: 100%;
}

@media (min-width: 1024px) {
  header .cmp-container-header .header__logo .cmp-image {
    margin-left: 25px;
    padding-top: 18px;
    padding-bottom: 18px;
  }
}

@media (min-width: 1441px) {
  header .cmp-container-header .header__logo .cmp-image {
    margin-left: 85px;
  }
}

header .cmp-container-header .header__logo .cmp-image svg {
  height: 38px;
  width: 160px;
}

@media (min-width: 1024px) {
  header .cmp-container-header .header__logo .cmp-image svg {
    height: auto;
    width: auto;
  }
}

@media (min-width: 1441px) {
  header .cmp-container-header .header__content {
    margin-right: 118px;
  }
}

header .cmp-container-header .header__content .header__close-button {
  all: unset;
  margin-right: 10px;
}

header .cmp-container-header .header__content .header__menu__hamburger__button {
  all: unset;
  cursor: pointer;
}

header .cmp-container-header .header__content .header__menu {
  height: 100%;
  width: 100%;
  left: -100%;
  top: var(--emu-common-spacing-none);
  background-color: var(--emu-common-colors-white);
  z-index: var(--emu-common-other-z-index-header);
  transition: all .8s;
  position: fixed;
  overflow: auto;
}

@media (min-width: 1024px) {
  header .cmp-container-header .header__content .header__menu {
    left: var(--emu-common-spacing-none);
    transition: none;
    display: block;
    position: relative;
    overflow: hidden;
  }
}

header .cmp-container-header .header__content .header__menu > div {
  flex-direction: column-reverse;
  display: flex;
}

@media (min-width: 1024px) {
  header .cmp-container-header .header__content .header__menu > div {
    flex-direction: column;
  }
}

header .cmp-container-header .header__content .header__menu.js-toggle-on {
  left: var(--emu-common-spacing-none);
  background-color: var(--emu-component-layers-modal-scrim-color-background);
}

@media (min-width: 1024px) {
  header .cmp-container-header .header__content .header__menu.js-toggle-on {
    background-color: var(--emu-common-colors-white);
  }
}

header .cmp-container-header .header__content .header__menu.js-toggle-on > div {
  background-color: var(--emu-common-colors-white);
}

@media (min-width: 1024px) {
  header .cmp-container-header .header__content .header__menu.js-toggle-on > div {
    background-color: var(--emu-common-colors-transparent);
  }
}

header .cmp-container-header .header__content .header__menu .header__menu__brands {
  background-color: var(--emu-common-colors-transparent);
  border: none;
}

header .cmp-container-header .header__content .header__menu .header__menu__brands .cmp-button__text {
  color: var(--emu-semantic-colors-primary-teal-400);
  font-size: var(--emu-semantic-font-sizes-wide-xs);
  line-height: 16px;
  font-family: var(--emu-semantic-font-families-body);
}

header .cmp-container-header .header__content .header__menu .header__menu__brands svg {
  margin-top: var(--emu-common-spacing-small);
}

@media (min-width: 768px) {
  header .cmp-container-header .header__content .header__menu .header__menu__brands svg {
    margin-top: var(--emu-common-spacing-medium);
  }
}

header .cmp-container-header .header__content .header__menu .header__menu__logo {
  padding-left: 23px;
}

@media (min-width: 1024px) {
  header .cmp-container-header .header__content .header__menu .header__menu__logo {
    display: none;
  }
}

header .cmp-container-header .header__content .header__menu .header__menu__intro__container > div {
  align-items: center;
  display: flex;
}

header .cmp-container-header .header__content .header__menu .header__menu__intro__container > div div:nth-child(2) {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

header .cmp-container-header .header__content .header__menu .header__menu__list {
  background-color: #0000;
  margin-top: 33px;
  padding-left: 25px;
  padding-right: 25px;
}

@media (min-width: 1024px) {
  header .cmp-container-header .header__content .header__menu .header__menu__list {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    margin-top: var(--emu-common-spacing-none);
  }
}

header .cmp-container-header .header__content .header__menu .header__menu__list .emu-navigation__item--active {
  border-bottom: var(--emu-common-border-width-medium) solid var(--emu-semantic-colors-primary-teal-400);
}

header .cmp-container-header .header__content .header__menu .header__menu__list .emu-navigation__item--active a {
  font-weight: var(--emu-common-font-weight-bold);
}

header .cmp-container-header .header__content .header__menu .header__menu__list ul {
  flex-direction: column;
}

@media (min-width: 1024px) {
  header .cmp-container-header .header__content .header__menu .header__menu__list ul {
    flex-direction: row;
    justify-content: space-between;
  }
}

header .cmp-container-header .header__content .header__menu .header__menu__list ul li:last-child {
  display: none;
}

@media (min-width: 1024px) {
  header .cmp-container-header .header__content .header__menu .header__menu__list ul li:last-child {
    display: block;
  }
}

header .cmp-container-header .header__content .header__menu .header__menu__list a {
  color: var(--emu-semantic-colors-primary-teal-400);
  font-family: var(--emu-semantic-font-families-body);
  font-size: var(--emu-semantic-font-sizes-narrow-xs);
  border-bottom: var(--emu-common-border-width-thin) solid var(--emu-semantic-colors-primary-teal-400);
  line-height: normal;
}

@media (min-width: 1024px) {
  header .cmp-container-header .header__content .header__menu .header__menu__list a {
    padding: var(--emu-common-spacing-none);
    padding-bottom: var(--emu-common-spacing-xs);
    font-size: var(--emu-semantic-font-sizes-narrow-small);
    border-bottom: none;
  }
}

header .cmp-container-header .header__content .header__menu .header__menu__text {
  padding-top: 13px;
}

header .cmp-container-header .header__content .header__menu .header__menu__text ul {
  font-family: var(--emu-semantic-font-families-body);
  font-size: var(--emu-semantic-font-sizes-wide-xs);
  line-height: var(--emu-semantic-line-heights-narrow-small);
  padding-left: 30px;
  list-style: none;
}

@media (min-width: 1024px) {
  header .cmp-container-header .header__content .header__menu .header__menu__text ul {
    padding-left: var(--emu-common-spacing-none);
  }
}

header .cmp-container-header .header__content .header__menu .header__menu__text ul:first-child {
  line-height: var(--emu-semantic-line-heights-narrow-medium);
}

@media (min-width: 1024px) {
  header .cmp-container-header .header__content .header__menu .header__menu__text ul:nth-child(2) {
    display: none;
  }
}

header .cmp-container-header .header__content .header__menu .header__menu__text ul li a {
  color: var(--emu-semantic-colors-primary-teal-400);
  letter-spacing: .192px;
  text-transform: uppercase;
  text-decoration: none;
}

@media (min-width: 1024px) {
  header .cmp-container-header .header__content .header__menu .header__menu__text ul li a {
    font-size: 10px;
    line-height: var(--emu-semantic-line-heights-wide-top-navigation);
    letter-spacing: .17px;
    text-transform: unset;
    color: var(--emu-semantic-colors-primary-teal-400);
  }
}

@media (min-width: 1201px) {
  header .cmp-container-header .header__content .header__menu .header__menu__text ul li a {
    font-size: var(--emu-common-font-sizes-narrow-medium);
  }
}

header .cmp-container-header .header__content .header__menu .header__menu__text ul li a .emphasis {
  display: none;
}

@media (min-width: 1024px) {
  header .cmp-container-header .header__content .header__menu .header__menu__text ul li a .emphasis {
    color: var(--emu-semantic-colors-primary-teal-400);
    display: block;
  }
}

header .cmp-container-header .header__content .header__menu .header__menu__text ul li a img {
  vertical-align: middle;
  max-height: 15px;
  margin-bottom: 3px;
  margin-left: 10px;
  display: inline-block;
}

header .cmp-container-header .header__content .header__menu .header__menu__social-media {
  padding-bottom: 25px;
  padding-left: 30px;
}

header .cmp-container-header .header__content .header__menu .header__menu__facebook-icon {
  padding-right: 7px;
}

@media (min-width: 1024px) {
  header .cmp-container-header .header__content .header__menu .header__menu__facebook-icon {
    padding-right: var(--emu-common-spacing-none);
  }
}

header .cmp-container-header .header__content .header__menu .header__menu__follow {
  padding-right: 10px;
}

header .cmp-container-header .header__content .header__menu .header__menu__follow p {
  margin: var(--emu-common-spacing-none);
  color: var(--emu-semantic-colors-primary-teal-400);
  font-family: var(--emu-semantic-font-families-body);
  font-size: var(--emu-semantic-font-sizes-wide-xs);
  line-height: 16px;
}

@media (min-width: 1024px) {
  header .cmp-container-header .header__content .header__menu .header__menu__follow {
    display: none;
  }
}

header .cmp-container-header .header__content .header__menu .header__menu__navlink__container > div {
  flex-direction: column;
  display: flex;
}

@media (min-width: 1024px) {
  header .cmp-container-header .header__content .header__menu .header__menu__navlink__container > div {
    flex-direction: row;
    align-items: center;
  }
}

header .cmp-container-header .header__content .header__menu .header__menu__navlink__container > div > div:last-child {
  width: auto;
}

header .cmp-container-header .header__content .header__menu .header__menu__navlink__container > div > div:last-child li {
  width: 100%;
  padding-left: 25px;
  padding-right: 25px;
}

@media (min-width: 1024px) {
  header .cmp-container-header .header__content .header__menu .header__menu__navlink__container > div > div:last-child li {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

header .cmp-container-header .header__content .header__menu .header__menu__navlink__container > div > div:last-child a {
  color: var(--emu-semantic-colors-primary-teal-400);
  font-family: var(--emu-semantic-font-families-body);
  font-size: var(--emu-semantic-font-sizes-narrow-xs);
  border-bottom: var(--emu-common-border-width-thin) solid var(--emu-semantic-colors-primary-teal-400);
}

@media (min-width: 1024px) {
  header .cmp-container-header .header__content .header__menu .header__menu__navlink__container > div > div:last-child a {
    white-space: pre;
    border: var(--emu-common-border-width-thin) solid var(--emu-semantic-colors-primary-teal-400);
    padding-top: 1px;
    padding-bottom: 1px;
    padding-left: var(--emu-common-spacing-small);
    padding-right: var(--emu-common-spacing-small);
    margin-right: 15px;
    font-size: 13px;
  }
}

@media (min-width: 1441px) {
  header .cmp-container-header .header__content .header__menu .header__menu__navlink__container > div > div:last-child a {
    margin-right: var(--emu-common-spacing-none);
    padding-top: 3px;
    padding-bottom: 3px;
  }
}

@media (min-width: 1024px) {
  header .cmp-container-header .header__content .header__text__container {
    padding-bottom: var(--emu-common-spacing-small);
  }

  header .cmp-container-header .header__content .header__text__container > div {
    align-items: baseline;
    display: flex;
  }

  header .cmp-container-header .header__content .header__text__container > div > div:nth-child(2) {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: 15px;
  }
}

@media (min-width: 1441px) {
  header .cmp-container-header .header__content .header__text__container > div > div:nth-child(2) {
    margin-left: 65px;
  }
}

header .cmp-container-header .header__content .emu-navigation__content-wrapper {
  position: relative;
}

@media (min-width: 1024px) {
  header .cmp-container-header .header__content {
    width: 100%;
    height: 100%;
    grid-area: top-navigation;
    align-items: flex-end;
    padding-bottom: 10px;
    display: flex;
  }
}

header .cmp-container-header .header__content .button {
  display: block;
}

@media (min-width: 1024px) {
  header .cmp-container-header .header__content .button {
    display: none;
  }

  header .cmp-container-header .header__content .cmp-text {
    grid-column-gap: var(--emu-common-spacing-none);
    -moz-column-gap: var(--emu-common-spacing-none);
    column-gap: var(--emu-common-spacing-none);
    display: flex;
  }

  header .cmp-container-header .header__content .cmp-text ul {
    font: var(--emu-semantic-typography-wide-body-top-navigation);
    grid-gap: 5px;
    margin: var(--emu-common-spacing-none);
    padding: var(--emu-common-spacing-none);
    color: var(--emu-semantic-colors-secondary-grey-300);
    justify-content: flex-start;
    gap: 5px;
    list-style: none;
    display: flex;
  }
}

@media (min-width: 1024px) and (min-width: 1024px) {
  header .cmp-container-header .header__content .cmp-text li {
    cursor: pointer;
    display: flex;
  }
}

@media (min-width: 1024px) {
  header .cmp-container-header .header__content .cmp-text li:after {
    display: none;
  }
}

@media (min-width: 1024px) and (min-width: 1024px) {
  header .cmp-container-header .header__content .cmp-text li:after {
    content: "";
    height: 9px;
    width: var(--emu-common-sizing-xxs);
    background-color: var(--emu-semantic-colors-primary-teal-500);
    align-self: center;
    margin-left: 5px;
    margin-right: 2.5px;
    display: flex;
  }
}

@media (min-width: 1024px) and (min-width: 1201px) {
  header .cmp-container-header .header__content .cmp-text li:after {
    margin-left: 10px;
    margin-right: 5px;
  }
}

@media (min-width: 1024px) {
  header .cmp-container-header .header__content .cmp-text li:last-child:after {
    display: none;
  }

  header .cmp-container-header .header__content .cmp-text li a {
    all: unset;
  }

  header .cmp-container-header .header__content .cmp-text li img {
    display: none;
  }

  header .cmp-container-header .header__content .social-network-icons {
    overflow: unset;
    margin-right: 17px;
    display: flex;
  }
}

@media (min-width: 1441px) {
  header .cmp-container-header .header__content .social-network-icons {
    margin-left: 50px;
    margin-right: var(--emu-common-spacing-none);
    margin-top: 15px;
  }
}

header .cmp-container-header .header__content .social-network-icons .cmp-container {
  background-color: inherit;
  align-items: flex-end;
  display: flex;
}

@media (min-width: 1024px) {
  header .cmp-container-header .header__content .social-network-icons .cmp-container {
    grid-gap: 5px;
    padding: var(--emu-common-spacing-none);
    gap: 5px;
  }
}

header .cmp-container-header .header__content .social-network-icons .cmp-container .image {
  display: flex;
}

@media (min-width: 1024px) {
  header .cmp-container-header .header__content .social-network-icons .cmp-container .image {
    padding: var(--emu-common-spacing-none);
    margin-left: var(--emu-common-spacing-none);
  }
}

header .cmp-container-header .header__content .social-network-icons .cmp-container .image svg path {
  fill: var(--emu-semantic-colors-primary-teal-400);
}

@media (min-width: 1024px) {
  header .cmp-container-header .header__content .social-network-icons .cmp-container .image .cmp-image {
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

header .cmp-container-header .header__content .social-network-icons a {
  display: flex;
}

header .cmp-container-header .header__global-navigation {
  left: var(--emu-common-spacing-none);
  top: var(--emu-common-spacing-none);
  width: 100%;
  display: flex;
  position: absolute;
}

@media (min-width: 1024px) {
  header .cmp-container-header .header__global-navigation {
    height: 100%;
    grid-area: navigation;
    display: block;
    position: relative;
  }

  header .cmp-container-header .header__logo {
    -ms-grid-row: 1;
    -ms-grid-row-span: 2;
    -ms-grid-column: 1;
  }

  header .cmp-container-header .header__content {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
  }

  header .cmp-container-header .header__global-navigation {
    -ms-grid-row: 2;
    -ms-grid-column: 3;
  }
}

header .cmp-container-header .header__global-navigation .aaaem-navigation__content-wrapper .aaaem-navigation__global-navigation-close-button button {
  cursor: pointer;
}

header .cmp-container-header .header__global-navigation .aaaem-navigation__content-wrapper .aaaem-navigation__global-navigation-close-button button span {
  display: none;
}

[data-component="container-isi-v2"].isi-scrollable .isi-container {
  z-index: var(--emu-common-other-z-index-isi);
}

[data-component="container-isi-v2"] .aaaem-isi-container ul, [data-component="container-isi-v2"] .aaaem-isi-container p {
  font: var(--emu-semantic-typography-narrow-body-footnotes);
}

[data-component="container-isi-v2"] .aaaem-isi-container ul b, [data-component="container-isi-v2"] .aaaem-isi-container p b {
  color: var(--emu-semantic-colors-primary-teal-400);
  font-family: var(--emu-semantic-font-families-heading);
  font-size: var(--emu-semantic-font-sizes-wide-xs);
  line-height: var(--emu-semantic-line-heights-narrow-xs);
}

@media (min-width: 1024px) {
  [data-component="container-isi-v2"] .aaaem-isi-container ul b, [data-component="container-isi-v2"] .aaaem-isi-container p b {
    font-size: var(--emu-semantic-font-sizes-wide-small);
    line-height: var(--emu-common-line-heights-narrow-large);
  }

  [data-component="container-isi-v2"] .aaaem-isi-container ul, [data-component="container-isi-v2"] .aaaem-isi-container p {
    font: var(--emu-semantic-typography-wide-body-small);
  }
}

[data-component="container-isi-v2"] .aaaem-isi-container .aaaem-isi-container__isi-content .text {
  width: calc(100% - 15px);
}

@media (min-width: 1024px) {
  [data-component="container-isi-v2"] .aaaem-isi-container .aaaem-isi-container__isi-content .text {
    width: calc(100% - 25px);
  }
}

[data-component="container-isi-v2"] .aaaem-isi-container .aaaem-isi-container__isi-content.js-expand {
  max-width: var(--emu-component-containers-container-max-width);
  margin: auto;
}

[data-component="container-isi-v2"] .aaaem-isi-container .aaaem-isi-banner {
  max-width: var(--emu-component-containers-container-max-width);
  margin-top: var(--emu-common-spacing-none);
  margin-right: auto;
  margin-bottom: var(--emu-common-spacing-none);
  justify-content: right;
  margin-left: auto;
  position: relative;
}

[data-component="container-isi-v2"] .aaaem-isi-container__banner-content {
  max-height: 95px;
  --mask: linear-gradient( 360deg, #75242400 20%, var(--emu-semantic-colors-primary-bone) 0% );
  -webkit-mask: var(--mask);
  -webkit-mask: var(--mask);
  mask: var(--mask);
  padding-top: 15px;
}

@media (min-width: 768px) {
  [data-component="container-isi-v2"] .aaaem-isi-container__banner-content {
    --mask: linear-gradient( 360deg, var(--emu-semantic-colors-secondary-grey-400) 32%, var(--emu-semantic-colors-primary-bone) 0% );
  }
}

@media (min-width: 1024px) {
  [data-component="container-isi-v2"] .aaaem-isi-container__banner-content {
    --mask: linear-gradient( 360deg, var(--emu-semantic-colors-secondary-grey-400) 0%, var(--emu-semantic-colors-primary-bone) 60% );
    max-height: 105px;
    padding-top: var(--emu-common-spacing-none);
  }
}

[data-component="container-isi-v2"] .aaaem-isi-container__banner-content p {
  margin-bottom: var(--emu-common-spacing-xs);
  margin-top: var(--emu-common-spacing-xs);
}

@media (min-width: 1024px) {
  [data-component="container-isi-v2"] .aaaem-isi-container__banner-content p .emphasis {
    display: none;
  }

  [data-component="container-isi-v2"] .aaaem-isi-container__banner-content {
    display: flex;
  }

  [data-component="container-isi-v2"] .aaaem-isi-container__banner-content p {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  [data-component="container-isi-v2"] .aaaem-isi-container__banner-content > div:first-child {
    width: 29%;
    padding-right: var(--emu-common-spacing-xs);
    margin-right: 20px;
  }

  [data-component="container-isi-v2"] .aaaem-isi-container__banner-content > div:first-child p:last-child, [data-component="container-isi-v2"] .aaaem-isi-container__banner-content > div:first-child p:nth-child(3) {
    margin-top: var(--emu-common-spacing-none);
  }
}

[data-component="container-isi-v2"] .aaaem-isi-container__banner-content > div:first-child p:nth-child(3) {
  margin-bottom: var(--emu-common-spacing-none);
}

@media (min-width: 1024px) {
  [data-component="container-isi-v2"] .aaaem-isi-container__banner-content > div:nth-child(2) {
    width: 70%;
  }

  [data-component="container-isi-v2"] .aaaem-isi-container__banner-content > div:nth-child(2) p:last-child {
    margin-top: var(--emu-common-spacing-none);
  }

  [data-component="container-isi-v2"] .aaaem-isi-container__banner-content > div:nth-child(2) p:first-child {
    margin-bottom: var(--emu-common-spacing-none);
  }

  [data-component="container-isi-v2"] .aaaem-isi-container__button-wrapper {
    position: absolute;
  }
}

[data-component="container-isi-v2"] .aaaem-isi-container__button-wrapper button {
  padding-left: 15px;
  padding-right: 15px;
}

[data-component="container-isi-v2"] .aaaem-isi-container__button-wrapper button .cmp-button__text {
  display: none;
}

[data-component="container-isi-v2"] .aaaem-isi-container__button-wrapper button.js-toggle-on {
  right: var(--emu-common-spacing-medium);
  top: var(--emu-common-spacing-none);
  z-index: var(--emu-component-containers-isi-z-index);
  position: absolute;
}

@media (min-width: 1024px) {
  [data-component="container-isi-v2"] .aaaem-isi-container__button-wrapper button.js-toggle-on {
    position: static;
    right: 65px;
  }
}

[data-component="container-isi-v2"] .aaaem-isi-container .aaaem-button.plus-minus-icon .aaaem-button__icon {
  height: 20px;
}

[data-component="container-isi-v2"] .aaaem-isi-container .aaaem-button.plus-minus-icon .aaaem-button__icon:before, [data-component="container-isi-v2"] .aaaem-isi-container .aaaem-button.plus-minus-icon .aaaem-button__icon:after {
  background-color: var(--emu-semantic-colors-primary-teal-400);
}

[data-component="container-isi-v2"] .aaaem-isi-container .aaaem-button:focus {
  outline: none;
}

[data-component="container-isi-v2"] .aaaem-isi-container__isi-content > div {
  max-width: var(--emu-component-containers-container-max-width);
  margin: auto;
}

[data-component="container-isi-v2"] .aaaem-isi-container__isi-content > div p, [data-component="container-isi-v2"] .aaaem-isi-container__isi-content > div ul {
  font: var(--emu-semantic-typography-narrow-body-footnotes);
  line-height: 17px;
}

@media (min-width: 1024px) {
  [data-component="container-isi-v2"] .aaaem-isi-container__isi-content > div p, [data-component="container-isi-v2"] .aaaem-isi-container__isi-content > div ul {
    font: var(--emu-semantic-typography-wide-body-small);
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    line-height: 18px;
  }
}

[data-component="container-isi-v2"] .aaaem-isi-container__isi-content > div p:first-child, [data-component="container-isi-v2"] .aaaem-isi-container__isi-content > div ul:first-child {
  margin-top: var(--emu-common-spacing-none);
}

[data-component="container-isi-v2"] .aaaem-isi-container__isi-content > div p a, [data-component="container-isi-v2"] .aaaem-isi-container__isi-content > div p a:hover, [data-component="container-isi-v2"] .aaaem-isi-container__isi-content > div p a:active, [data-component="container-isi-v2"] .aaaem-isi-container__isi-content > div p a:visited, [data-component="container-isi-v2"] .aaaem-isi-container__isi-content > div ul a, [data-component="container-isi-v2"] .aaaem-isi-container__isi-content > div ul a:hover, [data-component="container-isi-v2"] .aaaem-isi-container__isi-content > div ul a:active, [data-component="container-isi-v2"] .aaaem-isi-container__isi-content > div ul a:visited {
  color: var(--emu-semantic-colors-primary-teal-400);
  text-decoration: none;
}

[data-component="container-isi-v2"] .aaaem-isi-container__isi-content > div ul {
  padding-left: 14px;
}

@media (min-width: 1024px) {
  [data-component="container-isi-v2"] .aaaem-isi-container__isi-content > div ul {
    padding-left: 22px;
  }
}

[data-component="container-isi-v2"] .aaaem-isi-container__isi-content > div ul:nth-child(5), [data-component="container-isi-v2"] .aaaem-isi-container__isi-content > div ul:nth-child(9) {
  margin-top: -10px;
}

[data-component="container-isi-v2"] .aaaem-isi-container__isi-content > div b {
  color: var(--emu-semantic-colors-primary-teal-400);
  font-family: var(--emu-semantic-font-families-heading);
  font-size: var(--emu-semantic-font-sizes-wide-xs);
}

@media (min-width: 1024px) {
  [data-component="container-isi-v2"] .aaaem-isi-container__isi-content > div b {
    font-size: var(--emu-semantic-font-sizes-wide-small);
  }
}

[data-component="container-isi-v2"] [data-ref="content-footer"] {
  border-top: var(--emu-common-border-width-thick) solid var(--emu-semantic-colors-primary-teal-400);
}

[data-component="container-isi-v2"] .aaaem-isi-holder .text ul {
  -webkit-padding-start: 14px;
  padding-inline-start: 14px;
}

[data-component="container-isi-v2"] .aaaem-isi-holder > .aaaem-isi-container__isi-content .text {
  max-width: var(--emu-component-containers-container-max-width);
  margin: auto;
}

.modal .modal-content {
  border-radius: var(--emu-semantic-border-radius-xs);
  padding-top: 35px;
  padding-bottom: 35px;
}

@media (min-width: 1024px) {
  .modal .modal-content {
    padding-top: 42px;
    padding-left: var(--emu-common-spacing-large);
    padding-right: var(--emu-common-spacing-large);
  }
}

.modal .modal-content .close {
  padding-top: var(--emu-common-spacing-small);
  padding-right: 10px;
  padding-bottom: var(--emu-common-spacing-small);
  padding-left: 10px;
}

@media (min-width: 1024px) {
  .modal .modal-content .close {
    padding: 14px 18px;
  }
}

.modal .modal-content .aaaem-container {
  flex-flow: wrap;
  margin: auto;
  display: flex;
}

.modal .modal-content .aaaem-container .cmp-button {
  min-width: 80px;
}

.modal .modal-content .aaaem-text {
  width: 100%;
  padding-bottom: 15px;
}

.modal .modal-content .aaaem-text a {
  color: var(--emu-semantic-colors-primary-black);
  text-decoration: none;
}

.modal .modal-content .aaaem-text h3 {
  font: var(--emu-semantic-typography-narrow-headings-headline-h4);
  font-size: var(--emu-semantic-font-sizes-narrow-small);
  margin-top: 5px;
}

@media (min-width: 1024px) {
  .modal .modal-content .aaaem-text h3 {
    font-size: var(--emu-semantic-font-sizes-narrow-large);
    margin-top: var(--emu-common-spacing-none);
    margin-bottom: 3px;
  }
}

.modal .modal-content .aaaem-text p {
  font: var(--emu-semantic-typography-narrow-body-regular);
  margin: var(--emu-common-spacing-none);
  line-height: var(--emu-semantic-line-heights-wide-small);
}

@media (min-width: 1024px) {
  .modal .modal-content .aaaem-text p {
    font: var(--emu-semantic-typography-wide-body-regular);
    line-height: 1.2;
  }
}

.modal .modal-content .modal-cta-container {
  flex-direction: row-reverse;
  justify-content: flex-end;
}

@media (min-width: 1024px) {
  .modal .modal-content .modal-cta-container {
    margin-bottom: 5px;
  }
}

.modal .modal-content .modal-cta-container .button {
  padding-right: 15px;
}

.modal .modal-content .modal-cta-container .aaaem-button {
  font: var(--emu-semantic-typography-narrow-body-regular);
  padding-bottom: var(--emu-common-spacing-xs);
  padding-top: var(--emu-common-spacing-xs);
}

.modal [data-id="brand-nav"] .modal-wrapper {
  animation-name: slide;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-delay: 0s;
  overflow: auto;
}

@keyframes slide {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

@media (min-width: 1024px) {
  .modal [data-id="brand-nav"] .modal-wrapper {
    animation-name: colorChange;
    animation-duration: 1s;
    animation-timing-function: ease;
    animation-delay: .2s;
    animation-fill-mode: forwards;
  }

  @keyframes slide {
    from {
      transform: translateX(0);
    }

    to {
      transform: translateX(0);
    }
  }

  @keyframes colorChange {
    from {
      background-color: var(--emu-component-layers-modal-scrim-color-background);
    }

    to {
      background-color: var(--emu-component-layers-modal-scrim-color-background);
    }
  }

  .modal [data-id="brand-nav"] .modal-wrapper {
    background-color: var(--emu-common-colors-transparent);
    animation: none;
  }

  .modal [data-id="brand-nav"] .modal-wrapper.is-open {
    height: auto;
  }
}

.modal [data-id="brand-nav"] .modal-content, .modal [data-id="brand-nav"] .aaaem-container {
  background-color: var(--emu-semantic-colors-primary-teal-200);
}

@media (min-width: 1024px) {
  .modal [data-id="brand-nav"] .modal-content, .modal [data-id="brand-nav"] .aaaem-container {
    background-color: var(--emu-semantic-colors-primary-white-solid);
  }
}

.modal [data-id="brand-nav"] .modal-content {
  border-radius: var(--emu-common-border-radius-none);
  margin: var(--emu-common-spacing-none);
  max-width: none;
  width: 100%;
  padding-bottom: 66px;
  padding-left: 25px;
  padding-right: 25px;
}

@media (min-width: 1024px) {
  .modal [data-id="brand-nav"] .modal-content {
    padding-bottom: 24px;
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    padding-top: 24px;
    animation-name: bannerSlide;
    animation-duration: 1s;
    animation-timing-function: ease;
    animation-delay: .2s;
    animation-fill-mode: forwards;
    transform: translateY(-100%);
  }

  @keyframes bannerSlide {
    from {
      transform: translateX(0)translateY(-100%);
    }

    to {
      transform: translateX(0)translateY(0);
    }
  }
}

.modal [data-id="brand-nav"] .modal-content .main-close {
  border: var(--emu-common-border-width-thin) solid var(--emu-semantic-colors-primary-teal-400);
  height: 38px;
  padding: var(--emu-common-spacing-none);
  width: 38px;
  border-radius: 50%;
  margin-top: 25px;
  margin-right: 25px;
  display: flex;
}

@media (min-width: 1024px) {
  .modal [data-id="brand-nav"] .modal-content .main-close {
    border: none;
    margin-top: 14px;
    margin-right: 25px;
  }
}

.modal [data-id="brand-nav"] .modal-content .main-close span {
  font-size: 0;
}

.modal [data-id="brand-nav"] .modal-content .main-close:before, .modal [data-id="brand-nav"] .modal-content .main-close:after {
  background-color: var(--emu-semantic-colors-primary-teal-400);
  content: "";
  height: 20px;
  width: var(--emu-common-sizing-xxs);
  margin: auto;
  display: block;
  transform: rotate(45deg);
}

@media (min-width: 1024px) {
  .modal [data-id="brand-nav"] .modal-content .main-close:before, .modal [data-id="brand-nav"] .modal-content .main-close:after {
    background-color: var(--emu-semantic-colors-primary-black);
    width: 2px;
  }
}

.modal [data-id="brand-nav"] .modal-content .main-close:after {
  left: 17px;
  top: var(--emu-common-spacing-small);
  position: absolute;
  transform: rotate(-45deg);
}

@media (min-width: 1024px) {
  .modal [data-id="brand-nav"] .modal-content .main-close:after {
    top: 9px;
    left: 18px;
  }
}

.modal [data-id="brand-nav"] .modal-content > .container {
  margin: var(--emu-common-spacing-none);
}

.modal [data-id="brand-nav"] #brand-nav .button {
  width: auto;
}

@media (min-width: 1024px) {
  .modal [data-id="brand-nav"] #brand-nav .button {
    display: none;
  }
}

.modal [data-id="brand-nav"] #brand-nav button[data-modal="brand-nav"] {
  background-color: var(--emu-common-colors-transparent);
  color: var(--emu-semantic-colors-primary-black);
  font: var(--emu-semantic-typography-narrow-body-regular);
  font-size: var(--emu-semantic-font-sizes-wide-xs);
  line-height: var(--emu-semantic-line-heights-narrow-xs);
  padding-left: var(--emu-common-spacing-none);
  pointer-events: none;
  border: none;
}

.modal [data-id="brand-nav"] #brand-nav h2 {
  font-family: var(--emu-common-font-families-sans);
  font-size: var(--emu-semantic-font-sizes-narrow-xs);
  margin-bottom: var(--emu-common-spacing-none);
  margin-top: var(--emu-common-spacing-none);
  line-height: 26px;
}

@media (min-width: 1024px) {
  .modal [data-id="brand-nav"] #brand-nav h2 {
    font-size: var(--emu-common-font-sizes-wide-medium);
  }
}

.modal [data-id="brand-nav"] #brand-nav p, .modal [data-id="brand-nav"] #brand-nav span {
  font-size: var(--emu-common-font-sizes-narrow-medium);
  line-height: 12px;
}

@media (min-width: 1024px) {
  .modal [data-id="brand-nav"] #brand-nav p, .modal [data-id="brand-nav"] #brand-nav span {
    font-size: 10px;
    line-height: 10px;
  }
}

.modal [data-id="brand-nav"] #brand-nav .text {
  padding-bottom: var(--emu-common-spacing-none);
  display: flex;
}

@media (min-width: 1024px) {
  .modal [data-id="brand-nav"] #brand-nav .text {
    text-align: center;
  }
}

.modal [data-id="brand-nav"] #brand-nav .text:not(.aaaem-text) {
  border-bottom: solid 1px var(--emu-semantic-colors-primary-teal-400);
  padding-top: 10px;
  padding-bottom: 10px;
}

@media (min-width: 1024px) {
  .modal [data-id="brand-nav"] #brand-nav .text:not(.aaaem-text) {
    border-bottom: none;
  }
}

.modal [data-id="brand-nav"] #brand-nav .text .aaaem-text {
  display: block;
}

@media (min-width: 1024px) {
  .modal [data-id="brand-nav"] #brand-nav .text .aaaem-text {
    padding: var(--emu-common-spacing-none);
    margin: auto;
  }

  .modal [data-id="brand-nav"] #brand-nav > div {
    display: flex;
  }

  .modal [data-id="brand-nav"] #brand-nav > div .text:not(:first-child):not(:nth-child(2)) {
    border-left: solid var(--emu-common-border-width-thin) var(--emu-semantic-colors-primary-black);
    padding-left: var(--emu-common-spacing-xs);
    padding-right: var(--emu-common-spacing-xs);
  }
}

.modal .modal-mothers-day-gift .modal-content {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  border-radius: var(--emu-common-border-radius-none);
  top: var(--emu-common-spacing-none);
  padding: var(--emu-common-spacing-none);
  background-color: #e6f7f8;
  margin: 100px auto;
}

@media (min-width: 768px) {
  .modal .modal-mothers-day-gift .modal-content {
    max-width: 760px;
  }
}

.modal .modal-mothers-day-gift .modal-content .mom-modal {
  position: relative;
}

.modal .modal-mothers-day-gift .modal-content .mom-modal__content-container .mom-modal__title-container {
  width: 100%;
  position: absolute;
  top: 35px;
}

@media (min-width: 768px) {
  .modal .modal-mothers-day-gift .modal-content .mom-modal__content-container .mom-modal__title-container {
    position: static;
  }
}

.modal .modal-mothers-day-gift .modal-content .mom-modal__content-container .mom-modal__cta-container {
  width: 100%;
  position: absolute;
  bottom: 52px;
}

@media (min-width: 768px) {
  .modal .modal-mothers-day-gift .modal-content .mom-modal__content-container .mom-modal__cta-container {
    position: static;
  }

  .modal .modal-mothers-day-gift .modal-content .mom-modal__content-container {
    position: absolute;
    top: 112px;
    right: -185px;
  }
}

.modal .modal-mothers-day-gift .modal-content .mom-modal__pre-title .cmp-title__text, .modal .modal-mothers-day-gift .modal-content .mom-modal__pre-title p {
  font-weight: var(--emu-common-font-weight-regular);
  font-size: var(--emu-common-font-sizes-wide-medium);
  text-align: center;
  letter-spacing: .317px;
  color: #0f322c;
  margin-bottom: var(--emu-common-spacing-none);
  margin-top: var(--emu-common-spacing-none);
  line-height: 1;
  font-family: var(--emu-semantic-font-families-body);
  padding-bottom: var(--emu-common-spacing-xxs);
  border-bottom: 1.5px solid #00acb9;
  display: inline-block;
  position: relative;
}

@media (min-width: 768px) {
  .modal .modal-mothers-day-gift .modal-content .mom-modal__pre-title .cmp-title__text, .modal .modal-mothers-day-gift .modal-content .mom-modal__pre-title p {
    font-size: var(--emu-semantic-font-sizes-narrow-small);
    letter-spacing: .4px;
  }
}

.modal .modal-mothers-day-gift .modal-content .mom-modal__main-title {
  padding-top: 9px;
}

@media (min-width: 768px) {
  .modal .modal-mothers-day-gift .modal-content .mom-modal__main-title {
    padding-top: 12px;
  }
}

.modal .modal-mothers-day-gift .modal-content .mom-modal__main-title .cmp-title__text, .modal .modal-mothers-day-gift .modal-content .mom-modal__main-title p {
  letter-spacing: .475px;
  text-align: center;
  color: #160c1f;
  font-size: 24px;
  font-weight: 250;
  line-height: 28px;
  font-family: var(--emu-semantic-font-families-juana-light);
  margin-top: 0;
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .modal .modal-mothers-day-gift .modal-content .mom-modal__main-title .cmp-title__text, .modal .modal-mothers-day-gift .modal-content .mom-modal__main-title p {
    letter-spacing: .6px;
    font-size: 30px;
    line-height: 35px;
  }
}

.modal .modal-mothers-day-gift .modal-content .mom-modal__main-title .cmp-title__text bdi, .modal .modal-mothers-day-gift .modal-content .mom-modal__main-title p bdi {
  color: #00acb9;
}

.modal .modal-mothers-day-gift .modal-content .mom-modal__cta {
  width: auto;
  font-family: var(--emu-semantic-font-families-body);
  font-weight: var(--emu-common-font-weight-regular);
  padding-top: var(--emu-common-spacing-small);
  padding-bottom: var(--emu-common-spacing-small);
  border: var(--emu-common-border-width-none);
  border-radius: var(--emu-common-border-radius-none);
  line-height: var(--emu-common-line-heights-narrow-medium);
  font-size: var(--emu-common-font-sizes-narrow-medium);
  margin-bottom: 5px;
  padding-left: 45px;
  padding-right: 45px;
  text-decoration: none;
  display: inline-block;
}

.modal .modal-mothers-day-gift .modal-content .mom-modal__cta, .modal .modal-mothers-day-gift .modal-content .mom-modal__cta:hover, .modal .modal-mothers-day-gift .modal-content .mom-modal__cta:focus, .modal .modal-mothers-day-gift .modal-content .mom-modal__cta:active {
  color: var(--emu-common-colors-white);
  background-color: #0f322c;
}

@media (min-width: 768px) {
  .modal .modal-mothers-day-gift .modal-content .mom-modal__cta {
    margin-top: 27px;
    margin-bottom: 18px;
    padding: 12px 57px;
    font-size: 15px;
  }
}

.modal .modal-mothers-day-gift .modal-content .mom-modal__cta-container .button {
  text-align: center;
}

.modal .modal-mothers-day-gift .modal-content .mom-modal__cta-container .button:not(.backToTop) {
  display: block;
}

.modal .modal-mothers-day-gift .modal-content .mom-modal__disclaimer {
  padding-bottom: 18px;
}

@media (min-width: 768px) {
  .modal .modal-mothers-day-gift .modal-content .mom-modal__disclaimer {
    padding-bottom: var(--emu-common-spacing-none);
  }
}

.modal .modal-mothers-day-gift .modal-content .mom-modal__disclaimer p {
  font-weight: var(--emu-common-font-weight-regular);
  font-size: 6px;
  font-family: var(--emu-semantic-font-families-body);
  letter-spacing: .12px;
  color: var(--emu-common-colors-black);
  left: var(--emu-common-spacing-none);
  right: var(--emu-common-spacing-none);
  text-align: center;
  line-height: 1.2;
  position: absolute;
  bottom: 10px;
}

@media (min-width: 768px) {
  .modal .modal-mothers-day-gift .modal-content .mom-modal__disclaimer p {
    text-align: left;
    bottom: 5px;
    left: 6px;
  }
}

.modal .modal-mothers-day-gift .modal-content .mom-modal__text {
  padding-bottom: var(--emu-common-spacing-none);
}

@media (min-width: 768px) {
  .modal .modal-mothers-day-gift .modal-content .mom-modal__text {
    padding-bottom: 15px;
  }
}

.modal .modal-mothers-day-gift .modal-content .mom-modal__text p {
  letter-spacing: .22px;
  font-size: 11px;
  line-height: 1;
  font-weight: var(--emu-common-font-weight-regular);
  color: var(--emu-common-colors-black);
  text-align: center;
  font-family: var(--emu-semantic-font-families-body);
}

.modal .modal-mothers-day-gift .modal-content .mom-modal__text img {
  max-width: 102px;
  margin-left: 8px;
  margin-right: 7px;
  position: relative;
  top: 12px;
}

.modal .modal-mothers-day-gift .close {
  font-family: var(--emu-semantic-font-families-body);
  color: var(--emu-component-layers-modal-actions-close-text-color-light);
  width: 12px;
  padding: var(--emu-common-spacing-none);
  z-index: var(--emu-common-other-z-index-layer);
  font-size: 24px;
  top: 10px;
  right: 20px;
}

.modal .modal-mothers-day-gift .close:hover, .modal .modal-mothers-day-gift .close:focus {
  opacity: 1;
}

.modal .modal-mothers-day-gift .aaaem-container {
  background-color: var(--emu-common-colors-transparent);
}

.plyr__volume {
  width: auto;
  min-width: 0;
  justify-content: flex-end;
}

@media (min-width: 1024px) {
  .has-media {
    height: 810px;
  }
}

.teaser .teaser-container {
  align-self: center;
}

@media (min-width: 1024px) {
  .teaser .teaser-container {
    height: 100%;
    display: flex;
  }
}

.teaser .teaser-image-container .cmp-teaser__image--desktop img {
  -o-object-fit: cover;
  object-fit: cover;
}

.teaser .content-container {
  padding-top: var(--emu-common-spacing-none);
  width: 100%;
}

.teaser .content-container .content {
  margin: var(--emu-common-spacing-none);
  order: 2;
}

@media (min-width: 1024px) {
  .teaser .content-container .content {
    order: 1;
  }
}

.teaser .content-container .title-lockup {
  margin: auto;
}

@media (min-width: 1024px) {
  .teaser .content-container .title-lockup {
    margin-left: var(--emu-common-spacing-none);
  }
}

.teaser .content-container .title-lockup h2 {
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-none);
}

.teaser .content-container .disclaimer {
  order: 1;
  margin-bottom: 42px;
}

@media (min-width: 1024px) {
  .teaser .content-container .disclaimer {
    visibility: hidden;
  }
}

.teaser .content-container .disclaimer p {
  font: var(--emu-semantic-typography-narrow-body-footnotes);
}

@media (min-width: 1024px) {
  .teaser .content-container .disclaimer p {
    font: var(--emu-semantic-typography-wide-body-footnotes);
    margin-bottom: var(--emu-common-spacing-none);
  }

  .teaser .content-container .disclaimer {
    margin-top: var(--emu-common-spacing-none);
    margin-bottom: var(--emu-common-spacing-none);
    order: 2;
  }

  .teaser .content-container {
    height: 100%;
    width: 100%;
    justify-content: space-between;
    margin-left: 95px;
    position: relative;
  }
}

.teaser .cmp-teaser {
  position: relative;
  overflow: hidden;
}

.teaser .cmp-teaser img {
  -o-object-position: 80%;
  object-position: 80%;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 400px;
}

@media (min-width: 1024px) {
  .teaser .cmp-teaser img {
    left: var(--emu-common-spacing-none);
    top: var(--emu-common-spacing-none);
    height: 100%;
    position: absolute;
  }
}

.teaser .cmp-teaser__title, .teaser .cmp-teaser__description {
  text-align: center;
}

@media (min-width: 1024px) {
  .teaser .cmp-teaser__title, .teaser .cmp-teaser__description {
    text-align: left;
  }
}

.teaser .cmp-teaser__title {
  font: var(--emu-semantic-typography-narrow-headings-eyebrow);
  margin-bottom: 42px;
}

@media (min-width: 1024px) {
  .teaser .cmp-teaser__title {
    font: var(--emu-semantic-typography-wide-headings-eyebrow);
    margin: var(--emu-common-spacing-none);
  }
}

.teaser .cmp-teaser__title:after {
  content: "";
  width: 100%;
  background: var(--emu-semantic-colors-primary-teal-300);
  height: var(--emu-semantic-border-width-thick);
  border-radius: var(--emu-semantic-border-radius-round);
  display: flex;
  position: relative;
}

.teaser .cmp-teaser__description {
  margin: auto;
}

.teaser .cmp-teaser__description p {
  font: var(--emu-semantic-typography-narrow-headings-headline-h1);
}

.teaser .cmp-teaser__description p sup {
  font-size: var(--emu-semantic-font-sizes-narrow-medium);
  line-height: 0;
  position: relative;
  top: 3px;
}

@media (min-width: 1024px) {
  .teaser .cmp-teaser__description p sup {
    font-size: 30px;
    top: 1px;
  }

  .teaser .cmp-teaser__description {
    margin: var(--emu-common-spacing-none);
  }

  .teaser .cmp-teaser__description p {
    margin-top: var(--emu-common-spacing-medium);
    font: var(--emu-semantic-typography-wide-headings-headline-h1);
  }

  .teaser .teaser-container {
    position: relative;
  }
}

/*# sourceMappingURL=main.css.map */
