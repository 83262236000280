#savings {
  .teaser {
    width: 100%;
    overflow: hidden;

    .content-container {
      padding-bottom: var(--emu-common-spacing-none);
      padding-left: 26px;
      padding-right: 26px;
      width: 100%;

      @include mq('x-large') {
        padding-left: 95px;
        margin: auto;
        max-width: var(--emu-component-containers-container-max-width);
        margin-top: 60px;
        align-items: center;
        justify-content: center;
      }

      .aaaem-teaser__title {
        margin-bottom: var(--emu-common-spacing-none);
      }
    }

    .disclaimer {
      margin-top: var(--emu-common-spacing-small);
      margin-bottom: 45px;

      @include mq('x-large') {
        display: none;
      }

      p {
        font-size: var(--emu-semantic-font-sizes-narrow-xxs);
        line-height: var(--emu-semantic-line-heights-narrow-xxs);
        font-family: var(--emu-semantic-font-families-light);
        letter-spacing: 0.165px;
        margin-top: var(--emu-common-spacing-none);
        margin-bottom: var(--emu-common-spacing-none);
      }
    }

    .cmp-teaser {
      &__title {
        margin-top: var(--emu-common-spacing-none);
      }

      &__description {
        width: 100%;

        @include mq('medium') {
          width: 50%;
        }

        @include mq('x-large') {
          width: 40%;
        }

        p {
          margin-top: 37px;
          margin-bottom: 72px;
        }

        sup {
          position: relative;
          top: 3px;

          @include mq('large') {
            top: 1px;
          }

          &:nth-child(2) {
            font-size: 24px;
            top: 3px;
            @include mq('large') {
              top: -4px;
            }
          }
        }
      }
    }

    .teaser-image-container {
      background-color: #dcd5cf; // used once here
      margin-top: 10px;
    }
  }

  .disclaimer--text--desktop {
    @include mq('large') {
      p {
        margin-bottom: 83px;
      }
    }
  }

  .body-intro {
    display: flex;
    justify-content: center;

    @include mq('large') {
      margin-top: 35px;
      padding-top: 58px;
      max-width: var(--emu-component-containers-container-max-width);
      margin: auto;
      float: unset;
    }

    &__join-alle-container {
      > .button {
        max-width: 143px; // to match figma

        @include mq('large') {
          margin-bottom: 15px;
        }
      }

      .aaaem-text {
        p {
          font-size: var(--emu-semantic-font-sizes-narrow-medium);
          line-height: 32px;
          margin-top: var(--emu-common-spacing-none);
          margin-bottom: 20px;

          @include mq('x-large') {
            font-size: 25px;
            line-height: 35px;
            margin-bottom: 10px;
          }
        }

        a {
          font-family: var(--emu-semantic-font-families-heading);
          color: var(--emu-semantic-colors-primary-teal-400);
        }
      }
    }

    &__text {
      &-container {
        margin: var(--emu-common-spacing-none);
        padding-left: 48px;
        padding-right: 48px;
        margin-bottom: 80px;
        width: 100%;
        display: flex;
        justify-content: center;

        > .aaaem-container {
          max-width: 1255px; //as per figma
        }

        .btn--alle {
          min-width: 143px;
          width: 100%;
          font-family: var(--emu-semantic-font-families-body);
          font-size: var(--emu-semantic-font-sizes-narrow-small);
          line-height: 1;
          padding-top: 9px;
          padding-bottom: 9px;
          margin-bottom: 10px;
          justify-content: center;

          @include mq('x-large') {
            min-width: 181px;
            font-size: 25px;
            font-weight: 500;
            padding-top: 12px;
            padding-bottom: 12px;
          }
        }

        .body-intro-text {
          @include mq('x-large') {
            margin-top: var(--emu-common-spacing-none);
          }

          p {
            font-size: var(--emu-semantic-font-sizes-narrow-large);
            line-height: var(--emu-semantic-line-heights-narrow-large);
            margin: var(--emu-common-spacing-none);
            margin-top: 25px;
            margin-bottom: 58px;
            color: var(--emu-semantic-colors-primary-teal-500);

            @include mq('large') {
              font-size: 35px;
              line-height: var(--emu-semantic-line-heights-wide-medium);
              font-weight: 100;
              margin-bottom: 27px;
              padding-right: 90px;
            }
          }

          ul {
            list-style: none;
            display: flex;
            flex-direction: column;
            gap: 52px;
            padding-left: var(--emu-common-spacing-none);
            margin: var(--emu-common-spacing-none);

            @include mq('large') {
              gap: 28px;
            }

            li {
              font: var(
                --emu-semantic-typography-narrow-headings-headline-h4-teal
              );
              color: var(--emu-semantic-colors-primary-teal-500);

              @include mq('large') {
                font: var(
                  --emu-semantic-typography-wide-headings-headline-h4-teal
                );
              }

              p {
                padding-top: 11px;
                margin-top: var(--emu-common-spacing-none);
                margin-bottom: var(--emu-common-spacing-none);
                font: var(--emu-semantic-typography-narrow-body-regular);
                color: var(--emu-common-colors-black);
                letter-spacing: 0.25px;
                padding-right: var(--emu-common-spacing-none);

                @include mq('large') {
                  font: var(--emu-semantic-typography-wide-body-regular);
                }
              }
            }
          }
        }
      }
    }
  }

  .body-provider {
    margin-bottom: 95px;
    padding-left: 25px;
    padding-right: 25px;

    @include mq('large') {
      padding-left: var(--emu-common-spacing-none);
      padding-right: var(--emu-common-spacing-none);
      margin-bottom: 152px;
    }

    .aem-Grid {
      background: var(--emu-semantic-colors-primary-bone);
      border-radius: 20px;
      padding-left: 13px;
      padding-right: 13px;
      padding-top: 35px;
      padding-bottom: 44px;

      @include mq('large') {
        border-radius: var(--emu-semantic-border-radius-none);
        padding-top: 46px;
        padding-bottom: 69px;
      }

      h3 {
        font-size: var(--emu-semantic-font-sizes-narrow-xxl);
        line-height: var(--emu-semantic-line-heights-narrow-xxl);
        margin: var(--emu-common-spacing-none);

        @include mq('large') {
          font-size: var(--emu-semantic-font-sizes-wide-xl);
          line-height: var(--emu-semantic-line-heights-wide-xxl);
        }
      }

      p {
        font-size: var(--emu-semantic-font-sizes-narrow-small);
        line-height: var(--emu-semantic-line-heights-narrow-medium);
        margin-top: 13px;
        margin-bottom: 28px;
        max-width: 702px; //as per figma
        margin-left: auto;
        margin-right: auto;

        @include mq('large') {
          font-size: 25px;
          line-height: 35px;
          margin-bottom: 50px;
        }

        sup {
          position: relative;
          top: 4px;
          line-height: 0; //needed to fix line-height issue
          @include mq('large') {
            top: 6px;
          }
        }
      }

      > .button {
        svg {
          transition: all 0.3s ease-out;
          margin: var(--emu-common-spacing-none);
        }
        .aaaem-button__primary-filled {
          max-width: 155px;

          @include mq('x-large') {
            padding-top: 11px;
            padding-bottom: 11px;
          }

          &:hover,
          &:focus {
            svg {
              transform: translateX(5%);
              g {
                stroke: var(--emu-semantic-colors-primary-teal-400);
              }
            }
          }
        }
      }
    }
  }
}
