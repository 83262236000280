.light-bg {
  background-color: var(--emu-semantic-colors-primary-bone);
  padding-top: 32px;

  .aaaem-container {
    background-color: inherit;
  }

  .aem-Grid {
    max-width: var(--emu-component-containers-container-max-width);
    margin: auto;
  }
}
