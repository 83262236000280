body#real-results {
  .teaser {
    width: 100%;
    overflow: hidden;

    .content-container {
      width: 100%;
      padding-bottom: var(--emu-semantic-spacing-none);

      @include mq('large') {
        padding-left: 96px;
        margin: auto;
        max-width: var(--emu-component-containers-container-max-width);
      }

      .disclaimer {
        margin-bottom: 28px;
        @include mq('large') {
          margin-bottom: var(--emu-common-spacing-medium);
        }
      }
    }

    .cmp-teaser {
      &__description > p,
      &__title {
        margin-bottom: var(--emu-semantic-spacing-none);
      }
    }

    @include mq('medium') {
      .cmp-teaser {
        &__description {
          display: flex;
          justify-content: center;
          @include mq('large') {
            justify-content: flex-start;
          }

          p {
            width: 54%;
          }
        }
      }
    }

    @include mq('large') {
      .cmp-teaser {
        &__description {
          p {
            width: 35%;
          }
        }
      }
    }
  }

  .real-results-wrapper {
    margin-top: 44px;
    margin-bottom: 48px;

    .aem-Grid {
      max-width: var(--emu-component-containers-container-max-width);
      margin: auto;
    }

    .cmp-text {
      text-align: center;
    }

    h2 {
      font: var(--emu-semantic-typography-narrow-body-intro-large);
      width: 90%;
      margin: auto;
      padding-bottom: 21px;

      @include mq('large') {
        font: var(--emu-semantic-typography-wide-body-intro-large);
      }

      sup {
        position: relative;
        top: 5px;
        line-height: 0; //needed to match figma.
        @include mq('large') {
          top: 8px;
        }
      }
    }

    p {
      width: 85%;
      margin: auto;
      font: var(--emu-semantic-typography-narrow-body-footnotes);

      @include mq('large') {
        font: var(--emu-semantic-typography-wide-body-footnotes);
      }
    }

    @include mq('medium') {
      h2 {
        width: 60%;
      }

      p {
        width: 60%;
      }
    }

    @include mq('large') {
      font: var(--emu-semantic-typography-wide-body-intro-large);
      margin-top: 79px;

      h2 {
        width: 70%;
      }
      p {
        font: var(--emu-semantic-typography-wide-body-footnotes);
        padding-top: 27px;
        width: 45%;
      }
    }

    @include mq('xx-large') {
      h2 {
        width: 60%;
      }
      p {
        width: 40%;
      }
    }
  }

  .slider-carousel {
    max-width: 915px; //as per figma
    margin-left: auto;
    margin-right: auto;
  }
}
