body#faq {
  .teaser {
    .disclaimer {
      font: var(--emu-semantic-typography-wide-body-footnotes);
      margin-bottom: 26px;
    }
    width: 100%;

    .cmp-teaser {
      &__description {
        width: 90%;
        @include mq('medium') {
          width: 50%;
        }
        @include mq('x-large') {
          width: 35%;
        }

        p {
          margin: var(--emu-common-spacing-none);

          b {
            font-family: var(--emu-semantic-font-families-light);
            font-weight: var(--emu-common-font-weight-light);
          }
        }
      }

      &__title {
        margin-bottom: 36px;

        @include mq('large') {
          margin-bottom: var(--emu-common-spacing-medium);
        }
      }

      &__subtitle {
        display: none;

        @include mq('large') {
          display: block;
          position: absolute;
          bottom: 75px;
          font-size: 14px;
          line-height: var(--emu-semantic-line-heights-wide-small);
          margin: var(--emu-common-spacing-none);
          width: 380px; // to match figma
          height: 75px; // As per design.
          text-indent: -7px;
        }
      }

      .content-container {
        padding-bottom: var(--emu-common-spacing-none);
        @include mq('x-large') {
          padding-bottom: var(--emu-common-spacing-medium);
          padding-left: 70px;
          margin: auto;
          max-width: var(--emu-component-containers-container-max-width);
        }
      }
    }
  }

  .faq-page__sub-text {
    padding-left: 22px;
    padding-right: 22px;

    p {
      font-size: var(--emu-common-font-sizes-narrow-medium);
      line-height: var(--emu-semantic-line-heights-narrow-xxs);
      margin-top: 43px;
      margin-bottom: 30px;
    }

    @include mq('large') {
      display: none;
    }
  }
}
