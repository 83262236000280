header {
  background-color: var(--emu-common-colors-white);
  z-index: var(--emu-component-layers-header);
  position: fixed;
  left: var(--emu-common-spacing-none);
  top: var(--emu-common-spacing-none);
  width: 100%;

  @include mq('large') {
    display: flex;
    justify-content: flex-start;
  }

  @include mq('x-large') {
    justify-content: center;
  }

  .cmp-container-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: auto;
    padding-top: var(--emu-common-spacing-medium);
    padding-right: 23px;
    padding-bottom: var(--emu-common-spacing-medium);
    padding-left: 23px;
    max-width: 100%;

    @include mq('large') {
      max-width: var(--emu-component-containers-container-max-width);
      display: grid;
      grid-template-areas:
        'image  top-navigation'
        'image  navigation';
      justify-content: space-between;
      padding: var(--emu-common-spacing-none);
      flex: 1;
      column-gap: 20px;
      max-height: 104px;
    }

    @include mq('x-large') {
      margin-left: 26px;
      margin-right: 26px;
    }

    @include mq('xx-large') {
      column-gap: 143px;
      margin-left: var(--emu-common-spacing-none);
      margin-right: var(--emu-common-spacing-none);
    }

    .skip-menu-container {
      @include mq('large') {
        padding-left: 70px;
        a {
          color: var(--emu-semantic-colors-secondary-grey-300);
          text-decoration: none;
        }
      }
    }

    .header__logo {
      @include mq('large') {
        grid-area: image;
      }
      .cmp-image {
        height: 100%;
        @include mq('large') {
          padding-top: 18px;
          padding-bottom: 18px;
          margin-left: 25px;
        }

        @include mq('xx-large') {
          margin-left: 85px;
        }
        svg {
          height: 38px;
          width: 160px;
          @include mq('large') {
            height: auto;
            width: auto;
          }
        }
      }
    }

    .header__content {
      @include mq('xx-large') {
        margin-right: 118px;
      }

      .header__close-button {
        margin-right: 10px;
        all: unset;
      }

      .header__menu__hamburger__button {
        all: unset;
        cursor: pointer;
      }

      .header__menu {
        position: fixed;
        height: 100%;
        width: 100%;
        left: -100%;
        top: var(--emu-common-spacing-none);
        background-color: var(--emu-common-colors-white);
        z-index: var(--emu-common-other-z-index-header);
        overflow: auto;
        transition: all 0.8s;

        @include mq('large') {
          display: block;
          position: relative;
          overflow: hidden;
          left: var(--emu-common-spacing-none);
          transition: none;
        }

        > div {
          display: flex;
          flex-direction: column-reverse;

          @include mq('large') {
            flex-direction: column;
          }
        }

        &.js-toggle-on {
          left: var(--emu-common-spacing-none);
          background-color: var(
            --emu-component-layers-modal-scrim-color-background
          );

          @include mq('large') {
            background-color: var(--emu-common-colors-white);
          }

          > div {
            background-color: var(--emu-common-colors-white);

            @include mq('large') {
              background-color: var(--emu-common-colors-transparent);
            }
          }
        }

        .header__menu__brands {
          background-color: var(--emu-common-colors-transparent);
          border: none;

          .cmp-button__text {
            color: var(--emu-semantic-colors-primary-teal-400);
            font-size: var(--emu-semantic-font-sizes-wide-xs);
            line-height: 16px;
            font-family: var(--emu-semantic-font-families-body);
          }

          svg {
            margin-top: var(--emu-common-spacing-small);

            @include mq('medium') {
              margin-top: var(--emu-common-spacing-medium);
            }
          }
        }

        .header__menu__logo {
          padding-left: 23px;

          @include mq('large') {
            display: none;
          }
        }

        .header__menu__intro__container {
          > div {
            display: flex;
            align-items: center;

            div:nth-child(2) {
              width: fit-content;
            }
          }
        }

        .header__menu__list {
          background-color: transparent;
          padding-left: 25px;
          padding-right: 25px;
          margin-top: 33px;

          @include mq('large') {
            padding-left: var(--emu-common-spacing-none);
            padding-right: var(--emu-common-spacing-none);
            margin-top: var(--emu-common-spacing-none);
          }

          .emu-navigation__item--active {
            border-bottom: var(--emu-common-border-width-medium) solid
              var(--emu-semantic-colors-primary-teal-400);
            a {
              font-weight: var(--emu-common-font-weight-bold);
            }
          }

          ul {
            flex-direction: column;
            @include mq('large') {
              justify-content: space-between;
              flex-direction: row;
            }

            li {
              &:last-child {
                display: none;
                @include mq('large') {
                  display: block;
                }
              }
            }
          }

          a {
            color: var(--emu-semantic-colors-primary-teal-400);
            font-family: var(--emu-semantic-font-families-body);
            font-size: var(--emu-semantic-font-sizes-narrow-xs);
            border-bottom: var(--emu-common-border-width-thin) solid
              var(--emu-semantic-colors-primary-teal-400);
            line-height: normal;

            @include mq('large') {
              border-bottom: none;
              padding: var(--emu-common-spacing-none);
              padding-bottom: var(--emu-common-spacing-xs);
              font-size: var(--emu-semantic-font-sizes-narrow-small);
            }
          }
        }

        .header__menu__text {
          padding-top: 13px;

          ul {
            list-style: none;
            font-family: var(--emu-semantic-font-families-body);
            font-size: var(--emu-semantic-font-sizes-wide-xs);
            padding-left: 30px;
            line-height: var(--emu-semantic-line-heights-narrow-small);

            @include mq('large') {
              padding-left: var(--emu-common-spacing-none);
            }

            &:first-child {
              line-height: var(--emu-semantic-line-heights-narrow-medium);
            }

            &:nth-child(2) {
              @include mq('large') {
                display: none;
              }
            }

            li {
              a {
                color: var(--emu-semantic-colors-primary-teal-400);
                text-decoration: none;
                letter-spacing: 0.192px;
                text-transform: uppercase;

                @include mq('large') {
                  font-size: 10px;
                  line-height: var(
                    --emu-semantic-line-heights-wide-top-navigation
                  );
                  letter-spacing: 0.17px;
                  text-transform: unset;
                  color: var(--emu-semantic-colors-primary-teal-400);
                }

                @include mq('x-large') {
                  font-size: var(--emu-common-font-sizes-narrow-medium);
                }

                .emphasis {
                  display: none;

                  @include mq('large') {
                    display: block;
                    color: var(--emu-semantic-colors-primary-teal-400);
                  }
                }

                img {
                  display: inline-block;
                  vertical-align: middle;
                  margin-left: 10px;
                  max-height: 15px;
                  margin-bottom: 3px;
                }
              }
            }
          }
        }

        .header__menu__social-media {
          padding-left: 30px;
          padding-bottom: 25px;
        }

        .header__menu__facebook-icon {
          padding-right: 7px;

          @include mq('large') {
            padding-right: var(--emu-common-spacing-none);
          }
        }

        .header__menu__follow {
          padding-right: 10px;

          p {
            margin: var(--emu-common-spacing-none);
            color: var(--emu-semantic-colors-primary-teal-400);
            font-family: var(--emu-semantic-font-families-body);
            font-size: var(--emu-semantic-font-sizes-wide-xs);
            line-height: 16px;
          }

          @include mq('large') {
            display: none;
          }
        }

        .header__menu__navlink__container {
          > div {
            display: flex;
            flex-direction: column;

            @include mq('large') {
              flex-direction: row;
              align-items: center;
            }

            > div:last-child {
              width: auto;

              li {
                padding-left: 25px;
                padding-right: 25px;
                width: 100%;

                @include mq('large') {
                  padding-left: var(--emu-common-spacing-none);
                  padding-right: var(--emu-common-spacing-none);
                }
              }

              a {
                color: var(--emu-semantic-colors-primary-teal-400);
                font-family: var(--emu-semantic-font-families-body);
                font-size: var(--emu-semantic-font-sizes-narrow-xs);
                border-bottom: var(--emu-common-border-width-thin) solid
                  var(--emu-semantic-colors-primary-teal-400);

                @include mq('large') {
                  white-space: pre;
                  font-size: 13px;
                  margin-right: 15px;
                  border: var(--emu-common-border-width-thin) solid
                    var(--emu-semantic-colors-primary-teal-400);
                  padding-top: 1px;
                  padding-bottom: 1px;
                  padding-left: var(--emu-common-spacing-small);
                  padding-right: var(--emu-common-spacing-small);
                }

                @include mq('xx-large') {
                  margin-right: var(--emu-common-spacing-none);
                  padding-top: 3px;
                  padding-bottom: 3px;
                }
              }
            }
          }
        }
      }

      .header__text__container {
        @include mq('large') {
          padding-bottom: var(--emu-common-spacing-small);
          > div {
            display: flex;
            align-items: baseline;

            > div:nth-child(2) {
              width: fit-content;
              margin-left: 15px;
            }
          }
        }

        @include mq('xx-large') {
          > div {
            > div:nth-child(2) {
              margin-left: 65px;
            }
          }
        }
      }

      .emu-navigation__content-wrapper {
        position: relative;
      }
      @include mq('large') {
        grid-area: top-navigation;
        width: 100%;
        height: 100%;
        align-items: flex-end;
        display: flex;
        padding-bottom: 10px;
      }

      .button {
        display: block;
        @include mq('large') {
          display: none;
        }
      }

      .cmp-text {
        @include mq('large') {
          display: flex;
          column-gap: var(--emu-common-spacing-none);
          ul {
            font: var(--emu-semantic-typography-wide-body-top-navigation);
            display: flex;
            justify-content: flex-start;
            gap: 5px;
            list-style: none;
            margin: var(--emu-common-spacing-none);
            padding: var(--emu-common-spacing-none);
            color: var(--emu-semantic-colors-secondary-grey-300);
          }
          li {
            @include mq('large') {
              display: flex;
              cursor: pointer;
            }
            &::after {
              display: none;
              @include mq('large') {
                content: '';
                display: flex;
                height: 9px;
                width: var(--emu-common-sizing-xxs);
                background-color: var(--emu-semantic-colors-primary-teal-500);
                margin-left: 5px;
                margin-right: 2.5px;
                align-self: center;
              }

              @include mq('x-large') {
                margin-left: 10px;
                margin-right: 5px;
              }
            }

            &:last-child {
              &::after {
                display: none;
              }
            }

            a {
              all: unset;
            }

            img {
              display: none;
            }
          }
        }
      }
      .social-network-icons {
        @include mq('large') {
          display: flex;
          overflow: unset;
          margin-right: 17px;
        }

        @include mq('xx-large') {
          margin-left: 50px;
          margin-right: var(--emu-common-spacing-none);
          margin-top: 15px;
        }
        .cmp-container {
          display: flex;
          align-items: flex-end;
          background-color: inherit;

          @include mq('large') {
            gap: 5px;
            padding: var(--emu-common-spacing-none);
          }
          .image {
            display: flex;
            @include mq('large') {
              padding: var(--emu-common-spacing-none);
              margin-left: var(--emu-common-spacing-none);
            }

            svg {
              path {
                fill: var(--emu-semantic-colors-primary-teal-400);
              }
            }

            .cmp-image {
              @include mq('large') {
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }
        a {
          display: flex;
        }
      }
    }

    .header__global-navigation {
      display: flex;
      position: absolute;
      left: var(--emu-common-spacing-none);
      top: var(--emu-common-spacing-none);
      width: 100%;
      @include mq('large') {
        display: block;
        position: relative;
        height: 100%;
        grid-area: navigation;
      }

      .aaaem-navigation__content-wrapper {
        .aaaem-navigation__global-navigation-close-button {
          button {
            cursor: pointer;
            span {
              display: none;
            }
          }
        }
      }
    }
  }
}
