.modal {
  .modal-content {
    border-radius: var(--emu-semantic-border-radius-xs);
    padding-bottom: 35px;
    padding-top: 35px;

    @include mq('large') {
      padding-top: 42px;
      padding-left: var(--emu-common-spacing-large);
      padding-right: var(--emu-common-spacing-large);
    }

    .close {
      padding-top: var(--emu-common-spacing-small);
      padding-right: 10px;
      padding-bottom: var(--emu-common-spacing-small);
      padding-left: 10px;
      @include mq('large') {
        padding-top: 14px;
        padding-right: 18px;
        padding-bottom: 14px;
        padding-left: 18px;
      }
    }

    .aaaem-container {
      display: flex;
      flex-flow: wrap;
      margin: auto;

      .cmp-button {
        min-width: 80px;
      }
    }

    .aaaem-text {
      width: 100%;
      padding-bottom: 15px;

      a {
        color: var(--emu-semantic-colors-primary-black);
        text-decoration: none;
      }

      h3 {
        font: var(--emu-semantic-typography-narrow-headings-headline-h4);
        font-size: var(--emu-semantic-font-sizes-narrow-small);
        margin-top: 5px;
        @include mq('large') {
          font-size: var(--emu-semantic-font-sizes-narrow-large);
          margin-top: var(--emu-common-spacing-none);
          margin-bottom: 3px;
        }
      }

      p {
        font: var(--emu-semantic-typography-narrow-body-regular);
        margin: var(--emu-common-spacing-none);
        line-height: var(--emu-semantic-line-heights-wide-small);
        @include mq('large') {
          font: var(--emu-semantic-typography-wide-body-regular);
          line-height: 1.2;
        }
      }
    }

    .modal-cta-container {
      justify-content: flex-end;
      flex-direction: row-reverse;

      @include mq('large') {
        margin-bottom: 5px;
      }

      .button {
        padding-right: 15px;
      }

      .aaaem-button {
        font: var(--emu-semantic-typography-narrow-body-regular);
        padding-bottom: var(--emu-common-spacing-xs);
        padding-top: var(--emu-common-spacing-xs);
      }
    }
  }
  // ============ brand navigation ===========
  [data-id='brand-nav'] {
    .modal-wrapper {
      overflow: auto;
      @include horizontal-slide-animation(-100%, 0);
      @include mq('large') {
        @include horizontal-slide-animation(0, 0);
        @include color-change-animation(
          var(--emu-component-layers-modal-scrim-color-background),
          transparent
        );
      }

      @include mq('large') {
        background-color: var(--emu-common-colors-transparent);
        animation: none;
      }

      &.is-open {
        @include mq('large') {
          height: auto;
        }
      }
    }

    .modal-content,
    .aaaem-container {
      background-color: var(--emu-semantic-colors-primary-teal-200);
      @include mq('large') {
        background-color: var(--emu-semantic-colors-primary-white-solid);
      }
    }
    .modal-content {
      border-radius: var(--emu-common-border-radius-none);
      margin: var(--emu-common-spacing-none);
      max-width: none;
      padding-left: 25px;
      padding-right: 25px;
      padding-bottom: 66px;
      width: 100%;
      @include mq('large') {
        @include slide-animation(0, 0, -100%, 0, bannerSlide);
        padding-bottom: 24px;
        padding-left: var(--emu-common-spacing-none);
        padding-right: var(--emu-common-spacing-none);
        padding-top: 24px;
        transform: translateY(-100%);
        animation-fill-mode: forwards;
      }
      .main-close {
        border: var(--emu-common-border-width-thin) solid
          var(--emu-semantic-colors-primary-teal-400);
        border-radius: 50%;
        display: flex;
        height: 38px;
        margin-top: 25px;
        margin-right: 25px;
        padding: var(--emu-common-spacing-none);
        width: 38px;
        @include mq('large') {
          border: none;
          margin-right: 25px;
          margin-top: 14;
        }
        span {
          font-size: 0;
        }
        &::before,
        &::after {
          background-color: var(--emu-semantic-colors-primary-teal-400);
          content: '';
          display: block;
          height: 20px;
          margin: auto;
          transform: rotate(45deg);
          width: var(--emu-common-sizing-xxs);
          @include mq('large') {
            background-color: var(--emu-semantic-colors-primary-black);
            width: 2px;
          }
        }
        &::after {
          left: 17px;
          position: absolute;
          top: var(--emu-common-spacing-small);
          transform: rotate(-45deg);
          @include mq('large') {
            top: 9px;
            left: 18px;
          }
        }
      }

      > .container {
        margin: var(--emu-common-spacing-none);
      }
    }
    #brand-nav {
      .button {
        width: auto;
        @include mq('large') {
          display: none;
        }
      }
      button {
        &[data-modal='brand-nav'] {
          background-color: var(--emu-common-colors-transparent);
          border: none;
          color: var(--emu-semantic-colors-primary-black);
          font: var(--emu-semantic-typography-narrow-body-regular);
          font-size: var(--emu-semantic-font-sizes-wide-xs);
          line-height: var(--emu-semantic-line-heights-narrow-xs);
          padding-left: var(--emu-common-spacing-none);
          pointer-events: none;
        }
      }
      h2 {
        font-family: var(--emu-common-font-families-sans);
        font-size: var(--emu-semantic-font-sizes-narrow-xs);
        line-height: 26px;
        margin-bottom: var(--emu-common-spacing-none);
        margin-top: var(--emu-common-spacing-none);
        @include mq('large') {
          font-size: var(--emu-common-font-sizes-wide-medium);
        }
      }
      p,
      span {
        font-size: var(--emu-common-font-sizes-narrow-medium);
        line-height: 12px;
        @include mq('large') {
          font-size: 10px;
          line-height: 10px;
        }
      }
      .text {
        display: flex;
        padding-bottom: var(--emu-common-spacing-none);
        @include mq('large') {
          text-align: center;
        }
        &:not(.aaaem-text) {
          border-bottom: solid 1px var(--emu-semantic-colors-primary-teal-400);
          padding-bottom: 10px;
          padding-top: 10px;
          @include mq('large') {
            border-bottom: none;
          }
        }
        .aaaem-text {
          display: block;
          @include mq('large') {
            margin: auto;
            padding: var(--emu-common-spacing-none);
          }
        }
      }
      > div {
        @include mq('large') {
          display: flex;
          .text:not(:first-child):not(:nth-child(2)) {
            border-left: solid var(--emu-common-border-width-thin)
              var(--emu-semantic-colors-primary-black);
            padding-left: var(--emu-common-spacing-xs);
            padding-right: var(--emu-common-spacing-xs);
          }
        }
      }
    }
  }

  .modal-mothers-day-gift {
    .modal-content {
      width: max-content;
      background-color: #e6f7f8; // used only once. No variable
      border-radius: var(--emu-common-border-radius-none);
      top: var(--emu-common-spacing-none);
      padding: var(--emu-common-spacing-none);
      margin: 100px auto;

      @include mq('medium') {
        max-width: 760px;
      }

      .mom-modal {
        position: relative;

        &__content-container {
          .mom-modal__title-container {
            position: absolute;
            top: 35px;
            width: 100%;

            @include mq('medium') {
              position: static;
            }
          }

          .mom-modal__cta-container {
            position: absolute;
            bottom: 52px;
            width: 100%;

            @include mq('medium') {
              position: static;
            }
          }

          @include mq('medium') {
            position: absolute;
            top: 112px;
            right: -185px;
          }
        }

        &__pre-title {
          .cmp-title__text,
          p {
            font-weight: var(--emu-common-font-weight-regular);
            font-size: var(--emu-common-font-sizes-wide-medium);
            line-height: 1;
            text-align: center;
            letter-spacing: 0.317px;
            color: #0f322c; // used only for this modal. No variable
            margin-bottom: var(--emu-common-spacing-none);
            margin-top: var(--emu-common-spacing-none);
            font-family: var(--emu-semantic-font-families-body);
            position: relative;
            display: inline-block;
            border-bottom: 1.5px solid #00acb9;
            padding-bottom: var(--emu-common-spacing-xxs);

            @include mq('medium') {
              font-size: var(--emu-semantic-font-sizes-narrow-small);
              letter-spacing: 0.4px;
            }
          }
        }

        &__main-title {
          padding-top: 9px;

          @include mq('medium') {
            padding-top: 12px;
          }

          .cmp-title__text,
          p {
            font-weight: 250;
            font-size: 24px;
            line-height: 28px;
            letter-spacing: 0.475px;
            text-align: center;
            color: #160c1f;
            margin-bottom: 0px;
            margin-top: 0px;
            font-family: var(--emu-semantic-font-families-juana-light);

            @include mq('medium') {
              font-size: 30px;
              letter-spacing: 0.6px;
              line-height: 35px;
            }

            bdi {
              color: #00acb9; // Used only once. No variable
            }
          }
        }

        &__cta {
          display: inline-block;
          width: auto;
          font-family: var(--emu-semantic-font-families-body);
          font-weight: var(--emu-common-font-weight-regular);
          text-decoration: none;
          padding-top: var(--emu-common-spacing-small);
          padding-bottom: var(--emu-common-spacing-small);
          padding-left: 45px;
          padding-right: 45px;
          margin-bottom: 5px;
          border: var(--emu-common-border-width-none);
          border-radius: var(--emu-common-border-radius-none);
          line-height: var(--emu-common-line-heights-narrow-medium);
          font-size: var(--emu-common-font-sizes-narrow-medium);

          &,
          &:hover,
          &:focus,
          &:active {
            color: var(--emu-common-colors-white);
            background-color: #0f322c; // used only for this modal. No variable
          }

          @include mq('medium') {
            padding-top: 12px;
            padding-bottom: 12px;
            padding-left: 57px;
            padding-right: 57px;
            margin-top: 27px;
            margin-bottom: 18px;
            font-size: 15px;
          }

          &-container {
            .button {
              text-align: center;

              &:not(.backToTop) {
                display: block;
              }
            }
          }
        }

        &__disclaimer {
          padding-bottom: 18px;

          @include mq('medium') {
            padding-bottom: var(--emu-common-spacing-none);
          }

          p {
            font-weight: var(--emu-common-font-weight-regular);
            font-size: 6px;
            font-family: var(--emu-semantic-font-families-body);
            line-height: 1.2;
            letter-spacing: 0.12px;
            position: absolute;
            color: var(--emu-common-colors-black);
            left: var(--emu-common-spacing-none);
            right: var(--emu-common-spacing-none);
            bottom: 10px;
            text-align: center;

            @include mq('medium') {
              bottom: 5px;
              left: 6px;
              text-align: left;
            }
          }
        }

        &__text {
          padding-bottom: var(--emu-common-spacing-none);

          @include mq('medium') {
            padding-bottom: 15px;
          }
          p {
            font-size: 11px;
            line-height: 1;
            letter-spacing: 0.22px;
            font-weight: var(--emu-common-font-weight-regular);
            color: var(--emu-common-colors-black);
            text-align: center;
            font-family: var(--emu-semantic-font-families-body);
          }

          img {
            margin-left: 8px;
            margin-right: 7px;
            max-width: 102px;
            top: 12px;
            position: relative;
          }
        }
      }
    }

    .close {
      font-family: var(--emu-semantic-font-families-body);
      color: var(--emu-component-layers-modal-actions-close-text-color-light);
      right: 20px;
      top: 10px;
      width: 12px;
      padding: var(--emu-common-spacing-none);
      font-size: 24px;
      z-index: var(--emu-common-other-z-index-layer);

      &:hover,
      &:focus {
        opacity: 1;
      }
    }

    .aaaem-container {
      background-color: var(--emu-common-colors-transparent);
    }
  }
}
