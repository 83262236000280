body#science {
  .teaser {
    overflow: hidden;

    .teaser-container {
      justify-content: flex-end;

      @include mq('large') {
        justify-content: center;
      }

      img {
        width: 100%;
        object-position: 15%;
      }

      .content-container {
        padding: var(--emu-common-spacing-none);
        margin-left: var(--emu-common-spacing-none);

        @include mq('large') {
          max-width: var(--emu-component-containers-container-max-width);
          display: flex;
          align-content: flex-end;
        }

        @include mq('x-large') {
          margin-right: 63px;
        }

        .content-middle-right {
          @include mq('large') {
            padding-bottom: 60px;
            width: 500px;

            .cmp-teaser__description {
              margin-top: 20px;

              p {
                font-size: var(--emu-semantic-font-sizes-wide-large);
              }
            }
          }
        }

        .disclaimer {
          font: var(--emu-semantic-typography-narrow-body-footnotes);
          align-self: flex-start;
          padding-left: 22px;

          @include mq('large') {
            align-self: flex-end;
            font: var(--emu-semantic-typography-wide-body-footnotes);
            padding-bottom: var(--emu-common-spacing-medium);
          }

          p {
            margin-bottom: var(--emu-common-spacing-none);
          }
        }
      }
    }
    .cmp-teaser {
      &__title {
        margin-top: var(--emu-common-spacing-none);
      }
      &__description {
        p {
          sup {
            top: 1px;
          }
        }
      }
    }
  }

  .body-intro {
    display: flex;
    justify-content: center;

    @include mq('large') {
      margin-top: var(--emu-common-spacing-large);
      margin-bottom: 40px;
    }

    .text {
      max-width: 936px;

      @include mq('large') {
        width: 64%;
      }

      p {
        font: var(--emu-semantic-typography-narrow-body-intro-large);
        margin-top: 47px;
        margin-left: auto;
        margin-right: auto;

        @include mq('large') {
          font: var(--emu-semantic-typography-wide-body-intro-large);
        }

        sup {
          line-height: initial;
          position: relative;
          top: 5px;
          line-height: 0; //needed to match figma
          @include mq('large') {
            top: 8px;
          }
        }

        &:nth-of-type(1) {
          width: 93%;

          @include mq('large') {
            width: 100%;
          }
        }

        &:nth-of-type(2) {
          width: 87%; // to match figma
          margin-top: 36px;
          font-size: var(--emu-semantic-font-sizes-narrow-xs);
          line-height: var(--emu-semantic-line-heights-narrow-small);

          @include mq('large') {
            width: 100%;
            font-size: var(--emu-semantic-font-sizes-narrow-large);
            line-height: var(--emu-semantic-line-heights-narrow-large);
          }

          sup {
            line-height: initial;
            position: relative;
            top: 4px;
            line-height: 0; //needed to match figma
            @include mq('large') {
              top: 6px;
            }
          }
        }
      }
    }
  }

  .science--glow-report--text {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 27px;

    @include mq('large') {
      width: 60%;
    }

    @include mq('x-large') {
      width: 45%;
    }

    p {
      font-size: var(--emu-semantic-font-sizes-narrow-xxs);
      line-height: var(--emu-semantic-line-heights-narrow-xxs);

      @include mq('large') {
        font-size: var(--emu-semantic-font-sizes-wide-xs);
        line-height: var(--emu-semantic-line-heights-wide-small);
      }
    }
  }

  .body__content-container {
    margin-top: var(--emu-common-spacing-xl);
    display: flex;
    justify-content: center;

    @include mq('large') {
      margin-top: 90px;
    }

    .teaser {
      @include mq('large') {
        width: 44%;
      }
      .has-media {
        height: 100%;
      }
    }

    .science--body--teaser {
      .teaser-container {
        img {
          height: max-content;

          @include mq('large') {
            object-fit: cover;
            height: 100%;
          }
        }

        .content-container {
          @include mq('large') {
            margin-right: var(--emu-common-spacing-none);
          }
        }

        .disclaimer {
          visibility: visible;
          position: absolute;
          bottom: 13px;
          color: var(--emu-common-colors-white);
          margin-bottom: var(--emu-common-spacing-none);
          padding-left: var(--emu-common-spacing-none);
          align-self: center;

          @include mq('large') {
            bottom: -5px; //to match figma
          }
        }
      }
    }

    .cmp-container {
      display: flex;
      flex-direction: column;
      max-width: var(--emu-component-containers-container-max-width);

      @include mq('large') {
        flex-direction: row;
        flex-wrap: nowrap;
        width: 100%;
      }

      @include mq('xx-large') {
        justify-content: center;
      }

      .body__content-container__text-container {
        display: flex;
        justify-content: center;

        @include mq('large') {
          padding: var(--emu-common-spacing-none);
        }

        .aaaem-container {
          flex-direction: column;
          gap: 21px;
          margin-top: 53px;
          width: 89%;

          @include mq('large') {
            margin-top: var(--emu-common-spacing-none);
            width: auto;
            padding-left: 26px;
            gap: 26px;
          }

          .cmp-text {
            background-color: var(--emu-semantic-colors-primary-bone);
            padding-top: 44px;
            padding-right: 25px;
            padding-bottom: 44px;
            padding-left: 25px;

            @include mq('large') {
              padding-top: 52px;
              padding-right: 92px;
              padding-bottom: 58px;
              padding-left: 82px;
              max-width: 787px;
            }

            h3 {
              margin-top: var(--emu-common-spacing-none);
              color: var(--emu-semantic-colors-primary-teal-400);
              margin-bottom: 12px;
              font: var(
                --emu-semantic-typography-narrow-headings-headline-h4-teal
              );

              @include mq('large') {
                font: var(
                  --emu-semantic-typography-wide-headings-headline-h4-teal
                );
                margin-bottom: 11px;
              }
            }

            p {
              font: var(--emu-semantic-typography-narrow-body-regular);
              margin-top: var(--emu-common-spacing-none);
              margin-bottom: var(--emu-common-spacing-none);
              @include mq('large') {
                font: var(--emu-semantic-typography-wide-body-regular);
                margin-bottom: var(--emu-common-spacing-none);
              }
            }
          }
        }
      }
    }
  }

  .body-cta-container {
    margin-top: 61px;
    margin-bottom: 101px;
    display: flex;
    justify-content: center;
    padding-bottom: 10px;

    @include mq('large') {
      margin-top: 132px;
      margin-bottom: 136px;
    }
    .cmp-container {
      width: 85%;
      @include mq('large') {
        width: 55%;
      }
      p {
        font: var(--emu-semantic-typography-narrow-headings-headline-h2);
        margin-top: var(--emu-common-spacing-none);
        padding-left: 10px;
        padding-right: 10px;
        @include mq('large') {
          margin-bottom: 47px;
          font: var(--emu-semantic-typography-wide-headings-headline-h1);
        }
      }
    }
    .button {
      justify-content: center;

      a {
        width: auto;
        min-width: 130px;

        > span {
          display: none;
        }

        svg {
          width: 100%;
        }

        @include mq('large') {
          padding-top: 11px;
          padding-bottom: 11px;
          min-width: 157px;
        }
      }

      svg {
        transition: all 0.3s ease-out;
        margin: var(--emu-common-spacing-none);
      }
      .aaaem-button {
        &:hover,
        &:focus {
          svg {
            transform: translateX(5%);
            g {
              stroke: var(--emu-semantic-colors-primary-teal-400);
            }
          }
        }
        &:active {
          svg g {
            stroke: var(--emu-semantic-colors-primary-teal-100);
          }
        }
      }
    }
  }
}
